import * as React from "react";
import {withRouter} from "../../../CommonComponents/WithRouter";
import {
    AddTagsComponent,
    AddTagsComponent02, defaultThumb,
    InputFieldComponent,
    SelectComponent,
    TextAreaComponent
} from "../CommonComponents/Form";
import {Link} from "react-router-dom";
import {studioAPI} from "../../../../api/base";
import * as qiniu from "qiniu-js";


class UploadVideoComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            status:1,
            file:null,
            fileName:null,
            pageToken:this.props.router.pageToken.to(),
            ref:null
        };

    }
    selectedFile(fileName,file){
        this.setState({status:2,file:file,fileName:fileName})
    }

    render(){
        return <div className="studioWrap">
            <div className="console">
                <ContentComponent pageToken={this.props.router.pageToken.to()} />
            </div>
        </div>
    }
}

export const UploadVideoPage = withRouter(UploadVideoComponent);


function UploadComponent({dom,pageToken}) {


    let [videoTitle,setVideoTitle] = React.useState("");
    let [videoDescription,setVideoDescription] = React.useState("");
    let [tags,setTags] = React.useState([]);
    let [videoSourceKey,setVideoSourceKey] = React.useState(null);
    let [videoId,setVideoId] = React.useState(null);

    let modify = ()=>{

        if (videoId!==null){
                let p = {
                    title:videoTitle,
                    description:videoDescription,
                    tags:tags,
                    page_token:pageToken,
                    id:videoId
                };
                let a = new studioAPI();
                a.modifyVideo(
                    p,
                    (response)=>{
                        //navigate("/studio/video",{replace:true,state:{from:"/studio/video/upload"}})
                        window.alert("更新成功!")
                    },
                    (msg)=>{window.alert(msg["error_message"])}
                )

        }
    };



    return (
        <div>
            <UploadVideoBox pageToken={pageToken} dom={dom}
                            callback_sendVideoInformation={(p)=>{
                                setVideoId(p.videoId);
                            }}
                            callback_completeUpload={()=>{}}
            />
            <div style={{marginTop:"10px"}}>
                <InputFieldComponent label={"Video Title"} callback_onChange={(t)=>{setVideoTitle(t)}}/>
                <AddTagsComponent02 callback_addTag={(tag)=>{

                    let newTags = tags;
                    newTags.push(tag);
                    setTags(newTags);
                }} callback_removeTag={(tag)=>{
                    let newTags = [];
                    tags.forEach(it=>{
                       if (it !== tag){
                           newTags.push(tag)
                       }
                    });
                    setTags(newTags);
                }} defaultValue={tags} />
                <TextAreaComponent label={"Video Description"} callback_onChange={(desc)=>{setVideoDescription(desc)}}/>
                <div className="mb-3">
                    <button className="btn btn-primary" onClick={()=>modify()} >Modify</button>
                </div>
            </div>
        </div>
    )
}


function UploadVideoBox({dom,pageToken,callback_completeUpload,callback_sendVideoInformation}) {


    let [status,setStatus] = React.useState(1);
    let [percent,setPercent] = React.useState(0);
    let [error,setError] = React.useState(false);
    let [errorMessage,setErrorMessage] = React.useState();
    let [uploadToken,setUploadToken] = React.useState(null);
    let [videoId,setVideoId] = React.useState(null);
    let [videoSourceKey,setVideoSourceKey] = React.useState(null);

    //let [fileContent,setFileContent] = React.useState(null);

    let subscription = null;
    let readyUpload=true;

    let fileName = dom.current.value;
    let fileSource = dom.current.files[0];
    let ext = fileName.split(".").pop();


    let uploadFile = ()=>{

        readyUpload = false;

        let a = new studioAPI();
        a.getUploadToken(
            {filename:fileName,ext:ext,page_token:pageToken},
            (response)=>{
                let videoId = response.video_id;
                let videoSource = response.key;
                let uploadToken = response.upload_token;
                let region = response.region;

                setVideoId(videoId);
                setVideoSourceKey(videoSource);
                setUploadToken(uploadToken);

                callback_sendVideoInformation({videoId:videoId,key:videoSourceKey});

                const ob = {
                    next:(res)=>{
                        setPercent(res.total.percent);
                    },
                    error(err){
                        //setStatus(2);
                        if (err.name === "RequestError"){
                            if(err.code === 579){
                                setStatus(2);
                                return
                            }else{
                                window.alert("请求发生错误,正在重试!");
                            }
                        }else{
                            setErrorMessage(err.name);
                            setError(true);
                            window.alert(`网络发生异常!(原因:${err.name})`);
                        }
                        if (subscription === undefined || subscription === null){
                            return null
                        }else{
                            return subscription.unsubscribe()
                        }
                    },
                    complete(res){
                        setStatus(2);
                        return callback_completeUpload()
                    }
                };

                let r = "";
                switch (region.toLocaleLowerCase()){
                    case "z2":
                        r = qiniu.region.z2;
                        break;
                    case "as0":
                        r = qiniu.region.as0;
                        break;
                    default:
                        r = qiniu.region.as0;
                        break
                }

                let q = qiniu.upload(fileSource,response.key,response.token,null,
                    {
                        useCdnDomain: true,
                        region: r
                    });
                subscription = q.subscribe(ob);
            },
            (msg)=>{
                window.alert(msg["error_message"]);
                setError(true);
                setErrorMessage(msg["error_message"]);
                setStatus(4);
            },false
        )
    };

    React.useEffect(()=>{
        uploadFile();
    },[readyUpload===false]);

    const renderStatus = (s)=>{

        switch (s){
            case 1:
                return <div className="progress-bar bg-primary" style={{width:`${percent}%`}}>{percent.toFixed(2)}%</div>;
            case 2:
                return <div className="progress-bar bg-primary" style={{width:"100%"}}>转码中...</div>;
            case 3:
                return <div className="progress-bar bg-warning" style={{width:"100%"}}>申请中...</div>;
            case 4:
                return <div className="progress-bar bg-danger-subtle" style={{width:"100%"}}>发生错误</div>;
            default:
                return <div className="progress-bar bg-danger" style={{width:"100%"}}>重试中...</div>
        }

    };

    return (
        <div className="uploadVideoBox" >
            <div className="uploadVideoBoxInside">
                <div  style={{height:"100%"}}>
                    <div className="col-sm-4 fl" style={{height:"100%",background:"#333"}}>
                        <img style={{width:"100%"}} alt={fileName} src={defaultThumb} width={"100%"} height={"100%"}/>
                    </div>
                    <div style={{height:"100%",marginLeft:"10px"}} className="col-sm-5 fl">
                        <span className="h3 overflow-hidden"  style={{maxHeight:"65px"}}>{fileName}</span>
                        <div className="progress mt-3" style={{height:"30px"}}>
                            {renderStatus(status)}
                        </div>
                        <div className="mt-3">
                            {error === true ? <span className="fw-medium fs-5 lh-base mt-3 text-danger">{errorMessage}</span>:
                                <span className="fw-medium fs-5 lh-base mt-3" style={{color:"#333"}}>{status===1 ? "Uploading..." : "Transcoding..."}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function ContentComponent({pageToken}) {

    let ref = React.useRef();

    let [isSelectedVideo,setSelectedVideoState] = React.useState(false);

    let FLimitSize = (file,callback=()=>{})=>{

        if (file.size > 1024*1024*1024*5){
            window.alert("Sorry,The file limit size is 5GB!")
            return null
        }else{
            callback()
        }
    };

    return (
        <div className="console_inside">
            <input type="file" id="input-file" hidden={"true"} ref={ref} onChange={(event)=>{
                    FLimitSize(event.target.files[0].size,()=>{
                        setSelectedVideoState(true)
                    });
            }}/>

            { isSelectedVideo === true ? <UploadComponent dom={ref} pageToken={pageToken}/>:
                <SelectVideoComponent callback_notifyCompleteSelectVideo={()=>{
                    if (ref.current.files.length > 0){
                        FLimitSize(ref.current.files[0],()=>{
                            setSelectedVideoState(true);
                            return null
                        });
                    }
                }} dom={ref}/>}
        </div>
    )
       //
}


function SelectVideoComponent({dom,callback_notifyCompleteSelectVideo}){


    const onClick = () => {
        dom.current.click();
    };

    const copyFile = (files)=>{
        dom.current.files = files;

    };

    const dropFile=(e)=>{
        e.preventDefault();
        if (!e.dataTransfer.types.includes("Files")){
            window.alert("仅支持文件拖拽！");
            return null
        }
        let files = e.dataTransfer.files;
        copyFile(files);
        callback_notifyCompleteSelectVideo();
    };

    const DragEnter = (e)=>{
      e.preventDefault();
    };
    const DragOver = (e)=>{
        e.preventDefault();
    };


    return (
        <div style={{width: "100%", height: "400px"}}
             className="justify-content-center text-center align-content-center"
             onDrop={(e)=>dropFile(e)} onDragEnter={(e)=>DragEnter(e)} onDragOver={(e)=>DragOver(e)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor"
                 className="bi bi-cloud-arrow-up" viewBox="0 0 16 16" style={{marginTop: "80px", cursor: "pointer"}}
                 onClick={()=>{onClick()}}
            >
                <path fill-rule="evenodd"
                      d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"/>
                <path
                    d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
            </svg>
            <div className="fs-3">Click the icon or button to upload the video</div>
            <div className="fs-8">Your videos will be private until you publish them.</div>
            <div className="mt-1">
                <button className="btn-primary btn" onClick={() => {
                    onClick()
                }}>SELECT VIDEO
                </button>
            </div>
            <div className="fs-8 mt-2" style={{fontSize: "11px"}}>By submitting your videos to Yimi.TV, you acknowledge
                that you agree to <Link to={"/legal/terms_of_service"}>Yimi.tv's Terms of Service</Link> and <Link
                    to={"/legal/community_guidelines"}>Community Guidelines</Link>. <br/>Please do not upload illegal
                videos such as violent horror, pornographic videos, etc.
            </div>

        </div>
    )

}

