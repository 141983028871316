import React from "react";

import {Navigate, useLocation, useNavigate, useParams} from "react-router";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {api} from "../../api/base";
import {UploadPosterComponent} from "./Studio/CommonComponents/Form";
import Helmet from "react-helmet";


export function ReportPage() {


    const reportType = [
        {name:"低俗、暴力、色情内容",value:1},
        {name:"侵犯权益",value:2},
        {name:"违法犯罪",value:3},
        {name:"政治敏感",value:4},
        {name:"违规营销",value:5},
        {name:"不实信息",value:6},
        {name:"危害他人安全",value:7},
        {name:"AI生成内容",value:8},
        {name:"水军注水、洗稿",value:9}
    ];

    const infringementType = [
        {name:"侵犯版权(含搬运、抄袭)",value:1},
        {name:"侵犯商标权",value:2},
        {name:"侵犯肖像权",value:3},
        {name:"侵犯名誉权",value:4},
        {name:"侵犯隐私权",value:5},
        {name:"侵犯其他权益",value:6},
    ];

    let param = useParams();
    let location = useLocation();
    let nav = useNavigate();



    let itemId = param.item_id ;
    let itemTitle= location.state === null ? "" : location.state.item_title;



    let [isComplaintInfringement,setIsComplaintInfringement] = React.useState(false);

    let [reportDescription,setReportDescription] = React.useState(null);
    let [reportingMatters,setReportingMatters] = React.useState(null);
    let [reportDocument,setReportDocument] = React.useState([]);

    let [complaintInfringementType,setComplaintInfringementType] = React.useState(0);


    let selectReportType = (val)=>{
        //转换成数字
        val = parseInt(val);
        //假如等于侵权
        if (val === 2){
            return setIsComplaintInfringement(true);
        }
        //寻找举报事项
        reportType.forEach((it,index)=>{
            if (it.value === val){
                setIsComplaintInfringement(false);
                return setReportingMatters(it.name)
            }
        })
    };

    let selectInfringementType = (val)=>{
        val = parseInt(val);
        //寻找该值
        infringementType.forEach((it,index)=>{
            if (it.value === val){
                setComplaintInfringementType(val);
                return setReportingMatters(it.name);
            }
        })
    };
    let pushDocument = (name,key)=>{
        let d = [];
        let s = {};
        s[name] = key;
        reportDocument.forEach((i,index)=>{
            d.push(i)
        });
        d.push(s);
        setReportDocument(d)
    };

    let submit = ()=>{

        if (reportDescription === null || reportDescription.length <= 0){
            return window.alert("请输入举报描述");
        }
        if (reportingMatters === null || reportingMatters.length <= 0){
            return window.alert("请选择举报事项");
        }

        if(isComplaintInfringement === true ){
            if (reportDocument.length <= 0){
                return window.alert("请上传证明文件!")
            }
        }
        api.Report(
            {
                id:itemId,
                reporting_matters:reportingMatters,
                description:reportDescription,
                document:reportDocument,
            },
            (response)=>{
                window.alert("提交成功!感谢您的反馈,我们将会在15个工作日之内进行处理");
                return nav("/",{replace:true})
            },
            (msg)=>{window.alert(msg["error_message"])}
        )
    };

    let renderNeedDocumentComponent = ()=>{

        if (isComplaintInfringement === false || complaintInfringementType ===  0){return <div />}
        let d = [];
        switch (complaintInfringementType) {
            case 1:
                d.push("上传版权文件","上传版权人身份证(或营业执照)正面","上传版权人身份证(或营业执照)反面");
                break;
            case 2:
                d.push("上传商标文件","上传版权人身份证(或营业执照)正面","上传版权人身份证(或营业执照)反面");
                break;
            case 3:
                d.push("上传违法截图","上传版权人身份证(或营业执照)正面","上传版权人身份证(或营业执照)反面");
                break;
            case 4:
                d.push("上传违法截图","上传版权人身份证(或营业执照)正面","上传版权人身份证(或营业执照)反面");
                break;
            case 5:
                d.push("上传违法截图","上传版权人身份证(或营业执照)正面","上传版权人身份证(或营业执照)反面");
                break;
            case 6:
                d.push("上传违法截图");
                break;
            default:
                d.push("上传违法截图");
                break
        }
        return(
            <div >
                {d.map(it=>(
                    <UploadPosterComponent label={it} callback_afterUploadSuccess={(filename) => {
                        pushDocument(it,filename)
                    }} isImportant={true} maxAmount={1}/>
                ))}
            </div>
        )
    };


    return (
        <div >
            <Helmet>
                <title>Report Video - Yimi.TV</title>
            </Helmet>
            <HeadNavComponent />
            <div className="crwarp " style={{minHeight:"850px"}}>
                <div className="m-0 p-0 w-100 container">
                    <div className="row">
                        <div className="col-9 h-auto">
                            <div className="bg-white p-3">
                                <div className="fw-bold fs-5 mb-3">
                                    举报 / Report
                                </div>
                                <div className="form-floating mb-3">
                                    <input type="email" className="form-control" id="videoTitle" value={itemTitle}
                                           disabled />
                                        <label htmlFor="videoTitle">视频标题<span className="text-danger">*</span></label>
                                </div>
                                <SelectReportComponent items={reportType} callback_onChange={(e)=>selectReportType(e)} />
                                <SelectReportComponent hide={!isComplaintInfringement} items={infringementType} callback_onChange={(val)=>selectInfringementType(val) } label={"侵权类型"}/>
                                <div className="form-floating mb-3">
                                    <textarea className="form-control" id="reportDescription" onChange={(e)=>{
                                        setReportDescription(e.target.value)
                                    }} placeholder={"描述越详细,我们越快速去处理你的诉求哦～"} style={{minHeight:"200px"}}/>
                                    <label htmlFor="reportDescription">举报描述<span className="text-danger">*</span> </label>
                                </div>
                                {renderNeedDocumentComponent()}
                                <div className="text-end">
                                    <button className="btn btn-primary" onClick={()=>submit()}>举报</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 h-auto">
                            <div className="w-100 h-auto bg-white">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FootNavComponent />
        </div>
    )
}



function SelectReportComponent({items=[],callback_onChange,hide=false,label="投诉事项"}) {


    if (hide === true) {
        return <div />
    }
    return(
        <div className="form-floating mb-3">
            <select className="form-select" id="reportEvent" onChange={(e)=>callback_onChange(e.target.value)}>
                <option selected >{`请选择${label}`}</option>
                {items.map(it=>(
                    <option value={it.value} >{it.name}</option>
                ))}
            </select>
            <label htmlFor="reportEvent">{label}<span className="text-danger">*</span></label>
        </div>
    )
}
