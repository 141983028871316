import React, {createRef} from "react";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css"
import {studioAPI} from "../../../../api/base";
import * as qiniu from "qiniu-js";



export function UploadImageComponent({itemId,itemType,aspectRatio=5/7,pageToken="",afterSuccessCallback=()=>{}}) {

    let imageFile = new FileReader();
    let [image,setImage] = React.useState(null);
    let [afterHandlerImage,setAfterHandlerImage] = React.useState(null);
    let [isSelectedImage,setIsSelectedImage] = React.useState(false);
    let [readyUploadImage,setReadyUploadImage] = React.useState(null);

    let [percentage,setPercentage] = React.useState(100.00);
    let [uploadState,setUploadState] = React.useState(0);
    let [uploadErrorMessage,setUploadErrorMessage] = React.useState(null);




    let cropperRef= React.useRef();
    let subscription : any;

    let loadImage =(file)=>{
        imageFile.readAsDataURL(file);
        imageFile.onload =(event)=>{
            setIsSelectedImage(true);
            setImage(event.target.result);
        }
    };
    const dragEnter = (e)=>{
        e.preventDefault();
    };
    const dragOver = (e)=>{
        e.preventDefault();
    };

    const dropFile = (e)=>{
        e.preventDefault();
        if (!e.dataTransfer.types.includes("Files")){
            window.alert("仅支持文件拖拽！");
            return null
        }
        let files = e.dataTransfer.files;
        let dom = document.getElementById("input-file");
        dom.files = files;
        if (files.length > 1){
            window.alert("暂时只支持一个文件裁剪!");
            return null
        }
        loadImage(files[0])
    };
    const click = (e)=>{
        document.getElementById("input-file").click()
    };
    const onCut = () => {
        const imageElement = cropperRef?.current;
        if (typeof imageElement?.cropper === "undefined"){
            return
        }
        setAfterHandlerImage(imageElement.cropper.getCroppedCanvas().toDataURL());

        imageElement.cropper.getCroppedCanvas().toBlob((blob)=>{
            setReadyUploadImage(blob);
        });


    };

    const  SetInitState = ()=>{
        setIsSelectedImage(false);
        setAfterHandlerImage(null);
        setImage(null);
        setUploadState(0);
        setUploadErrorMessage(null);
        afterSuccessCallback();
    };

    const uploadBtnClick = (event)=>{

        event.target.disabled = true;
        event.target.innerHTML = "Upload...";
        setUploadState(1);
        uploadImage();
    };
    const uploadImage = ()=>{
        if (readyUploadImage === null){return null}

        let f = afterHandlerImage.split(";");
        let f1 = f[0].split(":");
        let f2 = f1[1].split("/");
        let ext = f2[1];


        //get upload token
        let api = new studioAPI();
        api.getUploadToken({
            "ext": ext,
            "file_name": "",
            "file_type":readyUploadImage.type,
            "file_size":readyUploadImage.size,
            "page_token":pageToken
        },(response)=>{
            let uploadToken = response.token;
            let filename = response.image_filename;
            setUploadState(2);
            //upload file
            window.setTimeout(()=>{
                const ob = {
                    next:(res)=>{
                        setPercentage(res.total.percent);
                    },
                    error(err){
                        if (err.code === 579){
                            setUploadState(3);
                            console.log(err);
                            return
                        }
                        setUploadState(4);
                        setUploadErrorMessage(err.message);
                    },
                    complete(res){
                        //upload success;
                        setUploadState(3);
                        //notify
                        api.setImage({
                            "id":itemId,
                            "about":itemType,
                            "filename":filename,
                            "key":res.key,
                            "page_token":pageToken,
                        },(response)=>{
                            setUploadState(6);
                        },(msg)=>{
                            setUploadState(5);
                            setUploadErrorMessage("图片上传成功,设置图片失败!(原因:"+msg["error_message"]+")")
                        })
                    }
                };
                let q = qiniu.upload(readyUploadImage,filename,uploadToken,null,null);
                subscription = q.subscribe(ob);
            },1000);

        },(msg)=>{
            setUploadState(4);
            setUploadErrorMessage(msg["error_message"])
        },true)
    };

    const calculateWidthHeight= ()=>{
        let width =0.0;
        let height = 0.0;
        if (aspectRatio<=1){
            height = 400;
            width = height*aspectRatio;
        }else{
            width = 400;
            height = width/aspectRatio;
        }


        return [width,height]
    };


    return (
        <div className="console_content">
            <div className="container text-center">
                <div className="row">
                    <input hidden={true} type="file" onChange={(e) =>{loadImage(e.target.files[0])}} id={"input-file"}/>
                    <div style={{height: "300px",display: isSelectedImage === true ? "none" : "block"}}
                         onDragEnter={(e)=>dragEnter(e)} onDragOver={(e)=>dragOver(e)} onDrop={(e)=>dropFile(e)} onClick={()=>click()}>
                        <div style={{width:"100%",height:"100%",border:"1px solid #f0f0f0",borderRadius:"5px"}} className="justify-content-center text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor"
                                 className="bi bi-cloud-arrow-up" viewBox="0 0 16 16" style={{marginTop:"80px",cursor:"pointer"}}
                                 onClick={()=>{}}
                            >
                                <path fill-rule="evenodd"
                                      d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z"/>
                                <path
                                    d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                            </svg>
                            <div className="fs-4">将图像文件拖拽到此处也可以上传哦</div>
                            <div style={{marginTop:"8px"}}>
                                <button className="btn btn-primary" >上传海报</button>
                            </div>
                        </div>
                    </div>
                    <div style={{display: isSelectedImage === true ? "block" : "none", minHeight: "450px"}}>
                        <div style={{height: "100%", float: "left", width: "400px"}}>
                            <div className="justify-content-center" style={{width: "100%", height: "400px"}}>
                                <img src={afterHandlerImage}
                                     hidden={afterHandlerImage===null}
                                     alt={"cut-image"}
                                     width={`${calculateWidthHeight()[0]}px`} height={`${calculateWidthHeight()[1]}px`} style={{border:"1px solid #0099ff",padding:"2px"}}/>
                            </div>
                            <div style={{height:"50px",lineHeight:"50px"}} className="justify-content-center">
                                <button className="btn btn-primary" disabled={readyUploadImage === null} onClick={(e)=>uploadBtnClick(e)}>上传图片</button>
                            </div>
                        </div>

                        <ProgressBox display={uploadState !== 0 } State={ uploadState } percentage={percentage} error_message={uploadErrorMessage} successCallback={()=>SetInitState()}/>
                        <div style={{height: "400px", float: "right", width: "400px",display:uploadState === 0 ? "block" :"none"}} id={"cuteTool"} >
                                <Cropper    zoomTo={0.8}
                                            initialAspectRatio={1}
                                            preview=".cut"
                                            ref={cropperRef}
                                            src={image}
                                            viewMode={1}
                                            minCropBoxHeight={10}
                                            minCropBoxWidth={10}
                                            background={false}
                                            responsive={true}
                                            autoCropArea={1}
                                            checkOrientation={false}
                                            aspectRatio={aspectRatio}
                                            guides={true}
                                />
                            <div style={{height:"50px",lineHeight:"50px"}} className="justify-content-center">
                                <button className="btn btn-primary" onClick={()=>onCut()}>裁剪</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


function ProgressBox({percentage,State =0 ,display=false,error_message=null,successCallback=()=>{}}) {


    let [state,setState] = React.useState(State);

    let renderText = ()=>{
        switch (state) {
            case 1:
                return "Preparing...";
            case 2:
                return "Uploading...";
            case 3:
                return "Upload Complete";
            case 4:
                return "Upload Failed";
            case 5:
                return "Upload Complete";
            case 6:
                return "Updated";
            default:
                return "Unknown";
        }
    };


    React.useEffect(
        ()=>{
            setState(State)
        },
    [State]);



    const renderAlert= ()=>{
        if (state === 4 || state === 5) {
            return (
                <div className="alert alert-danger align-items-center" style={{marginTop: "10px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                        <path
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <div style={{marginLeft: "5px"}}>{error_message}</div>
                </div>
            )
        }
        if (state === 6){
            return (
                <div className="alert alert-success align-items-center" style={{marginTop: "10px"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                        <path
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                    </svg>
                    <div style={{marginLeft: "5px"}}><Clock second={10} callback={()=>{successCallback()}}/></div>
                </div>
            )
        }
    };


    return (
        <div style={{height:"400px",float:"right","width":"400px","display":display ===true ? "block" : "none"}}>
            <div className="justify-content-center"style={{marginTop:"40%"}}>
                <h2>{renderText()}</h2>
                <div className="progress" >
                    <div className={State === 3 ? "progress-bar bg-success progress-bar-striped" : "progress-bar progress-bar-striped"} style={{width:`${percentage}%`}} >{percentage.toFixed(1)}%</div>
                </div>
                {renderAlert()}
            </div>
        </div>
    )
}
function Clock({second=50,callback=()=>{}}) {
    let ti = second;
    let [successMessage,setSuccessMessage] = React.useState(`更新成功!${ti}秒之后自动返回`);

    React.useEffect(()=>{
        const timer = setInterval(() => {
            ti--;
            setSuccessMessage(`更新成功!${ti}秒之后自动返回`);
            if (ti === 0){
                callback();
                clearInterval(timer)
            }
        }, 1000);
    },[]);
    return <div >{successMessage}</div>
}
