import $ from 'jquery'

window.$ = $;
const host = "https://web.yimi.tv";
//const host = "http://localhost:8080";
const studioDomain= `${host}/studio`;
const BiDomain = `${host}/bi`;
export const SearchDomain = `https://search.yimi.tv`;
export const search_key = "f23f8f98b9fae38ebf793341b498d5f57ffd0dbe8bc82d1cc2db7781aa3726cd";



export const VideoTopicToType = 1;
export const ProgramTopicToType = 2;
export const ChannelTopicToType = 3;
export const CommentTopicToType = 4;

class server {

    constructor(url,data,isPost = true){
        this.url = url;
        this.data = data;
        this.isPost = isPost;
    }

    connect(success,error=()=>{},redirect=()=>{}){


        $.ajax(this.url,
            {
                async:true,
                method:this.isPost === true ? "POST" : "GET",
                success:(response,status,xr)=> {
                        switch (parseInt(response.code)) {
                            case 200:return success(response.content);
                            case 400:return error(response.message);
                            case 404:return error(response.message);
                            case 302:return redirect(response.redirect_url);
                            case 301:return redirect(response.redirect_url);
                            default:
                                return success(response.message)
                        }
                        if (status === 404){
                            return error({"error_message":"404:Not Found"})
                        }
                    },
                error:(msg)=>{
                    if (msg.status === 200){
                        return error({"error_message":"response is not json"});
                    }else{
                        return error({"error_message":msg.statusText,"status":msg.status});
                    }
                },
                crossDomain:true,
                xhrFields:{withCredentials:true},
                data:this.isPost===true?JSON.stringify(this.data):this.data,
                contentType: "application/json",
                dataType:"json",
            });
    }
}

export class api{

    static GetAds(params, success, error){
        error = typeof(error) !== function(){} ?  ()=>{} : error;
        return new server(`${host}/advertising/list`,params,false).connect(success,error);
    }

    static GetVideoDetail(param, success, error){
        let s=new server(`${host}/video/query`,param,false);
        return s.connect(success,error);
    }
    static GetPlayLink(param,success,error,redirect){
        let s = new server(`${host}/video/watch`,param,false);
        return s.connect(success,error,redirect)
    }

    static GetProgramDetail(param, success, error){
        let api = new server(`${host}/program/detail`,param,false);
        return api.connect(
            success,
            error
        )
    }
    static GetEpisodes(param, success, error){
        let s = new server(`${host}/program/episodes`,param,false);
        return s.connect(
            success,
            error
        )
    }
    static SubscribeProgram(param, success, error){
        //use post method
        let s = new server(`${host}/program/subscribe`,param,true);
        return s.connect(success,error)
    }
    static IsAlreadySubscribed(param, success, error){
        //use get method to get data
        let s = new server(`${host}/program/is_subscribed`,param,false);
        return s.connect(success,error)
    }
    static GetProgramList(param,success, error){
        let s = new server(`${host}/program/list`,param,false);
        return s.connect(success,error);
    }

    static GiveReward(params, success, error, redirect){
        let s= new server(`${host}/reward`,params,true);
        return s.connect(
            success,
            error,
            redirect,
        )
    }

    static GetCommendVideo(params, success, error){
        let s = new server(`${host}/video/commend`,params,false);
        return s.connect(success, error)
    }
    static GetCommendUser(params, success, error){
        let s= new server(`${host}/channel/commend`,params,false);
        return s.connect(success,error)
    }
    static GetCommendProgram(params, success, error){
        let s = new server(`${host}/program/commend`,params,false);
        return s.connect(success,error)
    }
    static GiveLike(params, success, error){
        if (params.hasOwnProperty("id")===false){
            window.alert("Missing Id");
            return
        }
        if(params.hasOwnProperty("type")===false){
            window.alert("Missing Type");
            return
        }
        let  s= new server(`${host}/like/give`,{id:params.id,type:params.type,page_token:params.page_token},true);
        s.connect(success,error)
    }
    static CollectProgram(param, success, error){
        let s = new server(`${host}/collect/program`,param,true);
        return s.connect(success,error)
    }
    static CollectVideo(param, success, error){
        let s = new server(`${host}/collect/video`,param,true);
        return s.connect(success,error)
    }

    static GetUserProfile(user_id, success, error, pageToken=""){
        let s = new server(`${host}/channel/profile`,{id:user_id,page_token:pageToken},false);
        return s.connect(success,error)
    }
    static GetUserTopic(user_id, success, error, pageToken=""){
        let s = new server(`${host}/channel/program`,{id:user_id,page_token:pageToken},false);
        return s.connect(success,error)
    }
    static GetUserVideo({user_id="",page=1}, success, error, pageToken=""){
        let s = new server(`${host}/channel/video`,{id:user_id,page:page,page_token:pageToken},false);
        return s.connect(success,error)
    }
    static GetUserVideoTotal(user_id,success,error,pageToken=""){
        let s = new server(`${host}/channel/total_video`,{id:user_id,page_token:pageToken},false);
        return s.connect(success,error)
    }
    static GetUserTopicTotal(user_id,success,error,pageToken=""){
        let s = new server(`${host}/channel/total_program`,{id:user_id,page_token:pageToken},false);
        return s.connect(success,error)
    }

    static Follow(leader_id, success, error, is_follow=true, page_token=""){

        let url  = is_follow === true ? `${host}/follow` : `${host}/unfollow`;
        let s = new server(url,{leader_id,is_follow:is_follow,page_token},true);
        return s.connect(success,error)
    }
    static IsFollowed(leader_id,success,error,pageToken){
        let s = new server(`${host}/is_followed`,{leader_id:leader_id,page_token:pageToken},false)
        return s.connect(success,error)
    }



    static Search({id,page=1,keyword}, success, error,page_token=""){
        let s = new server(`${host}/channel/search`,{id:id,page:page,keyword:keyword,page_token:page_token},true);
        return s.connect(success,error)
    }

    static Rating(params, success, error){
        let s= new server(`${host}/program/rating`,params,true);
        return s.connect(success,error)
    }

    static Login(params,success,error){

        let s = new server(`${host}/login`,{...params},true);
        s.connect((response)=>{
            return success(response)
        },(msg)=>{
            return error(msg)
        },(redirect)=>{
            return null
        });
    }
    static Register(params,success,error=()=>{}){
        let s = new server(`${host}/register`,params,true);
        s.connect((response)=>{
            if (success === undefined || success === null){return null};
            return success(response);
        },(msg)=>{error(msg)});
    }
    static SignOut(success, error){
        let s = new server(`${host}/sign_out`,{},true);
        return s.connect(success,error)
    }

    static Recharge(param,success,error){
        let s= new server(`${host}/recharge`,param,true);
        return s.connect(success,error)
    }
    static GetStatistics(params,success,error){
        return success(
            [
                {"value":120,"date":"2021-01","pv":230},
                {"value":130,"date":"2021-02","pv":450},
                {"value":140,"date":"2021-03","pv":300},
                {"value":110,"date":"2021-04","pv":560},
                {"value":122,"date":"2021-05","pv":570},
                {"value":135,"date":"2021-06","pv":580},
                {"value":142,"date":"2021-07","pv":520},
                {"value":145,"date":"2021-08","pv":510},
                {"value":165,"date":"2021-09","pv":520},
                {"value":170,"date":"2021-10","pv":530},
                {"value":175,"date":"2021-11"},
                {"value":185,"date":"2021-12"},
                {"value":500,"date":"2022-01"},
                {"value":600,"date":"2022-02"},
                {"value":700,"date":"2022-03"},
            ]
        )
    }

    static publishComment(param, success, error) {
        let s = new server(`${host}/comment`,param,true);
        return s.connect(success,error)
    }

    static GetComment(param, success, error) {
        let s = new server(`${host}/comment`,param,false);
        return s.connect(success,error)
    }

    static GetReply(param, success, error) {
        let s = new server(`${host}/comment/get_reply`,param,false);
        return s.connect(success,error)
    }

    static GetLikeAmount(param, success, error) {
        let s = new server(`${host}/like/get_amount`,param,false);
        return s.connect(success,error)
    }

    static IsLiked(param, success, error) {
        let s = new server(`${host}/like/is_liked`,param,false);
        return s.connect(success,error)
    }

    static IsAlreadyRating(param, success, error) {
        let s = new server(`${host}/program/is_rated`,param,false);
        return s.connect(success,error)
    }

    static VerifyAccessToken(param, f, f2) {
        let s = new server(`${host}/verify_accessToken`,param,true);
        return s.connect(f,f2)
    }

    static Report(param, success, error) {
        let s = new server(`${host}/report`,param,true);
        return s.connect(success,error)
    }

    static SubscribePlan(param, success, error, direct) {
        return success()
    }

    static ValidCashCard(param, f, f2) {
        let s = new server(`${host}/cash_card/valid`,param,true);
        return s.connect(f,f2)
    }

    static PurchaseMember(param, f, f2) {
        let s = new server(`${host}/member/purchase`,param,true);
        return s.connect(f,f2)
    }

    static IsAlreadyCollectedProgram(param, f, f2) {
        let s = new server(`${host}/collect/is_collected_program`,param,false);
        return s.connect(f,f2)
    }
}

interface SearchParams{
    keyword:string,
    in:{type:number,id:string},
    page:number,
    limit:number
}

export class studioAPI{


    getOverview(params,success,error){

    }

    search(params,success,error){
        let s = new server(`${studioDomain}/search/video`,params,true);
        return s.connect(success,error)
    }
    searchProgram(param,success,error){
        let s = new server(`${studioDomain}/search/program`,param,true);
        return s.connect(success,error)
    }
    getStatistics(param,success,error){
        let service = new server(`${studioDomain}/statistical_report`,param,false);
        return service.connect(
            (response)=>{return success(response)},
            (msg)=>{return error(msg)}
        )
    }

    publishProgram(param, success, error) {
        let s = new server(`${studioDomain}/program/create`,param);
        return s.connect(
            (response)=>{return success(response)},
            (msg)=>{return error(msg)}
        )
    }
    getProgramList(param,success,error){
        let s = new server(`${studioDomain}/program/list`,param,false);
        return s.connect(
            (response)=>{return success(response)},
            (msg)=>{return error(msg)},
        )
    }
    deleteProgram(param,success,error){
        let s = new server(`${studioDomain}/program/delete`,param,true);
        return s.connect(
            (response)=>{return success(response)},
            (msg)=>{return error(msg)},
        )
    }

    getProgramListTotal(param, success,error=()=>{}) {
        let s = new server(`${studioDomain}/program/total`,param,false);
        return s.connect(
            (response)=>{return success(response)},
            (msg)=>{return error(msg)},
        )
    }
    getProgramDetail(param, success,error) {
        let s = new server(`${studioDomain}/program/query`,param,false);
        return s.connect(
            (response)=>{return success(response)},
            (msg)=>{return error(msg)},
        )
    }
    addEpisodesToProgram(param, success, error) {
        let s = new server(`${studioDomain}/program/add_episodes`, param, true)
        return s.connect(
            (response) => {
                return success(response)
            },
            (msg) => {
                return error(msg)
            }
        )
    }

    removeEpisodesToProgram(param,success,error) {
        let s = new server(`${studioDomain}/program/remove_episodes`, param, true);
        return s.connect(
            (response) => {
                return success(response)
            },
            (msg) => {
                return error(msg)
            }
        )
    }

    editEpisodeSortNumber(param, success, error) {
        let s = new server(`${studioDomain}/program/edit_episodes`, param, true);
        return s.connect(
            (response) => {
                return success(response)
            },
            (msg) => {
                return error(msg)
            }
        )
    }
    modifyProgram(param,success,error){
        let s = new server(`${studioDomain}/program/modify`,param,true);
        return s.connect(success,error)
    }

    getUploadToken(param, success,error,isUploadImage=true) {

        let s = new server(`${host}/upload/${isUploadImage===true ? "upload_image" : "upload_video"}`,param,true);
        s.connect((response)=>{
            return success(response)
        },(msg)=>{
            return error(msg)
        },()=>{});
    }

    deleteVideo(param, success,error) {
        let s = new server(`${studioDomain}/video/delete`,param,true);
        return s.connect((response)=>{return success(response)},(msg)=>{error(msg)});
    }

    getVideoList(param, success, error) {

        let s = new server(`${studioDomain}/video/list`,param,false)
        return s.connect((response)=>{return success(response)},(msg)=>{error(msg)});
    }

    getVideoListTotal(param, success,error) {
        let s = new server(`${studioDomain}/video/total`,param,false);
        return s.connect((response)=>{return success(response)},(msg)=>{error(msg)});
    }


    getAdvertisingPosition(param,success,error){
        let s = new server(`${studioDomain}/ads/ad_spot_list`,param,false);
        return s.connect(success,error);
    }
    getAdvertisingType(param, success, error) {
        let s = new server(`${studioDomain}/ads/ad_type_list`,param,false);
        return s.connect(success,error)
    }

    getAdvertisingList(param,success,error){
        let s = new server(`${studioDomain}/ads/list`,param,false);
        return s.connect(success,error)
    }
    getAdvertising(param,success,error){
        let s = new server(`${studioDomain}/ads/query`,param,false);
        return s.connect(success,error)
    }
    getAdvertisingSource(param, success, error) {
        let s = new server(`${studioDomain}/ads/source_list`,param,false);
        return s.connect(success,error)
    }
    createAdvertising(param,success,error){
        let s = new server(`${studioDomain}/ads/create`,param,true);
        return s.connect(success,error)
    }

    modifyAd(param, success, error) {
        let s = new server(`${studioDomain}/ads/modify`,param,true);
        return s.connect(success,error)
    }

    putAd(param, success,error) {
        let s = new server(`${studioDomain}/ads/put`,param,true);
        return s.connect(success,error)
    }
    putOutAd(param,success,error){
        let s = new server(`${studioDomain}/ads/put_out`,param,true);
        return s.connect(success,error)
    }
    deleteAds(param, success,error) {
        let s = new server(`${studioDomain}/ads/delete`,param,true);
        return s.connect(success,error)
    }
    getAdvertisingListTotal(param, success, error) {
        let s = new server(`${studioDomain}/ads/total`,{...param},false)
        return s.connect(success,error)
    }

    getSupportCountry(param,success,error){
        let s = new server(`${host}/verification/support_country`,param,false);
        return s.connect(success,error)
    }
    modifyVideo(params, success, error) {
        let s = new server(`${studioDomain}/video/modify`,params,true);
        return s.connect(
            (response)=>{success(response)},
            (msg)=>{error(msg)}
        )
    }

    getVideoDetail(param, success, error) {
        let s = new server(`${studioDomain}/video/detail`,param,false);
        return s.connect(
            (response)=>{return success(response) },
            (msg)=>{error(msg)}
        )
    }
    getTransactionFlow(param,success,error){
        return success([{}])
    }
    getTransactionFlowTotal(param,success,error){
        return success({page_amount:2})
    }
    smsVerification(param, success,error) {
        let s = new server(`${host}/verification/sms`,param,true);
        return s.connect(success,error)
    }
    changePassword(param, success, error) {
        let s = new server(`${studioDomain}/setting/change_password`,param,true)
        return s.connect(success,error)
    }

    /*
    * Tag Management
    * @id  string topic id or video id
    * @tags tagObject
    * @tags.name string
    * @tags.is_add bool
    * */
    addTagOnProgram(param,success,error){
        let s = new server(`${studioDomain}/tag/manage`,{...param,"about":"program"},true);
        return s.connect(success,error)
    }
    addTagOnVideo(param, success, error) {
        let s = new server(`${studioDomain}/tag/manage`,{...param,"about":"video"},true);
        return s.connect(success,error)
    }

    disableAccount(param,success,error){
        let s= new server(`${studioDomain}/account/disable`,param,true);
        return s.connect(success,error)
    }

    disableVideo(param,success,error){
        let s= new server(`${studioDomain}/video/disable`,param,true);
        return s.connect(success,error)
    }
    disableProgram(param,success,error){
        let s= new server(`${studioDomain}/program/disable`,param,true);
        return s.connect(success,error)
    }


    setImage(param, success, error) {
        let s = new server(`${studioDomain}/image`,param,true);
        return s.connect(success,error)
    }

    changeProfile(param,success,error){
        let s = new server(`${studioDomain}/setting/general`,param,true);
        return s.connect(success,error)
    }


    getGeneralSetting(param, success,error) {
        let s = new server(`${studioDomain}/setting/general`,param,false);
        return s.connect(success,error)
    }

    getSecurityStrategy(param, success, error) {
        let s = new server(`${studioDomain}/setting/security`,param,false);
        return s.connect(success,error)
    }

    addSecurePhone(param, success, error) {
        let s = new server(`${studioDomain}/setting/add_secure_phone`,param,true);
        return s.connect(success,error)
    }
    getCategory(param,success,error){
        let s = new server(`${studioDomain}/category/list`,param,false);
        return s.connect(success,error)
    }
}

export const EventMapClick ="click";
export const EventMapWatch = "watch";
export const EventMapView = "view";
export const EventMapFollow = "follow";
export const EventMapSubscribe = "subscribe";


export function newBI(topic,eventId,itemId) {
    let img = new Image();
    img.src=`${BiDomain}/${topic}?e=${eventId}&item_id=${itemId}`;
    img.style.opacity = 0.0;
    img.id=`${eventId}-${itemId}`;
    return img
}

export function newBiPoint(topic,eventId,itemId) {
    let key = `${eventId}-${topic}-${itemId}`;
    let dom =document.getElementById(key);
    if (dom === null){
        window.document.body.appendChild(newBI(topic,eventId,itemId));
    }
}

