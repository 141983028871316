import React from 'react';
import {Link} from "react-router-dom";
import '../../css/login.css'
import {withRouter} from "../CommonComponents/WithRouter";
import {api, studioAPI} from "../../api/base";
import {IsAccount} from "./Register";
import type {User} from "../../Auth/Route";
import Helmet from "react-helmet";
import {website_name} from "../CommonComponents/WebsiteTitle";

export const Second = 1000;

class LoginPageCore extends React.Component{

    constructor(props){
        super(props);
        this.state= {

            account:null,
            password: null,

            /*countryCode: "+86",
            mobilePhone: null,
            country: null,
            verifyCode: null,*/
            fromPath:this.isNull(this.props.router)===true ? "/" : this.props.router.location.state.pathname,
            fromState:this.props.router.location === undefined ? {} :this.props.router.location.state,
            pageToken:this.props.router.pageToken,
            sendCountDown:60,
            isSent:false,
        };
    }

    isNull(d){
        return d.location === undefined || d.location=== null|| d.location.state === null || d.location.state === undefined
    }

    componentDidMount(): void {
        this.getSupportCountry()
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        if (nextProps.router.auth.isLoggedIn===true){
            return this.props.router.navigate(this.state.fromPath)
        }
    }


    getSupportCountry(){
        let a = new studioAPI();
        a.getSupportCountry(
            {page_token:this.state.pageToken},
            (content)=>{
            this.setState({
                country:content
            })
        })
    }

    login(){

        if (IsAccount(this.state.account)===false || this.state.password.length <= 0){
            window.alert("请输入账号和密码");
            return
        }
        let auth = this.props.router.auth;
        api.Login({
            account:this.state.account,
            password:this.state.password,
            /*
            mobile_phone:this.state.mobilePhone,
            verify_code:this.state.verifyCode,
            country_code:this.state.countryCode,*/
            page_token:this.state.pageToken.to(),
        },(content)=>{
            let newUser:User = null;
            if (content === null || content === undefined) {
                return null
            } else {
                newUser = {
                    user_name: content.user_name,
                    user_id: content.user_id,
                    user_icon: content.user_icon,
                    user_background: content.user_background,
                    user_poster: content.user_poster,
                    user_level: content.level,
                    user_language: content.language,
                    user_experience: content.experience,
                    access_token:content.access_token,
                };
            }
            auth.signUp(newUser,()=>{
                this.props.router.navigate(this.state.fromPath,{replace:true,state:this.state.fromState})
            })
        },(msg)=>{
            window.alert(msg["error_message"])
        })
    }
    register(){
        this.props.router.navigate(
            "/register",
            {
                replace: true,
                state:this.state.fromState
            }
        )
    }
    sendCode(){

        if (this.state.mobilePhone === null){
            window.alert("请输入手机号码");
            return
        }


        if (this.state.isSent === false){
            let a = new studioAPI();
            a.smsVerification(
                {
                    "mobile_phone":this.state.mobilePhone,
                    "country_code":this.state.countryCode,
                    "page_token":this.state.pageToken.to(),
                },
                (content)=>{

                    //console.log(content);
                    this.setTimer();
                    this.setState({
                        isSent:true,
                    })
                }
            )
        }
    }

    setTimer(){

        let t = window.setInterval(
            ()=>{
                if (this.state.sendCountDown === 0){
                    window.clearInterval(t);
                    this.setState({
                        sendCountDown:60,
                        isSent:false,
                    })
                }else{
                    this.setState({
                        sendCountDown:this.state.sendCountDown-1
                    })
                }
            },
            Second
        )
    }
    render(){
        if (this.state.country === null){
            return <div />
        }

        return (
            <div>
                <Helmet>
                    <title>Log In  - {website_name}</title>
                </Helmet>
                <div className="l_wrap">
                    <div className="inside">
                        <div className="l_logo" >
                            <div className={"logo"}>Yimi<em>.tv</em></div>
                        </div>
                        <div className="wrap">
                            <div className="decoration">Welcome Back</div>
                            <ul>
                                <li>
                                    <input onChange={(e)=>{this.setState({account:e.target.value})}}
                                           placeholder={"請輸入登陸賬號 / Please Enter Account"}
                                           type="text" style={{height:"50px"}} />
                                </li>
                                <li >
                                    <input onChange={(e)=>{this.setState({password:e.target.value})}}
                                           placeholder={"請輸入登陸密碼 / Please Enter Password"}
                                           type="password" style={{height:"50px"}}/>
                                </li>
                                <li>
                                    <Link to={"/forget_password"}>忘記密碼嗎? / Forget Password?</Link>
                                </li>
                                <li className="f1">
                                    <button className={"button"}
                                            disabled={
                                                this.state.mobilePhone === null
                                            }
                                            onClick={() => this.login()}>立即登陸 / Log In</button>
                                </li>
                                <li>
                                    <button  className="register button" onClick={() => this.register()}>Don't have an account?
                                        Sign up
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const LoginPage = withRouter(LoginPageCore);

/*
                               <li className="verify">
                                   <div className="left"  style={{height:"100%"}}>
                                       <input style={{width:"62%",height:"50px"}} placeholder={"Verify Code"}
                                           onChange={(event)=>{
                                               this.setState({
                                                   verifyCode:event.target.value.length<=0 ? null : event.target.value
                                               })
                                           }}
                                       />
                                       <button className="sendCode"
                                               disabled={this.state.isSent || this.state.mobilePhone === null}
                                               onClick={()=>this.sendCode()}
                                       >{ this.state.isSent === true ? `(${this.state.sendCountDown})S` : "Send Code"}</button>
                                   </div>
                               </li>*/

/*
* <li className="f1">
                                    <div className={"select"} >
                                        <select className="selectBox" onChange={(event)=>{
                                            this.setState({
                                                countryCode:event.target.value
                                            })
                                        }}>
                                            { this.state.country.map( item=>(
                                                <option value={item.code}
                                                    selected={ item.code === this.state.countryCode}
                                                >{`${item.name} (${item.code})`}</option>
                                            ))}

                                        </select>
                                    </div>
                                    <input style={{width: "65%", float: "right",height:"50px"}} type="text" id="mobile_phone"
                                           placeholder="Please Enter Phone Number"
                                           onChange={(event) => {
                                               this.setState({
                                                   mobilePhone: event.target.value.length<=0 ? null : event.target.value
                                               })
                                           }}/>
                                </li>
*
* */