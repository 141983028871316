

export let IsHttpsURL = (url,is_failed = ()=>{})=>{

    if (url === undefined || url === null || url.length <=0 ){return url}
    let reg = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;//;

    if(reg.test(url)===false){
        //console.log(url);
        return is_failed(url)
    }else{
        //console.log(url);
        return url
    }
};

export let MakeStaticURL = (url)=>{return "https://cdn02.emicdn.com/"+url};

export let uniqueData = (arr) => {

    for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
            if (arr[i].id === arr[j].id) {
                //第一个等同于第二个，splice方法删除第二个
                arr.splice(j, 1);
                j--;
            }
        }
    }
    return arr;
};

export let episodeSort = (array)=> {

    if (array === null || array === undefined){return array}
    const len = array.length;
    if (len < 2) return array;

    for (let i = 0; i < len; i++) {
        for (let j = 0; j < i; j++) {
            let sortNumber1 = array[j].sort_number;
            let sortNumber2 = array[i].sort_number;
            if (sortNumber1 > sortNumber2) {
                const temp = array[j];
                array[j] = array[i];
                array[i] = temp;
            }
        }
    }
    return array
};

export const HomePageAdSpot = "home";
export const ProgramListAdSpot = "list";
export const ShowAdSpot = "show";
export const ChannelAdSpot = "channel";
export const SearchAdSpot = "search";
export const RegisterAdSpot="register";

/**
 * @return {string}
 */
export function QueryAdsSpot(name:string){

    let map={
        "home":"ads_home_page",
        "list":"ads_program_list",
        "show":"ads_program",
        "register":"ads_register_page",
        "search":"ads_search",
        "channel":"ads_channel",
    };

    return map[name];
}

export function numberUnit2Text(n:number) {

    let s = 1000;
    let wan = 10000;

    let yi = 10000*10000;

    switch (true) {
        case n<100:
            return n;
        case n>100 && n<s:
            return `${(n/100).toFixed(0)}百`;
        case n>s && n<wan:
            return `${(n/s).toFixed(0)}千`;
        case n>wan && n<yi:
            return `${(n/(wan)).toFixed(2)}万`;
        case n>yi && n<yi*1000:
            return `${(n/yi).toFixed(2)}亿`;
        default:
            return "超千亿"
    }
}

export function timestampToTime(timestamp) {
    // 时间戳为10位需*1000，时间戳为13位不需乘1000
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + "-";
    var M =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";

    return Y + M + D;
}

export function isCreditCard(number){
    if (number === null || number === undefined){return false}
    let r = /^\d{11,19}$/;
    return r.test(number);
}
export function isCCV(number) {
    if (number === null || number === undefined){return false}
    let r = /^\d{3,4}$/;
    return r.test(number)
}

export function isValidGiftCard(n) {
    if (n === null || n === undefined){return false}
    let reg = /^[0-9A-Za-z]{16}$/;
    return reg.test(n);
}

export function isValidCashCardPassword(n) {
    if (n === null || n === undefined){return false}
    let reg = /^[0-9A-Za-z]{8}$/;
    return reg.test(n);
}