import * as React from "react";
import {withRouter} from "../../../CommonComponents/WithRouter";
import {InputFieldComponent, SelectComponent, TextAreaComponent} from "../CommonComponents/Form";
import {studioAPI} from "../../../../api/base";


class CreateAdsComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            typeList:[],
            spotList:[],
            second:false,
            param:null,
            pageToken:this.props.router.pageToken.to(),
        }
    }

    componentDidMount(): void {
        if (this.state.spotList.length <=0){
            let s = new studioAPI();
            s.getAdvertisingPosition({
                    page_token:"",
                    platform:"pc",
                },
            (response)=>{this.setState({spotList:response})},
            (msg)=>{window.alert(msg["error_message"])}
            );
        }
        if (this.state.typeList.length <= 0){
            let s = new studioAPI();
            s.getAdvertisingType({
                    page_token:"",
                    platform:"pc",
                },
                (response)=>{this.setState({typeList:response})},
                (msg)=>{window.alert(msg["error_message"])}
            );
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="studioWrap">
                <div className="console">
                    <div className="console_inside">
                        <div style={{borderBottom:"1px solid #eee"}}>
                            <h3 className={"display-3"}>Create New Advertising</h3>
                            <p className={"lead text-end"}>A program that will make you proud forever</p>
                        </div>
                        {this.state.second===true ? <SecondComponent pageToken={this.state.pageToken} params={this.state.param}
                                                                     advertisingPositionList={this.state.spotList}
                                                                     typeList={this.state.typeList}
                                                                     callback_success={()=>{this.props.router.navigate("/studio/advertising",{replace:true})}}/> :
                            <FirstComponent typeList={this.state.typeList} advertisingPositionList={this.state.spotList}
                                            callback_complete={(p)=>{
                                                this.setState({param:p,second:true})
                                            }} />}
                    </div>
                </div>
            </div>
        )
    }
}

export const CreateAdsPage = withRouter(CreateAdsComponent);


function FirstComponent({typeList,advertisingPositionList,callback_complete}) {

    let [name,setName] = React.useState(null);
    let [slogan,setSlogan] = React.useState(null);
    let [type,setType] = React.useState(null);
    let [advertisingPosition,setAdvertisingPosition] = React.useState(null);

    let onClick = ()=>{
        if (name===null || slogan === null || type === null || advertisingPosition === null){
            return
        }
        callback_complete({name:name === undefined ? null : name,slogan:slogan === undefined ? null : slogan,type,ad_spot:advertisingPosition})
    };
    let isDisable = ()=>{
        return name === null || slogan === null || type === null || advertisingPosition === null
    };

    return (
        <div className="console_content">
            <InputFieldComponent label={"Advertising Name"} callback_onChange={(val)=>{setName(val)}} />
            <TextAreaComponent label={"Advertising Slogan"} callback_onChange={(val)=>{setSlogan(val)}}/>
            <SelectComponent label={"Advertising Type"} callback_onChange={(val)=>{setType(val)}} items={typeList} />
            <SelectComponent label={"Advertising Position"} callback_onChange={(val)=>{setAdvertisingPosition(val)}} items={advertisingPositionList} />
            <div className="mb-3">
                <button className="btn btn-primary"
                        disabled={isDisable()}
                        onClick={()=>onClick()}>Next Step</button>
            </div>
        </div>
    )

}


function SecondComponent({pageToken,params,callback_success,advertisingPositionList,typeList}) {

    params = params === undefined ? null : params;

    let [selectedProgram,setSelectedProgram] = React.useState(null);
    let [adSpotName,setAdSpotName] = React.useState();
    let [adTypeName,setAdTypeName]= React.useState();

    const getAdSpotName = ()=>{
        advertisingPositionList.map(it=> {
            if (it.id === params.ad_spot) {
                setAdSpotName(it.name)
            }
        })
    };
    const getAdTypeName = ()=>{
        typeList.map(it=>{
            if (it.id === params.type){
                setAdTypeName(it.name)
            }
        })
    };

    React.useEffect(()=>{
        getAdSpotName();
        getAdTypeName();
    },[]);

    let complete = ()=>{
        return (
            <div>
                <InputFieldComponent label={"广告名字"} disable={true} value={params === null ? "" :params.name} />
                <InputFieldComponent label={"广告类型"} disable={true} value={adTypeName} />
                <InputFieldComponent label={"广告位置"} disable={true} value={adSpotName} />
                <InputFieldComponent label={"宣传文本"} disable={true} value={params===null ? "" :params.slogan} />
                <InputFieldComponent label={"推广节目"} disable={true} value={selectedProgram.title}/>
                <div className={"mb-3"}>
                    <button className="btn btn-primary"
                            onClick={()=>{
                                if(params === null){
                                    window.alert("请输入参数");
                                }
                                let a = new studioAPI();
                                a.createAdvertising(
                                    {
                                        page_token:pageToken,
                                        name:params.name,
                                        slogan:params.slogan,
                                        target_program:selectedProgram.id,
                                        advertisement_spot_id:params.ad_spot,
                                        type:params.type,
                                        link:"",
                                    },
                                    (response)=>{window.alert("创建成功!");callback_success()},
                                    (msg)=>{window.alert(msg);}
                                )
                            }}
                            disabled={params === null}
                    >完成</button>
                </div>
            </div>
        )
    };

    let selector = ()=>{
        return (
            <EComponent pageToken={pageToken} callback_onSelected={(p)=>{
                setSelectedProgram(p);
            }}/>
        )
    };


    return (
        <div className="console_content">
            {selectedProgram === null ? selector() : complete()}
        </div>
    )
}


function EComponent({pageToken,callback_onSelected}) {

    let [keyword,setKeyword] = React.useState();
    let [searchResult,setSearchResult] = React.useState([]);

    let [errorMessage,setErrorMessage]= React.useState(null);
    let [showErrorMessage,setShowErrorMessage]=React.useState(false);
    let [initState,setInitState] = React.useState(true);

    let getProgramList = ()=>{

        let a = new studioAPI();
        a.getProgramList({
            page_token:pageToken,
            page:1
        },(response)=>{
            if(response === null){
                setShowErrorMessage(true);
                setErrorMessage("对不起,找不到任何数据!")
            }else{
                setSearchResult(response);

            }
        },(msg)=>{window.alert(msg["error_message"])});
        setInitState(false)
    };

    React.useEffect(()=>{
        getProgramList()
    },[initState===true]);


    return (
        <div className="episodesBox">
            <div style={{width:"100%",height:"40px",padding:"10px ",lineHeight:"30px",fontSize:"16px",fontWeight:"bold"}}>
                请选择你要推广节目(输入节目名字关键词)
            </div>
            <div className="mb-3 input-group" style={{padding: "10px"}}>
                <input className="form-control"
                       placeholder={"Please enter a program ID or name"}
                       onChange={(e)=>{
                           setKeyword(e.target.value);
                            setShowErrorMessage(false);
                       }}
                />
                <button
                    className="btn btn-outline-secondary"
                    onClick={(event) => {
                        event.target.disabled = true;
                        let a = new studioAPI();
                        a.searchProgram({"keyword":keyword,"page_token":pageToken},
                            (response)=>{
                                if(response === null){
                                    setShowErrorMessage(true);
                                    setErrorMessage("对不起,找不到任何数据!")
                                }else{
                                    setSearchResult(response);
                                }
                                event.target.disabled = false;
                            },
                            (msg)=>{
                                setShowErrorMessage(true);
                                setErrorMessage(msg["error_message"])
                            })
                    }} >Search</button>

            </div>
            { showErrorMessage === false ? <RenderSearchResultComponent data={searchResult} callback_onSelected={(item)=>callback_onSelected(item)}/> :
                <AutoCloseAlert isSuccess={false} message={errorMessage} callback={()=>{
                    setShowErrorMessage(false)
                }} timeout={15} />
            }

        </div>
    )
}

function  AutoCloseAlert({message,timeout=50,callback=()=>{},isSuccess=true,style}) {

    let [prompt,setPrompt] = React.useState(`${timeout}秒之后自动关闭`);
    let d = timeout;
    React.useEffect(
        ()=>{
            let timer = setInterval(()=>{
                d--;
                setPrompt(`${d}秒之后自动关闭`);
                if (d === 0){
                    clearInterval(timer);
                    d = timeout;
                    callback()
                }
            },1000);
        },[]
    );

    return (
        <div className={isSuccess === true ? "alert-success alert" : "alert alert-danger"}
             style={{width: "100%", height: "100%", ...style}}>
            <span className="fs-3">{`${message}`}</span>
            <span className="fs-6">{`(${prompt})`}</span>
        </div>

    )
}

function RenderSearchResultComponent ({data,callback_onSelected}) {

    let [selectedId,setSelectedId] = React.useState();
    return (
    <ul className="episodes_searchResult">
        {data.map(item=>(
            <li>
                <div className="episodesRow">
                    <div className="e-poster">
                        <img src={item.poster} alt={item.title}/>
                    </div>
                    <div className="text">
                        <span>{item.title}</span>
                        <span style={{fontWeight:"normal",fontSize:"14px"}}>{`Published On ${item.create_time}`}</span>
                    </div>
                    <div className={"operation"}>
                        <button className="btn btn-primary"
                                disabled={item.id === selectedId}
                                onClick={()=>{setSelectedId(item.id);callback_onSelected(item)}}
                        >Select this
                        </button>
                    </div>
                </div>
            </li>
        ))}
    </ul>)
}