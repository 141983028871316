import React from "react";


export function ErrorNotVideoComponent({setBorder}) {


    return (
        <div style={{
            width: "100%",
            height: "300px",
            background: "#fff",
            border: setBorder === true ? "1px #f0f0f0 solid" : "",
            marginBottom: "15px"
        }}
             className="justify-content-center d-flex">
            <div className="alert alert-danger text-center align-items-center text-md-center justify-content-center" role="alert" style={{width:"450px",height:"auto",lineHeight:"100%"}}>
                <h3 className="alert-heading">Nor Found Any Data</h3>
                <p className="fs-6">Well, we have searched the database 3 times, but still can't find any videos of this user. If you know this user please contact him to upload a video，Thanks!</p>
                <hr />
                <p className="mb-0 fs-6">If you are a channel administrator and the video you have uploaded cannot be displayed, please contact the website customer service</p>
            </div>
        </div>
    )
}

export function ErrorNetworkComponent() {

    return (
        <div style={{
            width: "100%",
            height: "300px",
            background: "#fff",
            marginBottom: "15px"
        }}
             className="justify-content-center d-flex">
            <div className="alert alert-danger text-center align-items-center text-md-center justify-content-center" role="alert" style={{width:"450px",height:"auto",lineHeight:"100%"}}>
                <h3 className="alert-heading">Connect to Network Failed</h3>
                <p className="fs-6">Sorry, our connection to the web server failed! Please check if your network is available，Thanks!</p>
                <hr />
            </div>
        </div>
    )

}