import * as React from "react";
import {studioAPI} from "../../../../../api/base";
import PropTypes, {array} from "prop-types";
import {episodeSort, IsHttpsURL, MakeStaticURL} from "../../../../CommonComponents/LittleFunc";
import {DefaultImageComponent} from "../../../../CommonComponents/Image";
import $ from "jquery";
import {NoRowComponent} from "../../CommonComponents/View";


export class EpisodesComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            program:this.props.program,
            addEpisodesStatus:false,
            pageToken:this.props.router.pageToken.to(),
            episodes:this.props.program === null ? [] : EpisodesComponent.rankEpisodes(this.props.program.episodes),
        }
    }
    static rankEpisodes(e:array){
        if (e === null || e===undefined ){
            return []
        }
        return episodeSort(e)
    }

    componentDidMount(): void {
    }
    getEpisodeListById(){
        let r = [];
        let e = this.state.episodes === undefined || this.state.episodes === null ? [] : this.state.episodes;
        e.forEach(i=>{
            r.push(i.video.id)
        });
        return r;
    }
    addEpisodesToProgram(video_id:string) {

        let a = new studioAPI();
        a.addEpisodesToProgram(
            {
                id: this.state.program.id,
                episodes: [{id:video_id,sort_number:1,stage:""}],
                page_token:this.state.pageToken
            }, (response)=>{
                //假如为数组
                if(Array.isArray(response)){
                    let e = this.state.episodes === undefined || this.state.episodes === null ? [] : this.state.episodes;
                    response.forEach((item)=>{
                        e.push(item)
                    });
                    this.setState({
                        episodes:e,
                    })
                }
                //假如为对象
                if($.isPlainObject(response)){
                    let e = this.state.episodes === undefined || this.state.episodes === null ? [] : this.state.episodes;
                    e.push(response);
                    this.setState({
                        episodes:e,
                    })
                }
            }, (msg)=>{console.log(msg["error_message"])}
        )
    }
    removeEpisodesToProgram(video_id:string){
        let a = new studioAPI();
        a.removeEpisodesToProgram(
            {
                id:this.state.program.id,
                episodes:[video_id],
                page_token:this.state.pageToken
            },
            (reponse)=>{

                let e = this.state.episodes === null || this.state.episodes === undefined ? [] : this.state.episodes;
                let newEpisodes = [];
                e.forEach(i=>{
                    let vid = i.id === undefined ? i.video_id : i.id;
                    if (vid !== video_id){
                        newEpisodes.push(i)
                    }
                });
                this.setState({
                    episodes:newEpisodes
                })
            },
            (msg)=>{window.alert(msg["error_message"])}
        )
    }
    editEpisodesSortNumber(sortNumber:number,episodes_id:string,stage:string){
        let a = new studioAPI();
        a.editEpisodeSortNumber(
            {
                id:this.state.program.id,
                episode_id:episodes_id,
                sort_number:parseInt(sortNumber),
                stage:stage
            },
            (response)=>{

                let e = this.state.episodes;
                let newEpisodes = [];

                e.forEach(item=>{

                    if (item.id === response.id){
                        item.sort_number = response.sort_number;
                    }
                    newEpisodes.push(item);
                });

                newEpisodes = EpisodesComponent.rankEpisodes(newEpisodes);
                this.setState({
                    addEpisodesStatus:false,
                    episodes:newEpisodes
                });
            },
            (msg)=>{
                window.alert(msg["error_message"]);
            },
        )
    }


    render(){
        return (<div className={"console_content"} >
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Video Id</th>
                    <th>Video Thumb</th>
                    <th>Video Title</th>
                    <th>Sort Number</th>
                    <th>Operation</th>
                </tr>
                </thead>
                <tbody>
                {this.state.episodes === undefined || this.state.episodes === null ? "" :this.state.episodes.map(
                    (item,index)=>{
                        return (
                            <RowComponent data={item}
                                          callback_delete={()=> this.removeEpisodesToProgram(item.id)}
                                          callback_editSort={(sortNumber,stage)=>this.editEpisodesSortNumber(sortNumber,item.id,stage)}
                                          row_number={index}
                            />)
                    }
                )}
                </tbody>
                <tfoot >
                <div className="console_TF">
                    <div className="mb-3">
                        <button className="btn btn-primary" style={{display:this.state.addEpisodesStatus === true ? "none" : "block"}}
                                onClick={()=>{
                                    this.setState({
                                        addEpisodesStatus:!this.state.addEpisodesStatus
                                    })
                                }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-plus" viewBox="0 0 16 16">
                                <path
                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                            </svg>
                            Add Episodes</button>
                        <button className="btn btn-secondary" style={{display:this.state.addEpisodesStatus === false ? "none" : "block"}}
                                onClick={()=>{
                                    this.setState({
                                        addEpisodesStatus:!this.state.addEpisodesStatus
                                    })
                                }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                      d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
                            </svg>
                            &nbsp;Complete Add
                        </button>
                    </div>
                </div>
                </tfoot>
            </table>
            {this.state.addEpisodesStatus===false ? <div /> :
                <AddEpisodesComponent callback_selected={(id)=>this.addEpisodesToProgram(id)}
                                      AlreadyEpisodesID={this.getEpisodeListById()}/>}
        </div>)
    }
}

function RowComponent({data,callback_delete,callback_editSort,row_number:number}) {

    let [editState,setEditState] = React.useState(false);
    let [sortNumber,setSortNumber] = React.useState(data.sort_number===undefined ? 0 : data.sort_number);
    let [stage,setStage] = React.useState();


    React.useEffect(()=>{
        setSortNumber(data.sort_number);
        setStage(data.stage);

    },[data]);

    return (

        <tr>
            <th>{data.video.id}</th>
            <th><img src={data.video.thumb} style={{width:"220px",height:"120px"}} alt={"s"}/></th>
            <th>{data.video.title}</th>
            <th>
                <div className="input-group-sm mb-3">

                    {editState===true ?
                        <input type="text"
                               className="form-control"
                               style={{width:"50px",display:"inline-block"}}
                               value={sortNumber}
                               onChange={(e)=>{
                                   setSortNumber(e.target.value);
                               }}
                        /> :  <span className="form-text" onClick={()=>{
                            setEditState(true)
                        }}>{sortNumber}</span>}
                    <button className="btn btn-primary"
                            style={{display:editState===true ? "inline-block" : "none"}}
                            onClick={()=>{
                                callback_editSort(sortNumber,stage);
                                setEditState(false);
                            }}
                    >Submit</button>
                </div>
            </th>
            <th><button className="btn btn-danger"
                        onClick={()=>{
                            callback_delete(data.video_id)
                        }}
            >Delete</button> </th>
        </tr>
    )
}



function AddEpisodesComponent(props) {

    let {callback_selected,AlreadyEpisodesID} = props;
    let [searchResult,setSearchResult] = React.useState([]);
    let [keyword,setKeyword] = React.useState();
    let [currentPage,setCurrentPage] = React.useState(1);
    let [totalPage,setPageTotal] = React.useState(0);


    let isDisable=(id)=>{
        let re = false;
        AlreadyEpisodesID.forEach(i=>{
            if (id!==undefined){
                if (i===id){return re=true}
            }
        });
        return re
    };

    React.useEffect(()=>{
        function init() {
            let a = new studioAPI();
            a.getVideoList({page_token:"",program_id:"not"},(response)=>{

                let d = response === null ? [] :response;
                let data =[];
                d.forEach((it,index)=>{
                    if (it.program === null){
                        data.push(it)
                    }
                });
                setSearchResult(data)},(msg)=>{window.alert(msg["error_message"])})
        }
        init()

    },[]);

    return (
        <div className={"episodesBox pb-3"}>
            <div className="mb-3 input-group" style={{padding:"10px"}}>
                <input className="form-control" placeholder={"Please enter a video ID or name"}
                       onChange={(e)=>{
                           setKeyword(e.target.value)
                       }}
                />
                <button
                    className="btn btn-outline-secondary"
                    onClick={()=>{
                        let a=new studioAPI();
                        a.search({keyword:keyword,"page":currentPage},(response)=>{
                            if (response !== null ){
                                setSearchResult(response.items);
                                setCurrentPage(response.current_page);
                                setPageTotal(response.total_page);
                            }
                        },(msg)=>{window.alert(msg)})
                    }}
                >Search</button>
            </div>
            <ul className={"episodes_searchResult"}>

                {searchResult.map(item=>{
                    return (
                        <li>
                            <div className="episodesRow">
                                <div className="img">
                                    <DefaultImageComponent src={IsHttpsURL(item.thumb,(url)=>MakeStaticURL(url))} isPoster={false} itemId={item.title}/>
                                </div>
                                <div className={"text"}>
                                    <span >{item.title}</span>
                                    <span >{`Published On ${item.create_time}`}</span>
                                </div>
                                <div className={"operation"}>
                                    <button className="btn btn-primary"
                                            onClick={()=>{
                                                callback_selected(item.id)
                                            }}
                                            disabled={isDisable(item.id)}
                                    >{isDisable(item.id) === true ? "Selected" : "Select This"}</button>
                                </div>
                            </div>
                        </li>
                    )
                })}
            </ul>
            <div className="text-center ">
                <button className="btn btn-primary" disabled={currentPage >= totalPage} onClick={()=>{
                    let a=new studioAPI();
                    a.search({keyword:keyword,"page":currentPage + 1},(response)=>{
                        if (response !== null){
                            setSearchResult(response.items);
                            setCurrentPage(response.current_page);
                            setPageTotal(response.total_page);
                        }
                    },(msg)=>{window.alert(msg)})
                }}>{currentPage >= totalPage ? "没有更多了～": "下一页 / Next Page"}</button>
            </div>
        </div>
    )
}
AddEpisodesComponent.propTypes={
    callback_selected:PropTypes.func,
    AlreadyEpisodesID:PropTypes.array,
};
AddEpisodesComponent.defaultProps={
    callback_selected:()=>{},
    AlreadyEpisodesID:[],
};
