import React from "react";


const AuthProviderBasie ={

    isAuthenticated:false,

    signUp(callback){
        this.isAuthenticated = true;
        setTimeout(callback,100);
    },
    signOut(callback){
        this.isAuthenticated =false;
        setTimeout(callback,100)
    },

};

export {AuthProviderBasie}