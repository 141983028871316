import React from "react";
import {TabComponent, TitleComponent} from "../../CommonComponents/Tab";
import {withRouter} from "../../../../CommonComponents/WithRouter";
import {VideoAnalyticsComponent} from "./Analytics";
import {VideoInformationComponent} from "./Info";
import {studioAPI} from "../../../../../api/base";
import {WaitingComponent} from "../../../../CommonComponents/Waiting";
import {UploadImageComponent} from "../../CommonComponents/UploadImage";

class VideoDetailComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            tabItem:[{id:1,label:"Video Information"},{id:2,label:"Video Thumb"},{id:3,label:"Video Analytics"}],
            selectedId:1,
            title:"",
            data:null,
            videoId:this.props.router.params.video_id,
        }
    }

    componentDidMount(): void {

        let a = new studioAPI();
        a.getVideoDetail(
            {
                id:this.state.videoId,
                page_token:this.props.router.pageToken.to(),
            },
            (response)=>{
                this.setState({data:response,title:response.title})},
            (msg)=>{window.alert(msg)}
        )
    }

    onModify(p){
        let a = new studioAPI();
        a.modifyVideo(
            p,
            (response)=>{
                if (response.code === 200){
                    this.setState({data:response,title:this.state.data.title});
                    this.props.router.navigate("/studio/video",{replace:true});
                }else{
                    window.alert(response.message["error_message"]);
                }
            },
            (msg)=>{

                if(msg.status === 500){
                    window.alert("系统发生错误!")
                    return
                }else{
                    window.alert("提交失败!")
                }

            }
        )
    }
    deleteVideo(){

        let  a= new studioAPI();
        a.deleteVideo({
            id:this.state.videoId,
            page_token:this.state.page_token,
        },(resposne)=>{
            this.props.router.navigate("/studio/video",{replace:true});
        },(message)=>{
            window.alert(message.error_message)
        })
    }
    renderContent(){
        switch (this.state.selectedId) {
            case 1:
                return (<VideoInformationComponent callback_onSubmit={(p)=>{this.onModify(p)}} data={this.state.data}/>);
            case 2:
                return (<UploadImageComponent itemId={this.state.videoId} pageToken={this.state.pageToken} itemType={"video"}
                                              afterSuccessCallback={()=>{}} aspectRatio={16/9}/>);
            case 3:
                return (<VideoAnalyticsComponent id={this.state.videoId}/>);
        }
    }

    render(){
        if(this.state.data === null){return (
            <div className="studioWrap">
            <div className="console">
                <WaitingComponent setBorder={false}/>
            </div>
        </div>)}


        return <div className="studioWrap">
            <div className="console">
                <div className="console_inside">
                    <TitleComponent callback_onClick={()=>this.deleteVideo()} title={this.state.title}/>
                    <TabComponent callback_OnSelected={(id)=>{this.setState({selectedId:id})}} tab_item={this.state.tabItem} />
                    {this.renderContent()}
                </div>
            </div>
        </div>
    }
}

export const VideoDetailPage = withRouter(VideoDetailComponent);