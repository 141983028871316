import React from "react";
import {Link} from "react-router-dom";
import {ReactEmojiEditor,ReactEmojiShow} from "react-emotor";
import {useAuth} from "../../Auth/Route";
import {api, ChannelTopicToType, CommentTopicToType, ProgramTopicToType, VideoTopicToType} from "../../api/base";
import * as Base64 from "js-base64";
import {IsHttpsURL, MakeStaticURL, uniqueData} from "./LittleFunc";


export function DiscussComponent({itemId="",topic="video",authorId="",pageToken=""}) {

    //let [data,setData] = React.useState([]);
    let [DiscusContent,setDiscussContent] = React.useState();
    let [discussList,setDiscussList] = React.useState([]);
    let [onlineUser,setOnlineUser] = React.useState(null);

    let auth = useAuth();
    let content = React.useRef();


    let getComment = ()=>{
        api.GetComment({
            topic:topic,
            item_id:itemId,
            page_token:pageToken,
        },(response)=>{
            if (response === null){
                return null
            }
            setDiscussList(response);
        },(msg)=>{console.log(msg["error_message"])})
    };
    let publishComment = (event)=>{


        event.target.disabled = true;
        api.publishComment({
            content:toBase64(DiscusContent),
            item_id:itemId,
            topic:topic,
            page_token:pageToken,
            reply:null,
        },(response)=>{
            let d = [response];
            discussList.map(ite=>(
                d.push(ite)
            ));
            setDiscussList(d);
            setDiscussContent(null);
            return content.current.clean();
        },(msg)=>{
            console.log(msg["error_message"])
        });
        return event.target.disabled = false;
    };

    let addComment = (data)=>{
        let d =[data];
        discussList.forEach((it,_)=>{
            d.push(it)
        });
        setDiscussList(d)
    };

    React.useEffect(()=>{
        function init() {
            getComment();
            if (auth.isLoggedIn === true){
                setOnlineUser(auth.user);
            }
        }
        init()
    },[]);

    React.useEffect(()=>{
        setOnlineUser(auth.user)
    },[auth]);


    let renderSendBox = ()=>{

        return (
                <div className="mb-3 mt-3">
                    <ReactEmojiEditor ref={content}
                                      onChange={(content) => {
                                          setDiscussContent(content)
                                      }}
                    />
                    <div className="justify-content-end d-flex mt-2">
                        <button className="btn btn-danger" onClick={(event) => publishComment(event)}>Comment</button>
                    </div>
                </div>

        )
    };

    let renderNeedLogin = ()=>{
        return (
            <div className="mb-3">
                <span className="text-center fs-5">评论需要您<Link to={"/login"} replace={true}>登陆</Link>，假如你没有账号？可以去<Link to={"/register"} replace={true}>注册</Link>一个</span>
            </div>
        )
    };


    return (
        <div className="bg-white float-start w-100  p-3" style={{marginTop:"20px"}} id="discuss_component">
            <div className="fw-bold fs-4">
                评论区
            </div>
            {onlineUser === null ? renderNeedLogin() : renderSendBox()}
            <div className="list-group mt-3">
                {
                    discussList.map(item=>(
                        <DiscussContentComponent data={item} authorId={authorId}
                                                 topic={topic}
                                                 itemId={itemId}
                                                 pageToken={pageToken}
                                                 isRoot={true}
                                                 onlineUser={onlineUser}/>
                    ))}
            </div>
        </div>
    )
}

function DiscussContentComponent({data,authorId="",topic="video",itemId="",pageToken="",onlineUser={},isRoot=true,callback_addComment=()=>{} }) {


    let [showReplyComponent,setShowReplyComponent] = React.useState(false);
    let [isLiked,setIsLiked] = React.useState(false);



    let userIcon = IsHttpsURL(data.creator.icon,(url)=>{return MakeStaticURL(url)});
    let userName = data.creator.name;
    let userId = data.creator.id;
    let commentContent = base64To(data.content);
    let comment_id = data.id;
    let remind = data.reply_who;
    let parent_id = data.parent_id === null ? data.id : data.parent_id.length > 0 ? data.parent_id : data.id ;

    let [likeAmount,setLikeAmount] = React.useState(0);
    let [replyAmount,setReplyAmount] = React.useState(data.reply_amount);
    let [published,setPublished] = React.useState(renderPublished(data.published));

    let [seeReplies,setSeeReplies] = React.useState(false);
    let [repliesData,setRepliesData] = React.useState([]);

    let reply = (data)=>{
        api.publishComment({
            topic:topic,
            item_id:itemId,
            content:toBase64(data.content),
            page_token:pageToken,
            parent_id:parent_id,
            reply:{
                user_id:userId,
                user_name:userName,
                user_icon:userIcon,
                comment_id:comment_id,
            },
        },(response)=>{
            if (isRoot === false){
                setShowReplyComponent(false);
                setReplyAmount(replyAmount + 1);
                return callback_addComment(response);
            }else{
                let r = repliesData === null ? [] : repliesData;
                r.push(response);
                setRepliesData(r);
                setShowReplyComponent(false);
                setReplyAmount(replyAmount + 1);
                return null
            }
        },(msg)=>{
            window.alert(msg["error_message"])
        })
    };

    let getLikeAmount = ()=>{
        api.GetLikeAmount({
            id:comment_id,
            type:CommentTopicToType,
        },(response)=>{
            let amount = response["amount"];
            if (amount > 0){
                api.IsLiked({
                    id:comment_id,
                    type:CommentTopicToType
                },(response)=>{
                    setIsLiked(response.is_liked)
                },(msg)=>{
                    console.log(msg["error_message"])
                })
            }
            setLikeAmount(response["amount"])
        },(msg)=>{
            console.log(msg["error_message"])
        });
    };

    let getRepliesData = ()=>{
        api.GetReply({
            parent_id:parent_id,
            page_token:pageToken
        },(response)=>{
            if (response === null){
                return null
            }
            if (repliesData.length > 0){
                response.push(...repliesData)
            }
            response = uniqueData(response);
            setRepliesData(response);
            setReplyAmount(response.length);
        },(msg)=>{
            window.alert(msg["error_message"])
        })
    };

    let addReply= (data={})=>{
        let r = repliesData;
        r.push(data);
        setRepliesData(r);
        setReplyAmount(r.length);
        setShowReplyComponent(false);
    };

    React.useEffect(()=>{

        function init(){
            getLikeAmount();
            let t = window.setInterval(()=>{
                let p =renderPublished(data.published);
                setPublished(p);
            },1000*60);
        }
        init()
    },[]);

    let giveLike = ()=>{

        api.GiveLike({
            type:CommentTopicToType,
            id:comment_id,
            isLiked:!isLiked,
            page_token:pageToken
        },(response)=>{
            if (isLiked === true){
                setLikeAmount(likeAmount-1);
            }else{
                setLikeAmount(likeAmount+1);
            }
            setIsLiked(!isLiked);
        },(msg)=>{
            window.alert(msg["error_message"])
        })
    };

    let renderContent = (content)=>{

        if ( remind !== null && remind !== undefined && remind.user_id.length >0 && remind.user_name.length > 0){
            return <span ><Link to={`/user/${remind.user_id}`} replace={true} style={{fontSize:"17px"}}>回复@{remind.user_name}</Link>:&nbsp;<ReactEmojiShow content={content} /></span>
        }

        return <ReactEmojiShow content={content} />;
    };



    let renderLevelBadge= ()=>{
        return <span >
            {onlineUser== null ? "" : onlineUser.user_id === data.creator.id ? <span className="badge rounded-pill text-bg-primary text-white ms-1">我</span> : "" }
            {data.creator.id === authorId ? <span className="badge rounded-pill text-bg-dark text-white ms-1">视频作者</span> : ""}
            <span className="badge rounded-pill text-bg-success text-white ms-lg-1">{`等级 ${data.creator.level}`}</span>
        </span>
    };


    let renderLike = ()=>{
        return (
            <button className={`btn ${isLiked===true ? "btn-danger" : "btn-outline-danger"} text-center rounded-pill lh-base`} onClick={()=>giveLike()}>
                <i className="bi-heart-fill" style={{height: "15px", fontSize: "15px"}}/>
                <span className="fw-bold ms-lg-1 lh-base">{likeAmount<=0 ? "点赞" : `${likeAmount}人点赞`}</span>
            </button>
        )
    };

    let renderReplyButton = ()=>{

        if (showReplyComponent === true){
            return (
                <button className="btn btn-outline-dark text-center ms-lg-2 rounded-pill" onClick={()=>{
                    setShowReplyComponent(!showReplyComponent);
                }}>
                    <i className="bi-reply-fill " style={{height:"15px",fontSize:"15px"}} />
                    <span className="fw-bold ms-lg-1 lh-base">隐藏回复框</span>
                </button>
            )
        }

        return (
            <button className="btn btn-outline-primary text-center ms-lg-2 rounded-pill" onClick={()=>{
                setShowReplyComponent(!showReplyComponent);
            }}>
                <i className="bi-reply-fill " style={{height:"15px",fontSize:"15px"}} />
                <span className="fw-bold ms-lg-1 lh-base">{replyAmount <= 0 ? "回复" : `${replyAmount}条回复`}</span>
            </button>
        )
    };

    let renderReplies = ()=>{
        if (repliesData === null || repliesData.length <= 0){
            return ""
        }

        return <div className="mt-2 list-group">
            {repliesData.map(it=>(
                <DiscussContentComponent data={it}
                                         callback_comment={()=>{}}
                                         authorId={authorId}
                                         topic={topic}
                                         pageToken={pageToken}
                                         itemId={itemId}
                                         onlineUser={onlineUser}
                                         isRoot={false}
                                         callback_addComment={(data)=>{addReply(data)}}
                />
            ))}
        </div>
    };

    return (
        <div  className="list-group-item list-group-item-action">
            <div className="row">
                <div className="justify-content-center col-1 p-0 justify-content-center d-flex" >
                    <img src={userIcon} alt={userName} style={{borderRadius:"100%",width:"60px",height:"60px"}}/>
                </div>
                <div className="col-11 justify-content-center">
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1 fs-4 lh-base">{userName}{renderLevelBadge()}</h5>
                        <small className="fs-5">发布于 {published}</small>
                    </div>
                    <p className="fs-5 mb-1" style={{margin:0}}>{renderContent(commentContent)}</p>
                    <div >
                        {renderLike()}
                        {renderReplyButton()}
                    </div>
                    {showReplyComponent === false ? <div /> : <ReplyComponent
                        callback_hideComponent={()=>{setShowReplyComponent(!showReplyComponent)}}
                        callback_comment={(data)=>reply(data)}
                        author_name={userName}
                        comment_id={comment_id}
                    /> }
                    {replyAmount > 0 ? <a className={`btn btn-sm btn-outline-primary fw-bold mt-2 rounded-pill ${seeReplies === true ? "active" :""}`}
                            onClick={(event)=>{
                                setSeeReplies(!seeReplies);
                                getRepliesData();

                            }}
                    >{`查看${replyAmount}条回复`}</a> : "" }
                    {seeReplies === true ? renderReplies() : ""}
                </div>
            </div>
        </div>
    )
}

function ReplyComponent({callback_hideComponent=()=>{},callback_comment=()=>{},author_name="",comment_id=""}) {

    let [discussContent,setDiscussContent]= React.useState();
    let [disableButton,setDisableButton] = React.useState(false);


    React.useEffect(()=>{
        setDisableButton(false);
    },[]);

    return (
        <div className="p-2">
            <div className="form-floating">
                <textarea className="form-control" placeholder="Leave a comment here" onChange={(event)=>{
                    setDiscussContent(event.target.value)
                }} />
                <label >{`回复 ${author_name}`}</label>
            </div>
            <div className="w-100 d-flex justify-content-end mt-2 fs-5">
                <button className="btn btn-outline-dark" onClick={()=>{callback_hideComponent()}}>Cancel</button>
                <button className="btn btn-danger ms-1" disabled={disableButton}  onClick={(event)=>{
                    //console.log(discussContent);
                    callback_comment({
                        content:discussContent,
                    });
                    setDisableButton(true);
                }}>Reply</button>
            </div>
        </div>
    )
}

let renderPublished = (p)=>{

    let day = 3600*24;
    let month = 3600*24*30;
    let hour = 3600;
    let year = month * 12;

    let t = Date.parse(new Date())/1000;
    let t1 = (t-p);

    switch (true) {
        case t1 < 60:
            return "刚刚发布";
        case t1 > 60 && t1 < hour:
            return `${Math.round(t1 / 60)} 分钟前`;
        case t1 > hour && t1 < day:
            return `${Math.round(t1/hour)} 小时前`;
        case t1 > day && t1 < month:
            return `${Math.round(t1/day)} 天前`;
        case t1 > month && t1 < year:
            return `${Math.round(t1/month)} 月前`;
        case t1 > year:
            return "超过一年";
        default:
            return "很长时间"
    }
};

function toBase64(src) {
    return Base64.encode(src)
}

/**
 * @return {string}
 */
function base64To(src) {
    return Base64.decode(src)
}