import * as React from "react";
import {useAuth} from "./Route";
import {Navigate, useLocation} from "react-router";


export default function IsAuthStatus({ children }) {

    let auth = useAuth() ;// 获取验证对象
    let location = useLocation();


    if (!auth.isLoggedIn) {
        // 未登入，使用Navigate组件重定向到登录页，传入state属性以保存当前URL位置信息
        return <Navigate to={"/login"} state={{...location.state,
            age:location.search,
            pathname:location.pathname}}/>
    }
    return children // 验证通过，返回插槽内容，例如： ProtectedPage 页面(显示页面)
}