import React from "react";
import {Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {studioAPI} from "../../../../../api/base";
import {timestampToTime} from "../../../../CommonComponents/LittleFunc";

export function AdAnalyticsComponent({id}) {


    let [item,setItem] = React.useState([{name:"view",dataKey:"view",color:"#0099ff"},{name:"click",dataKey:"click"}]);
    let [data,setData] = React.useState(null);
    let [startTime,setStartTime] = React.useState(null);
    let [endTime,setEndTime] = React.useState(null);


    const getData = ()=>{

        let api = new studioAPI();
        api.getStatistics({
            "id":id,
            "start_time":startTime,
            "end_time":endTime,
            "item_type":"ad",
        },(response)=>{

            if (response !== null && typeof(response) === "object"){
                let m ={};
                response.forEach((it,index)=>{
                    let date = timestampToTime(it.time);
                    if (m.hasOwnProperty(date)===true){
                        let i = m[date];
                        if (i.hasOwnProperty(it.event)===true){
                            i[it.event]++
                        }else{
                            i[it.event] = 1
                        }
                    }else{
                        let i = {};
                        i[it.event] = 1;
                        m[date] = i
                    }
                });
                let data =[];
                for (let key in m){
                    data.push(
                        {date:key,...m[key]}
                    )
                }
                setData(data)
            }else{

                let data = []

                let timestamp = Date.parse(new Date());
                timestamp = timestamp /1000;
                for (let i=0;i<30;i++){

                    let t = timestamp - (86400*i);
                    let date = timestampToTime(t);
                    data.push(
                        {date:date,click:0,view:0}
                    )
                }
                setData(data)
            }

        },(msg)=>{window.alert(msg)})

    };

    React.useEffect(()=>{
        getData()
    },[]);

    if (item === null || data===null){
        return <div />
    }
    return(
        <div className="console_content">
            <div style={{width:"100%",display:"block"}}>
                <LineChart width={800} height={300} data={data}>
                    <XAxis dataKey="date"/>
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                    {item.map(
                        i=>(
                            <Line type="monotone" dataKey={i.dataKey}  name={i.name} stroke={i.color===undefined ? "#333" : i.color} />
                        )
                    )}
                </LineChart>
            </div>
        </div>
    )
}