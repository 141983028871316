import * as React from "react";
import {withRouter} from "../../../../CommonComponents/WithRouter";
import {TabComponent, TitleComponent} from "../../CommonComponents/Tab";
import {AdInformationComponent} from "./Information"
import {SourceComponent} from "./Source";
import {AdAnalyticsComponent} from "./Analytics";
import {studioAPI} from "../../../../../api/base";
import {UploadImageComponent} from "../../CommonComponents/UploadImage";
import {WaitingComponent} from "../../../../CommonComponents/Waiting";


class AdsDetailComponent extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            tabItem:[
                {id:1,label:"Advertising Information"},
                {id:2,label:"Advertising Source"},
                {id:3,label:"Advertising Analytics"}
            ],
            selectedTab:1,
            data:null,
            programList:[],
            adId:this.props.router.params.ad_id,
            pageToken:this.props.router.pageToken.to(),
            isPutting:false,
        }
    }
    componentDidMount(): void {
        if (this.state.data === null){
            this.getData()
        }
    }
    putAD(){
        if (this.state.isPutting === false) {
            let a = new studioAPI();
            a.putAd(
                {id: this.state.adId, ad_spot_id:this.state.data.ad_spot.id,page_token: this.state.pageToken},
                (response) => {
                    this.setState({isPutting: true})
                },
                (msg)=>{window.alert(msg["error_message"])}
            )
        }else{
            let  a= new studioAPI();
            a.putOutAd(
                {id:this.state.adId,ad_spot_id:this.state.data.ad_spot.id,page_token:this.state.pageToken},
                (response)=>{
                    this.setState({isPutting:false})
                },
                (msg)=>{window.alert(msg["error_message"])}
            )
        }
    }
    getData(){
        let a = new studioAPI();
        a.getAdvertising(
            {id:this.state.adId,page_token:this.state.pageToken},
            (response)=>{
                this.setState({data:response,isPutting:response.serving_status===1})
            },
            ()=>{})
    }

    modifyProgram(param){

        let a = new studioAPI();
        a.modifyAd(
            {
                "name":param.name,
                "program_id":param.program_id,
                "slogan":param.slogan,
                "id":this.state.data.id
            },
            (response)=>{
                this.getData()
            },
            (msg)=>{}
        )
    }

    renderContent(){

        switch (this.state.selectedTab) {
            case 1:
                return (<AdInformationComponent programList={this.state.programList} data={this.state.data} callback_submit={(param)=>this.modifyProgram(param)}/>);
            case 2:
                return (<SourceComponent data={this.state.data} pageToken={this.state.pageToken} callback_refresh={()=>{this.getData()}}/>);
            case 3:
                return (<AdAnalyticsComponent id={this.state.data.id} />);
        }
    }
    render(){
        if (this.state.data === null) {
            return (<div className="studioWrap">
                <div className="console">
                    <WaitingComponent setBorder={false}/>
                </div>
            </div>)
        }

        return (
            <div className="studioWrap">
                <div className="console">
                    <div className="console_inside">
                        <TitleComponent title={this.state.data.name} callback_onClick={()=>this.putAD()}
                                        btn_title={this.state.isPutting === false ? "投放广告" : "投放中..."}
                                        btn_disable={false}
                        />
                        <TabComponent tab_item={this.state.tabItem} callback_OnSelected={(id)=>{
                            this.setState({selectedTab:id})
                        }}/>
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

export const AdsDetailPage = withRouter(AdsDetailComponent);

