import * as React from "react";


export function TabComponent({tab_item,callback_OnSelected}) {

    tab_item =  tab_item === undefined ? [] : tab_item;
    tab_item =  tab_item === null ? [] : tab_item;

    callback_OnSelected = callback_OnSelected === undefined ? ()=>{} : callback_OnSelected;

    let getIndex= () =>{
        let id = 1;
        tab_item.forEach(i=>{
            if(i.index === true){return id = i.id};
        });
        return id;
    };

    let [selectedId,setSelectedId] = React.useState(getIndex());


    return (
        <div className="console_nav" style={{width:"100%"}}>
            <ul className="nav nav-tabs" style={{height:"auto",background:"#fff",color:"#333"}}>
                { tab_item.map(item=>(
                    <li className="nav-item" style={{width:"auto",height:"auto",float:"none"}}>
                        <a className={item.id === selectedId ? "nav-link active" : "nav-link"}
                           onClick={()=>{
                               callback_OnSelected(item.id);
                               setSelectedId(item.id);
                           }}
                        >{item.label}</a>
                    </li>
                ))}
            </ul>
        </div>
    )
}


export function TitleComponent({title,callback_onClick,btn_title="Delete",btn_disable=false}) {


    let [dt,setDisplayTitle] = React.useState(title);

    React.useEffect(
        ()=>{
            setDisplayTitle(title)
        },
        [title]
    );


    callback_onClick = callback_onClick === undefined ? ()=>{} : callback_onClick;



    return (
        <div className="mb-3">
            <div className="col-10 d-inline-block">
                <span style={{fontSize:"20px",fontWeight:"bold",lineHeight:"25px"}}>{dt}</span>
            </div>
            <div className="col-2 d-inline-block justify-content-center text-center " >
                <button className="btn btn-outline-danger" style={{lineHeight:"25px"}} onClick={(e)=>{
                    e.target.disabled=true;
                    setTimeout(()=>{
                        e.target.disabled = false;
                    },1000*10);
                    callback_onClick() }} disabled={btn_disable}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-stoplights" viewBox="0 0 16 16">
                        <path
                            d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1.5 2.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                        <path
                            d="M4 2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v2h2c-.167.5-.8 1.6-2 2v1a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-1c-1.2-.4-1.833-1.5-2-2h2V8c-1.2-.4-1.833-1.5-2-2h2V4c-1.2-.4-1.833-1.5-2-2h2zm2-1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6z"/>
                    </svg>
                    &nbsp;{`${btn_title}`}</button>
            </div>
        </div>
    )
}