import * as React from "react";
import {Link} from "react-router-dom";


export class HNComponent extends React.Component{


    render(){
        return (
            <div className="navbar navbar-expand-lg bg-body-tertiary"
                 style={{borderBottom:"1px solid #eee",background:"#fff",width:"100%",position:"fixed",top:"0",left:"0",zIndex:3}}>
                <div className="container-fluid">
                    <div className="navbar-header" style={{marginLeft:"20px"}}>
                        <span className="navBarBrand">Yimi.<span className="font2">TV</span></span>
                    </div>
                    <div className="collapse navbar-collapse" style={{marginLeft:"20px"}}>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to={"/"}>Home</Link></li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/listing"}>Program</Link></li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/live"}>Live</Link></li>
                            <li className="nav-item">
                                <Link className="nav-link" to={"/gaming"}>Gaming</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}