import React from "react";
import {studioAPI} from "../../../../../api/base";
import * as qiniu from "qiniu-js";
import {UploadImageComponent} from "../../CommonComponents/UploadImage";


export function SourceComponent({data,pageToken,callback_refresh=()=>{}}) {


    let [sourceList,setSourceList] = React.useState(data.source===null ? [] : data.source);
    let [insertState,setInsertState] = React.useState(false);




    const deleteSource = (id)=>{};

    return (
        <div>
            {insertState === true ? <UploadImageComponent itemId={data.id} itemType={"ad"} pageToken={pageToken}
                                                          aspectRatio={data.width / data.height}
                                                          afterSuccessCallback={() => {
                                                              setInsertState(false);
                                                              callback_refresh();
                                                          }}/> :
                <SourceTableComponent sourceList={sourceList} callback_delete={(id) => {
                    deleteSource(id)
                }} callback_insertSource={() => {
                    setInsertState(true)
                }}/>}
        </div>
    )
}


function SourceTableComponent({sourceList,callback_delete,callback_insertSource}) {

    let [data,setData] = React.useState(sourceList);

    React.useEffect(()=>{setData(sourceList)},[sourceList]);


    return (
        <div className="console_content">
            <table className="table">
                <thead>
                <tr>
                    <th>Source Id</th>
                    <th>Source Click</th>
                    <th>Source Status</th>
                    <th>Source Remark</th>
                    <th>Operation</th>
                </tr>
                </thead>
                <tbody>
                {sourceList.map(item => (
                    <tr>
                        <th>{item.id}</th>
                        <th>{item.click}</th>
                        <th>{item.status}</th>
                        <th>{item.remark}</th>
                        <th>
                            <button className="btn btn-danger" onClick={()=>{callback_delete(item.id)}}>Delete</button>
                        </th>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={()=>{callback_insertSource()}}>Add Source</button>
            </div>
        </div>
    )
}