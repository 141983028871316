import * as React from "react";
import {Link, useNavigate} from "react-router-dom";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import Helmet from "react-helmet";
import {BusinessModal} from "../CommonComponents/Modal";
import {api} from "../../api/base";
import {useAuth} from "../../Auth/Route";

export function PlanPage() {


    let [planPackage,setPlanPackage] = React.useState([]);
    let [selectPlan,setSelectPlan] = React.useState(null);
    let [openModal,setOpenModal] = React.useState(false);

    let auth = useAuth();
    let nav = useNavigate();


    React.useEffect(()=>{

        function init(){
        let p = [
            {name:"入门级",subtitle:"使用簡易工具，讓粉絲為你提供無間支持。",slogan:"免月租,但账号的每笔收入20%作为服务费",price:0,id:"a",benefits:[
                    {name:"展示創作人頁面",isImport:false},
                    {name:"可发布时长不超过5分钟视频"},
                    {name:"Yimi.TV 工作坊"},
                ]},
            {name:"達人級",subtitle:"網羅一切所需工具，助你建立發展蓬勃的會員制業務，為你帶來合理收入，同時為最熱心的支持者提供物有所值的體驗。",slogan:"每月基础费用为$19.99,其账号收益归您所有",price:19.99,id:"b",isCommend:true,benefits:[
                    {name:"入門級的所有服務，以及...",isImport: true},
                    {name:"达人专属标识"},
                    {name:"节目推广工具"},
                    {name:"可发布长视频"},
                    {name:"订阅会员通讯工具"},
                    {name:"达人收益工具"},
                    {name:"达人客户经理"}
                ]},
            {name:"商家級",subtitle:"專為已有基礎的創作人和創意事業而設的指導和支援，加上多種達人級功能，助你節省時間和回饋訂閱會員。",slogan:"每月基础费用为$21.99,其账号收益归您所有",price:21.99,id:"c",benefits:[
                    {name:"達人級的所有服務，以及...",isImport: true},
                    {name:"专属品牌标识"},
                    {name:"商家销售服务"},
                    {name:"商家专享品牌广告"},
                    {name:"商家专业经理"},
                    {name:"官方定向采购服务"},
                ]}
        ];
        setPlanPackage(p)
        }
        init()

    },[]);

    let makeBill = ()=>{

        let d = {};
        planPackage.forEach((it,index)=>{
            if (it.id === selectPlan){
                d = {
                    name:`Yimi.TV ${it.name}`,
                    currency:"USD",
                    price:it.price,
                    start_time:"2023-06-18"
                }
            }
        });
        return d
    };

    let createOrder = (p)=>{
        let d = false;

        api.SubscribePlan({
            id:selectPlan,
            payment_method:p.payment_method,
            payment_information:p.payment_information
        },(response)=>{
            d= true;
        },(msg)=>{
            console.log(msg["error_message"]);
            d = false;
        },(url)=>{});

        return d
    };



    return (
        <div>
            <Helmet>
                <title>选择您的计划 - Yimi.TV</title>
            </Helmet>
            <HeadNavComponent/>
            <div className="container-fluid">
                <div className="w-100 h-auto container">
                    <div className="container-fluid pt-4 pb-4">
                        <div className="row text-center">
                            <div className="d-flex justify-content-center">
                                <div className="col-6 pb-5">
                                    <h1>你成功，我们才成功</h1>
                                    <div
                                        className="lead">登記使用Patreon完全免費。你開始透過Patreon賺取收入後，我們才會按百分比收取少許費用，以及依照行業標準收取處理訂閱會員付款的費用。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-5">

                            {
                                planPackage.map(it => (
                                    <PlanComponent data={it} callback_selectPlan={(id) => {
                                        /*if (auth.isLoggedIn === false){
                                                nav("/login",{replace:true,state:{pathname:"/plan"}})
                                                return
                                        };*/
                                        setSelectPlan(id);
                                        setOpenModal(()=>true);
                                    }} defaultSelected={selectPlan === null ? it.price <= 0 : it.id === selectPlan}/>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="container">
                        <AboutFeeComponent/>
                        <AboutIncomeComponent/>
                        <div className="row bg-white">
                            <div className="col-12 text-center pt-3 pb-3" style={{height: "300px"}}>
                                <div className="w-100 " style={{marginTop: "8%"}}>
                                    <div className="w-100">
                                        <span className="fs-1">加入超過20萬位創作人的行列，重新定義作品的價值</span>
                                    </div>
                                    <div className="w-100 mt-2 mb-2">
                                        <button className="btn btn-danger rounded-5">立即开始</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BusinessModal openModal={openModal} callback_close={()=>{setOpenModal(!openModal)}}
                            callback_onSubmit={(p)=>createOrder(p) }
                            callback_finish={(r)=>{setOpenModal(false)}}
                            bill={makeBill()}
            />
        </div>
    )
}

function PlanComponent({data,defaultSelected=false,callback_selectPlan=()=>{}}) {


    let [isSelected,setSelected] = React.useState(defaultSelected);


    React.useEffect(()=>{
        setSelected(defaultSelected)
    },[defaultSelected]);


    let onClick = ()=>{
        if (isSelected === false){
            callback_selectPlan(data.id)
        }
        setSelected(!isSelected);
    };

    let renderPrice = (price)=>{

        if (price <= 0 ){
            return (<span className="fs-1 fw-bold" style={{color: "#FF424D"}}>Free</span>)
        }
        let p = price.toFixed(2);
        let r = p.split(".")[1];
        let i = p.split(".")[0];
        return (
            <span className="fs-1 fw-bold" style={{color: "#FF424D"}}>${i}<small class="fs-3">.{r}</small></span>
        )

    };

    let renderBtn = ()=>{

        if (isSelected === true){
            return (
                <div className="card-footer">
                    <div className="w-100 " style={{height:"50px"}}>
                        <button className="btn btn-outline-danger rounded-5 w-100 h-100" disabled={true}>已启用</button>
                    </div>
                </div>
            )
        }

        return (
            <div className="card-footer text-center " style={{fontSize:"16px"}}>
                <div className="w-100 mb-3" style={{height:"50px"}}>
                    <button className="btn btn-danger rounded-5 w-100 h-100 fw-bold fs-5" onClick={()=>onClick()}>选择该计划</button>
                </div>
                <Link className="link-danger text-decoration-none" to={`/plan/${data.id}`}>了解更多</Link>
            </div>
        )

    };

    return (
        <div className="col-4 " style={{height: "700px"}} >
            <div className="card h-100">
                <div className="card-header text-center bg-white">
                    <h2>{data.name}{data.isCommend === true ?
                        <span className="badge text-bg-danger rounded-5 align-middle ms-1"
                              style={{fontSize:"16px"}}>推荐</span> : ""}</h2>
                    <p style={{fontSize: "16px"}}>{data.subtitle}</p>
                </div>
                <div className="card-body">
                    <p className="fs-4 m-0 p-0 fw-bold" style={{fontSize: "18px"}}>
                        {renderPrice(data.price)}
                    </p>
                    <div style={{
                        color: "#706C64",
                        fontSize: "14px",
                        height: "25px",
                        lineHeight: "25px"
                    }} className="mb-3">
                        {data.slogan}
                    </div>
                    <div className="pt-3 pb-3 border-top border-1 border-bottom mb-3"
                         style={{border: "#eee"}}>
                        <div className="w-100" style={{
                            color: "#706C64",
                            height: "30px",
                            lineHeight: "30px",
                            fontSize: "16px"
                        }}>
                            <i className="bi bi-cash-coin me-1"/>另加收款处理费用
                        </div>
                    </div>
                    {   data.benefits.map(it=>(
                        <p style={{fontSize:"16px"}} className={`m-0 mb-2 ${it.isImport === true ? "fw-bold" :""}`}>
                            <i className="bi bi-check me-3 text-danger fs-5"/>{it.name}</p>
                    ))}
                </div>
                {renderBtn()}
            </div>
        </div>
    )

}


function AboutFeeComponent() {


    let [items,setItems] = React.useState([]);


    React.useEffect(()=>{
        function init() {
            let i = [
                {title:"定期账单",description:"我们会每月向您的订阅会员开具账单，并处理订阅会员的问题，以确保他们满意。"},
                {title:"恢复失败的付款",description:"我们通过智能重试和过期卡片提醒来追踪交易失败的付款。"},
                {title:"保护创作者免受欺诈",description:"我们会在诈骗魔爪伸向您的账户之前及时拦截，让您不必担心。"},
                {title:"Flexible processing fees",description:"adjust these fees based on your tier pricing and your patrons’ currency."}
            ];
            setItems(i)
        }
        init()

    },[]);

    return (
        <div className="row ">
            <div className="col-12 ">
                <div className="pt-4 pb-4">
                    <div className="row text-center w-100">
                        <h2>关于款项处理费用</h2>
                        <div className="lead">款项处理费涵盖我们为安全可靠地从您的订阅会员收取付款而在幕后开展的所有活动的费用。</div>
                    </div>
                    <div className="row pt-4 pb-4" style={{height:"300px"}}>
                        <div className="col-6">
                            <div className="w-100">
                                {items.map(it=>(
                                    <div className="w-100 pt-2 pb-2">
                                        <div className="w-100 fw-bold " style={{height:"30px",lineHeight:"30px",fontSize:"18px"}}>
                                            {it.title}
                                        </div>
                                        <div className="w-100 lead" style={{height:"20px",lineHeight:"20px",fontSize:"15px"}}>
                                            {it.description}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-6 h-100 ">
                            <div className="w-100 h-100 rounded-3 text-center"
                                 style={{lineHeight:"250px",border:"1px #eeeeee solid"}}>
                                <div className="w-100 h-auto">
                                    <div className="w-100 " style={{height:"50px"}}>
                                        <div className="fw-bold h-50 w-100" style={{fontSize:"35px"}}>5%</div>
                                        <div className="w-100 h-50 lead mt-1">根据您每笔收入来计算</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function AboutIncomeComponent() {

    let [items,setItems] = React.useState([]);

    React.useEffect(()=>{

        let i=[
            {title:"定期账单",description:"我们会每月向您的订阅会员开具账单，并处理订阅会员的问题，以确保他们满意。"},
            {title:"恢复失败的付款",description:"我们通过智能重试和过期卡片提醒来追踪交易失败的付款。"},
            {title:"保护创作者免受欺诈",description:"我们会在诈骗魔爪伸向您的账户之前及时拦截，让您不必担心。"},
            {title:"保护创作者免受欺诈",description:"我们会在诈骗魔爪伸向您的账户之前及时拦截，让您不必担心。"},
            {title:"保护创作者免受欺诈",description:"我们会在诈骗魔爪伸向您的账户之前及时拦截，让您不必担心。"},
            {title:"保护创作者免受欺诈",description:"我们会在诈骗魔爪伸向您的账户之前及时拦截，让您不必担心。"},
        ];
        setItems(i)
    },[]);


    return (
        <div className="row bg-white">
            <div className="pt-4 pb-4">
                <div className="row ">
                    <div className="col-12 text-center">
                        <h2>关于账号收益</h2>
                        <div className="lead">账号收益我们希望创作者能够独享，这样有助于激发创作积极性</div>
                    </div>
                </div>
                <div className="row pt-4 pb-4">
                    <div className="col-6">
                        <div className="w-100">

                            {items.map(it=>(
                                <div className="w-100 pt-2 pb-2">
                                    <div className="w-100 fw-bold "
                                         style={{height:"30px",lineHeight:"30px",fontSize:"18px"}}>
                                        {it.title}
                                    </div>
                                    <div className="w-100 lead"
                                         style={{height:"20px",lineHeight:"20px",fontSize:"15px"}}>
                                        {it.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-6">
                        <img src="images/212x133.jpg" className="w-100 h-100 img-thumbnail"/>
                    </div>
                </div>
            </div>

        </div>
    )
}