import * as React from "react";
import {DateSelectorComponent, PaginationComponent, SelectComponent} from "../CommonComponents/Form";
import {studioAPI} from "../../../../api/base";
import {withRouter} from "../../../CommonComponents/WithRouter";
import {WaitingComponent} from "../../../CommonComponents/Waiting";
import {NoRowComponent} from "../CommonComponents/View";


class VideoListComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            pageAmount:0,
            total:0,
            currentPage:1,
            pageToken:this.props.router.pageToken.to(),
            data:null,
            keyword:null,
            initState:true,
            sort_by:null,
            start_time:null,
            end_time:null,
            useKeywordModel:false
        }
    }
    componentDidMount(): void {
        if (this.state.data===null){
            this.getTotal(this.state.param);
        }
    }

    getTotal(params){
        let a =new studioAPI();
        a.getVideoListTotal(
            {...params,"about":"video"},
            (response)=>{
                let pageAmount = parseInt(response.page_amount);
                let total = parseInt(response.total);
                if(total > 0){
                    this.getData(1,params)
                }
                this.setState({
                    pageAmount:pageAmount,
                    total:total,
                    data:[],
                    initState:false,
                }
                )},
            (msg)=>{
                //console.log(msg["error_message"]);
                this.setState({
                    initState:false,
                    data:[],
                    total:0,
                    pageAmount:0
                })
            }
        );
    }
    getData(page,params){

        if (this.state.keyword !== null && this.state.useKeywordModel === true){
           return this.search(params,page)
        }

        let a = new studioAPI();
        a.getVideoList(
            {...params,"page":page,"return_data_type":"simple"},
            (response)=>{
                return this.setState({data:response,currentPage:page,initState:false})},
            (msg)=>{console.log(msg["error_message"])}
        )
    }
    delete(id){

        let a = new studioAPI();
        a.deleteVideo(
            {id:id,page_token:this.state.pageToken},
            (response)=>{
                let newList = [];
                this.state.data.forEach(item=>{
                    if (item.id !== id){
                        newList.push(item)
                    }
                });
                this.setState({
                    data:newList
                })
            },
            (msg)=>{window.alert(msg["error_message"])}
        )
    }
    search(params,page = 1){


        const a = new studioAPI();
        a.search({
            page:page,
            start_time:params.start_time,
            end_time:params.end_time,
            sort_by:params.sort_by,
            keyword:params.keyword
        },(response)=>{
            if (response === null || response === undefined){
                this.setState({
                    data:response,
                    pageAmount:1,
                    currentPage:1,
                    total:0,
                });
                return
            }
            this.setState({
                data:response.items,
                pageAmount:response.total_page,
                currentPage:response.current_page,
                total:response.total,
                keyword:params.keyword,
                sort_by:params.sort_by,
                start_time:params.start_time,
                end_time:params.end_time,
                useKeywordModel:true,
            })

        },(message)=>{
            console.log(message)
        });
    }
    render(){

        if (this.state.initState === true) {
            return (<div className="studioWrap">
                <div className="console">
                    <WaitingComponent setBorder={false}/>
                </div>
            </div>)
        }
        return <div className="studioWrap">
            <div className="console">
                <div className="console_inside" >
                    <div >
                        <div className="col-10 d-inline-block">
                            <h4>Video List</h4>
                        </div>
                        <div className="col-2 d-inline-block justify-content-center text-center " >
                            <button className="btn btn-outline-danger" style={{lineHeight:"25px"}} onClick={()=>{
                                this.props.router.navigate("/studio/video/upload",{replace:true})
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-film" viewBox="0 0 16 16">
                                    <path
                                        d="M0 1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1zm4 0v6h8V1H4zm8 8H4v6h8V9zM1 1v2h2V1H1zm2 3H1v2h2V4zM1 7v2h2V7H1zm2 3H1v2h2v-2zm-2 3v2h2v-2H1zM15 1h-2v2h2V1zm-2 3v2h2V4h-2zm2 3h-2v2h2V7zm-2 3v2h2v-2h-2zm2 3h-2v2h2v-2z"/>
                                </svg>
                                &nbsp;Upload Video</button>
                        </div>
                    </div>
                    <VideoListHeadComponent callback_search={(param)=>{
                            if (param.keyword === null || param.keyword === undefined ){
                                return
                            }
                            this.search(param);
                    }}  callback_reset={()=>{

                        this.setState({
                            keyword:null,
                            useKeywordModel:false,
                            sort_by:null,
                            start_time:null,
                            end_time:null,
                        });
                        this.getTotal({
                             sort_by:"create_time"
                        })
                    }}/>
                    <div >
                        <table className="table">
                            <thead >
                                <tr>
                                    <th>Video ID</th>
                                    <th>Video Title</th>
                                    <th>Video Status</th>
                                    <th>Video Viewers</th>
                                    <th>Operation</th>
                                </tr>
                            </thead>
                            <tbody>


                            {this.state.data === null ? <NoRowComponent/> : this.state.data.map(item=>(
                                <RowComponent callback_delete={(id)=>this.delete(id)}
                                              callback_edit={(id)=>{
                                                  this.props.router.navigate(`/studio/video/${id}`,{replace:true})
                                              }}
                                              data={item}
                                />
                            ))}

                            </tbody>
                        </table>
                    </div>
                    <div className="mb-3">
                        <PaginationComponent currentPage={this.state.currentPage}
                                             maxPage={this.state.pageAmount}
                                             callback_switch={(page)=>{
                                                 this.getData(page,{
                                                     keyword:this.state.keyword,
                                                     sort_by:this.state.sort_by,
                                                     start_time:this.state.start_time,
                                                     end_time:this.state.end_time
                                                 })
                                             }} />
                    </div>
                </div>
            </div>
        </div> ;
    }
}


function VideoListHeadComponent({callback_search=()=>{},callback_reset=()=>{}}) {


    let [startTime,setStartTime] = React.useState(null);
    let [endTime,setEndTime] = React.useState(null);
    let [sortOrder,setOrder] = React.useState(null);
    let [keyword,setKeyword] = React.useState(null);
    let keywordInput = React.createRef();
    let dateSelector = React.createRef();
    let sortSelector = React.createRef();


    let sm = [
        {id:"create_time",name:"按照创建时间进行排列 / Sort by creation time"},
        {id:"view",name:"按照展示量进行排序 /  Sort by View"},
    ];

    return (
        <div  className="container">

            <div className="row g-3 mb-3">
                <DateSelectorComponent callback_onChange={(s, e) => {
                    setStartTime(s);
                    setEndTime(e);
                }}
                                       outline_className={"col-6 "}
                                       ref={dateSelector}
                />
                <div className="col-6">
                    <SelectComponent items={sm} label={"排列方式 / Sort Order"} defaultValue={"create_time"}
                                     callback_onChange={(value)=>{
                                         setOrder(value)
                                     }}
                                     ref={sortSelector}
                    />
                </div>
            </div>
            <div className="row mb-3 h-auto">
                <div className="col-6 h-auto text-start">
                    <div className="form-floating">
                        <input type="text" className="form-control" id="floatingInputValue" onChange={(e)=>{
                            let keyword = e.target.value;
                            setKeyword(keyword.length > 0 ? keyword : null)
                        }} ref={(ref)=>{keywordInput = ref}}/>
                        <label htmlFor="floatingInputValue" >关键词 / Keyword</label>
                    </div>
                </div>
                <div className="col-6 h-auto text-end">
                    <button className="btn btn-primary"
                            disabled={ keyword === null}
                            onClick={() => {
                                callback_search({
                                    sort_by:sortOrder,
                                    start_time: startTime,
                                    end_time: endTime,
                                    keyword:keyword
                                })
                            }}
                    >
                        <i className="bi bi-search" style={{marginRight:"3px"}}/>
                        Search
                    </button>
                    <button className="btn btn-light" style={{marginLeft:"8px"}}
                            onClick={(e)=>{

                                if (keyword === null && startTime === null && endTime === null && sortOrder === null){
                                    return
                                }
                                setEndTime(null);
                                setStartTime(null);
                                setOrder(null);
                                setKeyword(null);
                                keywordInput.value = "";
                                dateSelector.current.resetData();
                                sortSelector.current.resetData();
                                callback_reset();
                            }}
                    >
                        Reset
                    </button>
                </div>

            </div>
        </div>
    )
}

function RowComponent({data,callback_edit,callback_delete}) {


    const stateText = (s)=>{

        switch (s) {
            case 1:
                return <span className="fs-6 text-bg-success badge">正常</span>;
            case 2:
                return <span className="fs-6 text-bg-danger badge">受限制</span>;
            case 3:
                return <span className="fs-6 text-bg-primary badge">上传中</span>;
            case 4:
                return <span className="fs-6 text-bg-warning badge">转码中</span>;
            default:
                return <span className="fs-6 text-bg-dark badge">未知</span>;

        }
    };
    return (
        <tr>
            <th>{data.id}</th>
            <th>{data.title}</th>
            <th>{stateText(data.state===undefined ? data.status : data.state)}</th>
            <th>{data.click === undefined ? data.viewer : data.click }</th>
            <th>
                <button className="btn btn-primary" onClick={()=>{
                    callback_edit(data.id)
                }}>Edit</button>
                <button className="btn btn-danger"  onClick={()=>{
                    if (window.confirm("Please confirm whether to delete the video")){
                        callback_delete(data.id)
                    }
                }} style={{marginLeft:"5px"}}>Delete</button>
            </th>
        </tr>
    )
}



export const VideoListPage = withRouter(VideoListComponent);