import * as React from 'react'
import { AuthProviderBasie } from './Provider'
import {api} from "../api/base";
import {object} from "prop-types";

const localKey = "online-user";
// 验证上下文空间
let AuthContext = React.createContext(null);
// 利用useContext导出验证上下文，供其它组件使用
export function useAuth() {
    return React.useContext(AuthContext)
}

export class User{
    user_name:string;
    user_id:string;
    user_icon: string;
    user_background: string;
    user_poster: string;
    user_level: number;
    user_language: string;
    user_experience:number;
    access_token:string;
}

// 验证提供者
export default function AuthProvider({ children }) {


    // 创建验证组件状态
    let [user,setUser]= React.useState(null);
    let [isLoggedIn,setIsLoggedIn] = React.useState(false);
    let [loggedStatus,setLoggedStatus] = React.useState(0);
    let [statusHoldTime,setStatusHoldTime] = React.useState(0);
    let [pageToken,setPageToken] = React.useState(null);


    let getLocalData = ()=>{
        let userData = localStorage.getItem(localKey);
        let onlineUser = null;
        if (userData !== null && userData !== undefined && userData.length>0 ){
            onlineUser = {};
            try {
                JSON.parse(userData, (key, value) => {
                    onlineUser[key] = value
                });
            }catch(e){
                localStorage.removeItem(localKey);
                userData = null;
                onlineUser = null;
            }
            if (onlineUser.access_token === undefined || onlineUser.access_token === null){
                return null
            }else{


                return verifyDataByNetwork(onlineUser.access_token,()=>{
                    setIsLoggedIn(true);
                    setUser(onlineUser);
                },()=>{
                    setLoggedStatus(0);
                    setIsLoggedIn(false);
                    setUser(null)
                });
            }
        }
    };

    function verifyDataByNetwork(at,afterSuccess=()=>{},afterFailed=()=>{}) {

        api.VerifyAccessToken({
            access_token:at,
            page_token:"",
        },(response)=>{
            if (response.result){
                setLoggedStatus(response.expire > 3600 ? 2 : 3);
                setPageToken(response.page_token);
                return afterSuccess();
            }else{
                return afterFailed()
            }
        },(msg)=>{
            console.log(msg["error_message"]);
            afterFailed()
        });
    }

    React.useEffect(()=>{
        function init() {
            getLocalData();
        }
        init()
    },[]);

    // 登录验证
    let signUp = (newUser, callback) => {
        //save as local
        localStorage.setItem(localKey,JSON.stringify(newUser));
        //return
        return AuthProviderBasie.signUp(() => {
            setUser(newUser);
            setIsLoggedIn(true);
            if (typeof(callback)!=="function"){
                return
            }
            callback()
        })
    };
    // 退出登录
    let signOut = (callback) => {
        //remove local key
        localStorage.removeItem(localKey);
        //return
        return AuthProviderBasie.signOut(() => {
            setUser(null);
            setIsLoggedIn(false);
            callback()
        })
    };

    let modifyPageToken = (newPageToken:String)=>{
        return setPageToken(newPageToken);
    };

    let value = { user, signUp, signOut ,isLoggedIn,pageToken,modifyPageToken};

    // 传递验证上下文(AuthContext)属性给嵌套的插槽children子组件(App)
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

