import {withRouter} from "../../../CommonComponents/WithRouter";
import * as React from "react";
import {DateSelectorComponent, PaginationComponent, SelectComponent} from "../CommonComponents/Form";
import {studioAPI} from "../../../../api/base";
import {WaitingComponent} from "../../../CommonComponents/Waiting";
import {NoRowComponent} from "../CommonComponents/View";



class ProgramListComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            startTime:null,
            endTime:null,
            keyword:null,
            sort_by:null,
            pageToken:this.props.router.pageToken.to(),
            pageAmount:0,
            pageLimit:20,
            currentPage:1,
            data:null,
            isInitState:true,
        }
    }
    componentDidMount(): void {
        if (this.state.isInitState === true){
            this.getTotal();
        }
    }
    getTotal(param={
        start_time: this.state.startTime,
        end_time: this.state.endTime,
        keyword:this.state.keyword,
        sort_by:this.state.sort_by
    }){

        let a = new studioAPI();
        a.getProgramListTotal(
            {...param},
            (response)=>{
                this.setState({pageAmount:response.page_amount,isInitState:false});
                this.getData(1,param)
            },
            (msg)=>{
                console.log(msg["error_message"]);
                this.setState({isInitState:false})
            }
        );
    }
    getData(page,param={
        start_time: this.state.startTime,
        end_time: this.state.endTime,
        keyword:this.state.keyword,
        sort_by:this.state.sort_by}){

        if (param.keyword !== null) {
            return this.search(param,page)
        }

        let a = new studioAPI();
        a.getProgramList(
            {
                page:page,
                ...param
            },
            (response)=>{
                this.setState({
                    data:response,
                    currentPage:page,
                })
            },
            (msg)=>{console.log(msg["error_message"])}
        )
    }
    search(params,page= 1){

        if (params.keyword === null || params.keyword === undefined){
            return
        }
        let a = new studioAPI();
        a.searchProgram({
            start_time:params.startTime,
            end_time:params.endTime,
            keyword:params.keyword,
            sort_by:params.sort_by,
            page:page
        },(response)=>{

            if (response == null){
                this.setState({
                    data:null,
                    currentPage:1
                });
                return
            }
            this.setState({data:response.items,pageAmount:response.total_page,currentPage:response.current_page})
        },(message)=>{})

    }
    create(){

        this.props.router.navigate("/studio/program/create",{replace:true})
    }
    delete(id){
        let a = new studioAPI();
        a.deleteProgram(
            {
                id:id,
                password:"",
                page_token:this.state.pageToken
            },
            (response)=>{
                let newList = [];
                this.state.data.forEach(item=>{
                    if (item.id !== id){
                        newList.push(item)
                    }
                });
                this.setState({
                   data:newList
                })
            },
            (msg)=>{
                console.log(msg["error_message"])}
            )
    }
    render(){
        if (this.state.isInitState===true ){
            return (
                <div className="studioWrap">
                    <div className="console">
                        <WaitingComponent/>
                    </div>
                </div>
            )
        }
        return (
            <div className="studioWrap" >
                <div className="console">
                    <div className="console_inside">
                        <div >
                            <div >
                                <div className="col-9 d-inline-block">
                                    <h4>Program List</h4>
                                </div>
                                <div className="col-3 d-inline-block justify-content-center text-center " >
                                    <button className="btn btn-outline-danger" style={{lineHeight:"25px"}} onClick={()=>this.create()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                                        </svg>
                                        &nbsp;Create Program</button>
                                </div>
                            </div>
                            <SearchParameterComponent callback_search={(param)=>{
                                this.setState({
                                    startTime:param.startTime,
                                    endTime:param.endTime,
                                    keyword:param.keyword,
                                    sort_by:param.sort_by,
                                    currentPage:1,
                                });
                                this.search(param)
                            }}  callback_reset={()=>{

                                this.setState({
                                    startTime:null,
                                    endTime:null,
                                    keyword:null,
                                    sort_by:null,
                                    currentPage:1,
                                });
                                this.getTotal({
                                    sort_by:"create_time",
                                    keyword:null,
                                })
                            }}/>
                            <table className="table">
                                <thead >
                                    <tr>
                                        <th>Program ID</th>
                                        <th>Program Title</th>
                                        <th>Program Status</th>
                                        <th>Subscribers Amount</th>
                                        <th>Operation</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.data === null ? <NoRowComponent /> :this.state.data.map(item=>{
                                    return (
                                        <RowComponent   data={item}
                                                        callback_Delete={(id)=>this.delete(id)}
                                                        callback_Edit={(id)=>{
                                                            this.props.router.navigate(`/studio/program/${id}`,{replace:true})
                                                        }}
                                        />
                                    )
                                })}

                                </tbody>
                            </table>
                        </div>
                        <div className="mb-3">
                            <PaginationComponent
                                    currentPage={this.state.currentPage}
                                    maxPage={this.state.pageAmount}
                                    callback_switch={(page)=>{
                                       this.getData(page)
                                    }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function RowComponent({data,callback_Delete,callback_Edit}) {



    let renderState = (s)=>{

        switch (s) {
            case 1:
                return <span className="badge text-bg-success fs-6">正常</span>;
            case 2:
                return <span className="badge text-bg-danger fs-6">受限制</span>;
            default:
                return <span className="badge text-bg-danger fs-6">未知</span>

        }
    };

    return (

            <tr>
                <th>{data.id}</th>
                <th>{data.title}</th>
                <th>{renderState(data.state)}</th>
                <th>{`${data.subscriber} Subscribers`}</th>
                <th>
                    <button className="btn btn-primary"
                            onClick={()=>{
                                callback_Edit(data.id)
                            }}
                    >Edit</button>
                    <button className="btn btn-danger"
                            style={{marginLeft:"5px"}}
                            onClick={()=>{
                                if (window.confirm("Please confirm whether to delete the program")){
                                    callback_Delete(data.id)
                                }
                            }}
                    >Delete</button>
                </th>
            </tr>

    )
}

function SearchParameterComponent({callback_search,callback_reset=()=>{}}) {


    let [startTime,setStartTime] = React.useState(null);
    let [endTime,setEndTime] = React.useState(null);
    let [sortMethod,setSortMethod] = React.useState(null);
    let [keyword,setKeyword] = React.useState(null);

    let keywordInput = React.createRef();
    let dateSelector = React.createRef();
    let sortSelector = React.createRef();



    let sm = [
        {id:"create_time",name:"按照创建时间进行排列 / Sort by creation time"},
        {id:"view",name:"按照展示量进行排序 /  Sort by View"},
        {id:"subscriber",name:"按照订阅人数进行排序 / Sort by number of subscribers created"}
        ];

    return (
        <div className="container">
            <div className="row mb-3">
                <DateSelectorComponent callback_onChange={(s, e) => {
                    setStartTime(s);
                    setEndTime(e);
                }}
                                       outline_className={"col-6"}
                                       callback_onError={(msg) => {
                                           console.log(msg)
                                       }}
                                       ref={dateSelector}
                />
                <div className="col-6 ">
                    <SelectComponent items={sm} label={"排列方式 / Sort Order"} defaultValue={"create_time"} callback_onChange={(value) => {
                        if (value === null || value === "null"){
                            return
                        }
                        setSortMethod(value)
                    }} ref={sortSelector} />
                </div>
            </div>
            <div className="row mb-3">
                <div className={"col-6 text-start"} style={{margin:"0"}}>
                    <div className="form-floating">

                        <input className="form-control" type="text" id="floatingInput" onChange={(e) => {
                            setKeyword(e.target.value);
                        }} ref={keywordInput}/>
                        <label className="form-label" htmlFor={"floatingInput"}>关键词 / Keywords</label>
                    </div>

                </div>
                <div className="col-6 text-end" style={{margin:"0"}}>
                    <button className="btn btn-primary"
                            disabled={keyword === null}
                            onClick={() => {
                                callback_search({
                                    startTime:startTime,
                                    endTime:endTime,
                                    sort_by:sortMethod,
                                    keyword:keyword,
                                })
                            }}
                    >
                        <i className="bi bi-search" style={{marginRight:"3px"}}/>
                        Search
                    </button>
                    <button className="btn btn-light" style={{marginLeft:"8px"}}
                        onClick={()=>{
                            if (keyword === null && sortMethod === null && startTime === null && endTime === null){
                                return
                            }
                            setKeyword(null);
                            setStartTime(null);
                            setEndTime(null);
                            setSortMethod(null);
                            keywordInput.current.value = "";
                            dateSelector.current.resetData();
                            sortSelector.current.resetData();
                            callback_reset();
                        }}
                    >
                        Reset
                    </button>
                </div>
            </div>
        </div>
    )
}

export const ProgramListPage = withRouter(ProgramListComponent);


