import * as React from "react";
import {studioAPI} from "../../../../../api/base";
import {
    AddTagsComponent, AddTagsComponent02,
    InputFieldComponent, JNumberComponent,
    SelectComponent,
    TextAreaComponent,
    UploadPosterComponent
} from "../../CommonComponents/Form";


export class ProgramInformationComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            program:this.props.program,
            pageToken:this.props.router.pageToken.to(),
            title:this.props.program.title,
            description:this.props.program.description,
            page_token:this.props.program.pageToken,
            subtitle:this.props.program.show_subtitle,
            is_subscribe_topic:this.props.program.is_subscribe_topic,
            price:this.props.program.price,
            tags:this.transformTags(this.props.program),
            category_id:this.props.program.category === undefined || this.props.program.category === null ? null : this.props.program.category.id,
            categoryList:[],
        }
    }
    componentDidMount(): void {

        let a= new studioAPI();
        a.getCategory({},(response)=>{
            this.setState({
                categoryList:response,
            })
        },(message)=>{
            console.log(message.error_message)
        })
    }

    transformTags(program){
        if(program === undefined || program===null){return []}
        let tags = [];
        if (program.tags !== null){
            program.tags.forEach((it,index)=>{
                tags.push(it.name)
            });
        }
        return tags
    }


    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            program:nextProps.program
        })
    }
    Modify(){

        let a = new studioAPI();
        a.modifyProgram({
                id:this.state.program.id,
                title:this.state.title,
                description:this.state.description,
                page_token:this.state.pageToken,
                subtitle:this.state.subtitle,
                is_subscribe_topic:this.state.is_subscribe_topic,
                is_private_program:this.state.is_private_program,
                category_id:this.state.category_id,
                price:this.state.price<=0 ? 0 : this.state.price
            },
            (response)=>{
                this.props.callback_refresh();
            },
            (msg)=>{
                window.alert(msg["error_message"])
            },
        )
    }

    render(){

        if (this.state.program === null){
            return <div />
        }
        return (
            <div className="console_content" >

                <InputFieldComponent
                    showPrompt={false }
                    callback_onChange={(value)=>{
                        this.setState({
                            title:value
                        })
                    }}
                    label={"节目名字 / Program Name"}
                    disable={false}
                    promptText={"Please Input Program Name"}
                    placeholder={this.state.title}
                />
                <InputFieldComponent
                    showPrompt={false}
                    callback_onChange={(value)=>{
                        this.setState({
                            subtitle:value
                        })
                    }}
                    label={"节目简述 / Program Subtitle"}
                    disable={false}
                    promptText={"Please Input Program Subtitle"}
                    placeholder={this.state.subtitle}
                />
                <SelectComponent items={this.state.categoryList} callback_onChange={(category_id)=>{
                    this.setState({
                        category_id:category_id
                    })
                }} label={"请选择节目分类 / Program Category"} defaultValue={this.state.category_id}/>
                <TextAreaComponent
                    showPrompt={false}
                    callback_onChange={
                        (value)=>{
                            this.setState({
                                description:value
                            })
                        }
                    }
                    placeholder={this.state.program.description}
                    label={"节目简介 / Program Description"}
                    disable={false}
                    promptText={"Please Input Prompt Description"}
                />
                <AddTagsComponent02 defaultValue={this.state.tags}
                            callback_addTag={(tag)=>{
                                let tags = [];
                                if (typeof(tag) === "string"){
                                    tags.push({name:tag,is_add:true})
                                }else if(Array.isArray(tag)){
                                    tag.map(item=>{
                                        tags.push({name:item,is_add:true})
                                    })
                                }else{
                                    console.log(typeof(tag));
                                    return null
                                }

                                let a = new studioAPI();
                                a.addTagOnProgram(
                                    {
                                        id:this.state.program.id,
                                        tags:tags,
                                    },
                                    (resp)=>{
                                        let p = this.state.program;
                                        let tags = p.tags === undefined || p.tags === null ? [] :p.tags;
                                        tags.push(tag);
                                        p.tags = tags;
                                        this.setState({
                                            program:p
                                        })
                                    },
                                    (msg)=>{window.alert(msg)}
                                    );
                            }}
                                  callback_removeTag={(tag)=>{

                                      let tags = [];
                                      if (typeof(tag) === "string"){
                                          tags.push({name:tag,is_add:false})
                                      }else if(Array.isArray(tag)){
                                          tag.map(item=>{
                                              tags.push({name:item,is_add:false})
                                          })
                                      }else{
                                          console.log(typeof(tag));
                                          return null
                                      }

                                      let a = new studioAPI();
                                      a.addTagOnProgram(
                                          {
                                              id:this.state.program.id,
                                              tags:tags,
                                              page_token:this.state.pageToken
                                          },
                                          (resp)=>{
                                              let p = this.state.program;
                                              let tags = p.tags === undefined ? [] :p.tags;
                                              tags = tags === null ? [] : tags;
                                              tags.splice(tag);
                                              p.tags = tags;
                                              this.setState({
                                                  program:p
                                              })
                                          },
                                          (msg)=>{window.alert(msg)}
                                      );
                                  }}
                />
                <div >
                    <JNumberComponent label={"是否为收费节目?"} callback_onChange={(is,value)=>{
                        this.setState({
                            price:value
                        })
                    }}/>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary"
                            onClick={()=>this.Modify()}
                    >Modify</button>
                </div>
            </div>
        )
    }
    /*
    * <SelectComponent
                    items={[{id:1,name:"Yes"},{id:2,name:"No"}]}
                    callback_onChange={
                        (val)=>{
                            this.setState({
                                is_private_program:val===1
                            })
                        }
                    }
                    label={"Is it a private program"}/>
    *
    * */
}
