import React from "react";
import {isCCV, isCreditCard, isValidCashCardPassword, isValidGiftCard} from "./LittleFunc";
import {api} from "../../api/base";
import {Link} from "react-router-dom";


export const BusinessModal = ({openModal=false,
                                bill={},
                                  callback_close=()=>{},
                                  callback_onSubmit=()=>{},
                                  callback_finish=()=>{}})=>{


    let [addCreditCard,setAddCreditCard] = React.useState(false);
    let [addGitCard,setAddGiftCard] = React.useState(false);
    let [hasPayment,setHasPayment] = React.useState(true);
    let [isOpening,setOpening] = React.useState(openModal);

    let [paymentMethod,setPaymentMethod] = React.useState(null);
    let [paymentInformation,setPaymentInformation] = React.useState(null);

    let [componentStatus,setComponentStatus] = React.useState(-1);


    let selectPayment = (pm)=>{
        if (pm === null || pm === undefined){return };

        setAddCreditCard(pm === "credit_card");
        setAddGiftCard(pm === "cash_card");
        setPaymentMethod(pm);
        if (pm !== "credit_card" && pm !== "cash_card"){
            setPaymentInformation({
                "account":pm,
                "card_number":"",
            });
        }
    };

    React.useEffect(()=>{
        setOpening(openModal)
    },[openModal]);



    let submit  = ()=>{
        let res = callback_onSubmit({
            payment_information:paymentInformation,
            payment_type:paymentMethod
        });
        //set status
        setComponentStatus(res === true ? 1 : 2);
        //
        window.setTimeout(()=>{
            close();
            callback_finish(res);
        },1000*5);
    };

    let close = ()=>{
        setOpening(!isOpening);
        callback_close()
    };

    if (componentStatus >= 0){

        switch (componentStatus) {
            case 0:
                return (
                    <LoadingComponent title={"正在订购商家级计划"} isShow={true} />
                );
            case 1:
                return (<StatusAlertComponent callback_close={()=>{
                    close()
                }} isShow={isOpening} title={"成功订购"} label={"成功订购商家级计划"} statusCode={1}/>);
            case 2:
                return (
                    <StatusAlertComponent callback_close={()=>{close()}} isShow={isOpening} title={"订购失败"} label={"订购商家计划失败"} statusCode={2}/>
                );
            default:
                return <div />
        }
    }

    return (
        <div className="modal fade show bg-dark bg-opacity-75" style={{display:isOpening===true ?"block":"none"}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header ">
                        <h1 className="modal-title fs-5">订购 {bill.name}</h1>
                        <button className="btn-close" onClick={()=>close()} />
                    </div>
                    <div className="modal-body">
                        <div className="p-2">
                            <div className="card">
                                <div className="card-body">
                                    <p className="m-0"><span className="fw-bold" style={{fontSize:"17px"}}>订购 {bill.name}</span>
                                    </p>
                                    <p className="m-0"><span style={{fontSize:"14px"}}>按月计费 · 每月</span><span
                                        className="fw-bold"> {`${bill.currency}$${bill.price}`}</span></p>
                                    <p className="m-0"><span style={{fontSize:"14px"}}>开始计费时间:</span><span
                                        className="fw-bold">{bill.start_time}</span></p>
                                </div>
                            </div>
                        </div>
                        <CreditCardComponent isShow={false} callback_addCreditCard={()=>{
                            setAddCreditCard(true);
                            setHasPayment(!hasPayment)
                        }}/>
                        <AddCreditCardComponent isShow={false} callback_getCreditCard={(c)=>{
                            setPaymentInformation(c)
                        }} />

                        <AddPaymentComponent isShow={true} callback_selectedPayment={(id)=>{selectPayment(id)}}/>
                        <AddCashCardComponent isShow={addGitCard} callback_getCashCard={(cash_card)=>{setPaymentInformation(cash_card)}}
                                              tradingCurrency={bill.currency}
                                              tradingAmount={bill.price}/>
                        <div className="p-2">
                            <p>继续操作即表示，您同意遵守 <Link to="/legal/terms_of_service">Yimi.TV Payments 服务条款</Link>。<Link to={"/legal/privacy_policy"}>隐私权声明</Link>说明了 Yimi.TV
                                会如何处理您的数据。</p>
                            <p>结算从免费试订期结束之日（{bill.start_time}）开始，每月会自动执行一次。如果您在结算周期未满时提出退订，恕不能向您退款。随时可在“设置”中取消订阅。</p>
                            <p>继续即表示，您确认自己年满 18 周岁并且同意<Link to={"/legal/terms_of_service"}>相应条款</Link> 。</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-danger" onClick={()=>submit()} disabled={!(paymentInformation !== null && paymentMethod!==null)}>立即订购</button>
                    </div>
                </div>
            </div>
        </div>
)
};


const AddCreditCardComponent = ({isShow=false,callback_getCreditCard=()=>{}})=>{

    let [account,setAccount] = React.useState(null);
    let [expireDate,setExpireDate] = React.useState(null);
    let [cardNumber,setCardNumber] = React.useState(null);
    let [ccv,setCCV] = React.useState(null);


    React.useEffect(()=>{

        function valid() {
            if(cardNumber === null || cardNumber === undefined){}else{
                cardNumber = cardNumber.replace(/\s*/g,"");
            }
            if (isCreditCard(cardNumber) && isCCV(ccv) && account!==null && expireDate !== null){

                return callback_getCreditCard({
                    card_number:cardNumber,
                    account:account,
                    ccv:ccv,
                    expire_date:expireDate,
                })
            }
            return null
        }
        valid()

    },[account,expireDate,cardNumber,ccv]);


    function onKeyUp(e) {
        e.target.value =e.target.value.replace(/\s/g,'').replace(/(\d{4})(?=\d)/g,"$1 ");
    }

    return (
        <div className={`p-2 ${isShow === true ? "" : "hide"}`}>
            <div className="pb-2 fw-bold">添加信用卡</div>
            <div className="container m-0 p-0">
                <div className="row g-2 row-cols-2 pb-2">
                    <div className="col-md-8 ">
                        <div className="form-floating">
                            <input type="text" className="form-control" onChange={(e)=>{setCardNumber(e.currentTarget.value)}}
                                    onKeyUp={(e)=>onKeyUp(e)}
                            />
                            <label>卡号</label>
                        </div>
                    </div>
                    <div className="col-md ">
                        <div className="form-floating">
                            <input type="month" className="form-control" placeholder="" onChange={(e)=>{setExpireDate(e.currentTarget.value)}}/>
                            <label>有效期</label>
                        </div>
                    </div>
                </div>
                <div className="row g-2 row-cols-2 pb-2">
                    <div className="col-md-8 ">
                        <div className="form-floating">
                            <input type="email" className="form-control" placeholder="" onChange={(e)=>{setAccount(e.currentTarget.value)}}/>
                            <label>持卡人</label>
                        </div>
                    </div>
                    <div className="col-md ">
                        <div className="form-floating">
                            <input type="text" className="form-control" placeholder="" onChange={(e)=>{setCCV(e.currentTarget.value)}}/>
                            <label>CCV</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
const AddCashCardComponent = ({isShow=false,callback_getCashCard=(cash_card)=>{},tradingAmount=0,tradingCurrency="USD"})=>{


    let [account,setAccount] = React.useState(null);
    let [password,setPassword] = React.useState(null);

    let [failureStatus,setFailureStatus] = React.useState(false);
    let [failureMessage,setFailureMessage]=React.useState(null);


    React.useEffect(()=>{
        if (account != null && password!=null){
            if (isValidGiftCard(account)&&isValidCashCardPassword(password)){

                api.ValidCashCard({
                    account:account,
                },(response)=>{
                    if (response.status !== 1){
                        setFailureStatus(true);
                        setFailureMessage("对不起,该现金卡已经失效!");
                        return null
                    }else{
                        if (response.currency.toLocaleLowerCase() !== tradingCurrency.toLocaleLowerCase()){
                            setFailureStatus(true);
                            setFailureMessage("对不起,该现金卡不支持该货币交易");
                            return
                        }
                        if (response.balance < tradingAmount){
                            setFailureStatus(true);
                            setFailureMessage(`对不起，该现金卡面值不足以支付本次订单,请换卡支付!(面值:${response.currency}$${response.balance})`);
                            return
                        }
                        let balance= response.balance - tradingAmount;
                        if (balance > 1){
                            setFailureStatus(true);
                            setFailureMessage(`现金卡面值为USD$${response.balance},扣去本次交易金额,剩余USD$${balance.toFixed(2)}将会存入你的余额。`);
                        }
                        callback_getCashCard({
                            account:account,
                            password:password,
                        })
                    }

                },(msg)=>{
                    console.log(msg["error_message"]);
                    setFailureStatus(true);
                    setFailureMessage(msg["error_message"])
                });
            }
        }
    },[account,password]);

    return (
        <div className={`p-2 ${isShow === true ? "" : "hide"}`}>
            <div className="pb-2 fw-bold">添加现金卡</div>
            <div className="container m-0 p-0">
                <div className="row g-2 row-cols-2 pb-2">
                    <div className="col-md-6 ">
                        <div className="input-group has-validation">
                            <div className="form-floating">
                                <input type="text" className={`form-control ${failureStatus===true ? "is-invalid" : ""}`} onChange={(e) => {
                                    setAccount(e.currentTarget.value)
                                }}
                                       maxLength={16}
                                />
                                <label>卡号</label>
                            </div>
                            <div className={`invalid-feedback ${failureStatus===true ? "d-block" : ""}`}>
                                {failureMessage}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className="form-floating">
                            <input type="text" className="form-control" placeholder="" onChange={(e)=>{setPassword(e.currentTarget.value)}}
                                    maxLength={16}
                            />
                            <label>卡密码</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

};


const AddPaymentComponent = ({isShow=false,callback_selectedPayment = ()=>{}})=>{


    let [data,setData] = React.useState([
        {id:"cash_card",name:"使用Yimi.TV 现金卡"},
    ]);
    let [selectedPayment,setSelectedPayment] = React.useState(null);

    React.useEffect(()=>{
        if (selectedPayment === null || selectedPayment === undefined){return }
        callback_selectedPayment(selectedPayment)
    },[selectedPayment]);


    const ItemComponent = ({data,callback_onSelected=()=>{},defaultChecked = false})=>{

        let [isChecked,setChecked]=React.useState(defaultChecked);
        React.useEffect(()=>{setChecked(defaultChecked)},[defaultChecked]);

        return (
            <div className="list-group-item ">
                <input className="form-check-input mt-2 me-2" type="checkbox" onChange={(e)=>{
                    if (e.currentTarget.checked){
                        callback_onSelected(data.id);
                        setChecked(!isChecked)
                    }}} checked={defaultChecked}/>
                <label className="form-check-label fs-5">{data.name}</label>
            </div>
        )
    };


    return (
        <div className={`p-2 ${isShow === true ? "" : "hide"}`}>
            <div className="pb-2 fw-bold">
                <span>添加付款方式</span>
            </div>
            <div className="list-group ">

                {data.map(it=>(
                    <ItemComponent data={it} defaultChecked={selectedPayment === it.id} callback_onSelected={(id)=>setSelectedPayment(id)} />
                ))}

            </div>
        </div>
    )
};

const CreditCardComponent = ({isShow=false,callback_addCreditCard=()=>{}})=>{


    let [payment,setPayment] = React.useState([
        {card_number:"4016 **** *** 4018",bank_name:"中国银行",card_img:"images/MASTERCARD.png"},
        {card_number:"7013 **** *** 4018",bank_name:"美国银行",card_img:"images/MASTERCARD.png"},
    ]);

    const ItemComponent = ({data})=>{

        return (
            <div className="list-group-item " >
                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                        <input className="form-check-input mt-2 me-2" type="checkbox" />
                        <div style={{height:"45px"}}>
                            <div className="w-100" style={{height:"25px",lineHeight:"25px",fontSize:"16px"}}>{data.card_number}</div>
                            <div className="w-100 lead" style={{height:"20px",lineHeight:"20px",fontSize:"14px"}}>{data.bank_name}</div>
                        </div>
                    </div>
                    <div className="pt-2 pb-2">
                        <img src={data.card_img} style={{height:"25px"}} alt={data.bank_name}/>
                    </div>
                </div>
            </div>
        )
    };


    return (

        <div className={`p-2 ${isShow===true ? "" : "hide"}`}>
            <div className="pb-2 fw-bold d-flex justify-content-between">
                <span>选择现有信用卡</span>
                <span><a href="#" className="text-decoration-none" onClick={()=>{callback_addCreditCard()}}><i className="bi bi-plus-lg me-1" />添加新信用卡</a></span>
            </div>
            <div className="list-group">
                {payment.map(it=>(
                    <ItemComponent data={it} />
                ))}
            </div>
        </div>
    )
};

function StatusAlertComponent({isShow=true,statusCode=1,label="成功操作",title="成功",callback_close=()=>{}}) {

    let [isOpen,setOpen] = React.useState(isShow);

    React.useEffect(()=>{
        setOpen(isShow);
    },[isShow]);


    let close= ()=>{
        setOpen(!isOpen);
        callback_close()
    };

    let renderStatus = (statusCode)=>{
        switch (statusCode) {
            case 1:
                return "alert-success";
            case 2:
                return "alert-danger";
            case 3:
                return "alert-warning";
            default:
                return "alert-success"

        }
    };

    return (
        <div className="modal fade show bg-dark bg-opacity-75 " style={{display:isOpen===true ? "block" : "none"}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{title}</div>
                        <button className="btn-close" onClick={()=>close()}/>
                    </div>
                    <div className="modal-body">
                        <div className={`alert ${renderStatus(statusCode)}`}>
                            <i className="bi bi-check-circle-fill me-2" />{label}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-outline-success" onClick={()=>close()}>关闭</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

function LoadingComponent({isShow,title}) {

    let [isOpen,setOpen] = React.useState(isShow);


    React.useEffect(()=>{
        setOpen(isShow)
    },[isShow]);

    return (
        <div className="modal fade show bg-dark bg-opacity-75 " style={{display:isOpen===true ? "block" : "none"}}>

            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{title}</div>
                        <button className="btn-close" />
                    </div>
                    <div className="modal-body">
                        <div className="d-flex justify-content-center" style={{height:"30px",lineHeight:"30px"}}>
                            <div className="spinner-border text-success me-2" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <span className="text-success">正在处理中...</span>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-outline-dark" disabled>正在处理</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const MessageModal = ({title,isShow=false,message=null,callback_close=()=>{}})=>{

    let [isOpen,setOpen] = React.useState(isShow);

    React.useEffect(()=>{
        setOpen(isShow)
    },[isShow]);

    let close = ()=>{
        setOpen(!isOpen);
        callback_close()
    };

    return (
        <div className="modal fade show bg-dark bg-opacity-75 " style={{display:isOpen===true ? "block" : "none"}}>

            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="modal-title">{title}</div>
                        <button className="btn-close" onClick={()=>close()}/>
                    </div>
                    <div className="modal-body">
                       <div dangerouslySetInnerHTML={{__html:message}} />
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-outline-dark" onClick={()=>close()}>关闭</button>
                    </div>
                </div>
            </div>
        </div>
    )

};