import React from "react";
import {
    AddTagsComponent02,
    InputFieldComponent,
    TextAreaComponent
} from "../../CommonComponents/Form";
import {studioAPI} from "../../../../../api/base";


export function VideoInformationComponent({data,callback_onSubmit}) {


    let transformTags = ()=>{
        let tags = data.tags===null ? [] : data.tags;
        let d=[];
        tags.forEach(it=>{
            d.push(it.name)
        });
        return d
    };

    let [title,setTitle] = React.useState(data.title);
    let [description,setDescription] = React.useState(data.description);
    let [tags,setTags] = React.useState(transformTags());
    //let [isPrivate,setPrivate] = React.useState(false);
    let pageToken = "";

    let addTag = (t)=>{
        let a = new studioAPI();
        a.addTagOnVideo(
            {
                id:data.id,
                tags:[{name:t,is_add:true}],
            },
            (resp)=>{
                //update state
                tags.push(t);
                setTags(tags);
            },
            (msg)=>{window.alert(msg)}
        );
    };

    let removeTag = (t)=>{
        let a = new studioAPI();
        a.addTagOnVideo(
            {
                id:data.id,
                tags:[{name:t,is_add: false}],
            },
            (resp)=>{
                tags.splice(t);
                setTags(tags);
                },
            (msg)=>{window.alert(msg)}
            );

    };

    let modifyInformation = ()=>{

        let params = {
            title:title,
            description:description,
            id:data.id,
        };
        return callback_onSubmit(params);
    };

    return (<div className="console_content" >
        <InputFieldComponent
            showPrompt={false}
            callback_onChange={(value)=>{
                setTitle(value)
            }}
            label={"视频标题"}
            disable={false}
            promptText={"Please Input Video Name"}
            placeholder={title}
        />
        <TextAreaComponent
            showPrompt={false}
            callback_onChange={
                (value)=>{setDescription(value)}
            }
            placeholder={description}
            label={"视频简介"}
            disable={false}
            promptText={"Please Input Video Description"}
        />
        <AddTagsComponent02
            disable={true}
            defaultValue={tags}
            callback_addTag={(tag)=>addTag(tag)}
            callback_removeTag={(tag)=>removeTag(tag)}
        />
        <PushProgramComponent program={data.program} videoId={data.id} pageToken={pageToken}/>
        <div className="mb-3">
            <button className="btn btn-primary"
                    onClick={()=>{
                        modifyInformation();
                    }}
            >Update</button>
        </div>
    </div>)
}
function PushProgramComponent({pageToken,videoId,program=""}) {

    let [ok,setOK] = React.useState(false);
    let [programList,setProgramList] = React.useState([]);
    let [defaultProgram,setDefaultProgram] = React.useState(program!==null ? program.title : null);



    let getProgramList = ()=>{
        let a = new studioAPI();
        a.getProgramList({page:1},
            (response)=>{
                if(response === null){
                    return setProgramList([]);
                }
                setProgramList(response)
            },
            (msg)=>{window.alert(msg["error_message"])}
            )
    };
    let pushProgram = (e)=>{

        let index = e.target.selectedIndex;
        let title = e.target.options[index].text;
        let id = e.target.value;
        let t =window.confirm("是否将该视频添加到《"+title+"》节目当中");
        if (t===true){


            let a = new studioAPI();
            a.addEpisodesToProgram({
                    id:id,
                    episodes:[
                        {id:videoId,sort_number:1,stage:""}],
                    page_token:pageToken},
                (response)=>{
                        window.alert("添加成功");
                        setDefaultProgram(title)
                },
                (msg)=>{window.alert(msg["error_message"])}
            )

        }
    };

    React.useEffect(()=>{
        getProgramList();
    },[ok===true]);



    if (defaultProgram !== null ){

        return (
            <div className="mb-3">
                <label className="form-label">节目名字</label>
                <input className="form-control form-control-lg" type="text" disabled={true} value={defaultProgram} />
            </div>
        )
    }

    return (
        <div className="input-group mb-3" style={{width:"200px"}}>
            <div className="form-check form-switch " style={{background:"#fff",border:"none"}}>
                <input className="form-check-input " type="checkbox" style={{marginRight:"5px",marginTop:"3px"}}
                       onChange={(event)=>{
                           setOK(event.target.checked)
                       }}
                />
                <label className={"form-check-label"} >是否加入节目</label>
            </div>

            {ok === true ?
            <div className="input-group mt-3">
                <select className="form-select" onChange={(e)=>{pushProgram(e)}}>
                    <option>请选择节目</option>
                    {programList.map(item=>(
                        <option value={item.id} name={item.title}>{item.title}</option>
                    ))}
                </select>
            </div>: <div />}
        </div>
    )
}