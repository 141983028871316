import * as React from "react";
import {withRouter} from "../../../CommonComponents/WithRouter";
import {DateSelectorComponent, PaginationComponent} from "../CommonComponents/Form";
import {studioAPI} from "../../../../api/base";


class WalletComponent extends React.Component{


    render(){
        return (
            <div className="studioWrap">
                <div className="console">
                    <div className="console_inside">
                        <TransactionFlowComponent/>
                    </div>
                </div>
            </div>
        )
    }
}

export const WalletPage = withRouter(WalletComponent);


function TransactionFlowComponent({pageToken}) {

    let [flow,setFlow] = React.useState([]);
    let [pageAmount,setPageAmount] = React.useState(10);
    let [currentPage,setCurrentPage] = React.useState(1);
    let [startTime,setStartTime] = React.useState();
    let [endTime,setEndTime] = React.useState();


    let getTotal    = ()=>{

        let a = new studioAPI();
        a.getTransactionFlowTotal(
            {
                "page_token":pageToken,
                "start_time":startTime,
                "end_time":endTime,
            },
            (response)=>{
                setPageAmount(response.page_amount)
            },
            (msg)=>{window.alert(msg)}
        )
    };
    let getData     = ()=>{
        let a = new studioAPI();
        a.getTransactionFlow(
            {
                "page":currentPage,
                "page_token":pageToken,
                "start_time":startTime,
                "end_time":endTime,
            },
            (response)=>{
                setFlow(response)
            },
            (msg)=>{window.alert(msg)}
        )
    };

    React.useEffect(()=>{
        getTotal();
    });
    React.useEffect(()=>{
        getData();
    },[currentPage,pageAmount]);

    return (
        <div>
            <div >
                <h2>Transaction Flow</h2>
            </div>
            <div className="mb-3">
                <DateSelectorComponent callback_onChange={(s,e)=>{
                        setStartTime(s);
                        setEndTime(e);
                }}/>
                <div className="mt-2 col-sm-7">
                    <button className="btn btn-dark"
                            onClick={()=>{
                                getTotal();
                            }}
                    >Query</button>
                </div>
            </div>
            <table className="table table-hover table-bordered table-dark">
                <thead>
                <tr>
                    <th>Trade ID</th>
                    <th>Trade Date</th>
                    <th>Transfer Party</th>
                    <th>Transferee</th>
                    <th>Transaction Details</th>
                    <th>Amount</th>
                    <th>Balance</th>
                </tr>
                </thead>
                <tbody>
                {flow.map(it=>(
                    <TransactionItemComponent data={it}/>
                ))}
                </tbody>
            </table>
            <div>
                <PaginationComponent
                    maxPage={pageAmount}
                    currentPage={1}
                    callback_switch={(page)=>{
                        setCurrentPage(page)
                    }}
                />
            </div>
        </div>
    )
}




function TransactionItemComponent({data}) {


    return (
        <tr className="table-danger">
            <th>{data.id}</th>
            <th>{data.trading_time}</th>
            <th>Simonks</th>
            <th>Jim</th>
            <th>{data.remark}</th>
            <th>{data.amount}</th>
            <th>{data.balance}</th>
        </tr>
    )
}