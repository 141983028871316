import {useLocation, useNavigate, useParams} from "react-router";
import {useAuth} from "../../Auth/Route";
import React from "react";
import {api} from "../../api/base";


export function SubscribeProgramPage() {


    let param  = useParams();
    let auth = useAuth();
    let nav =useNavigate();
    let location = useLocation();

    let program_id = param.program_id;
    let pathname = location.state === undefined || location.state === null ? "/" : location.state.pathname === undefined || location.state.pathname === null ? "" : location.state.pathname;

    console.log(pathname);

    let [program,setProgram] = React.useState({});


    React.useEffect(()=>{
        function Init() {
            api.GetProgramDetail({
                id:program_id,
                page_token:"",
            },(response)=>{setProgram(response)},(msg)=>{console.log(msg)})
        }
        Init()
    },[]);

    let subscribe= ()=>{
        api.SubscribeProgram({
            program_id:program_id,
            page_token:""
        },(response)=>{
            window.alert("订阅成功!");
            nav(pathname,{replace:true})
        },(msg)=>{
            window.alert(msg["error_message"])
        })
    };

    let backTo = ()=>{
        nav(pathname,{replace: true})
    };


    return (
        <div className="container w-100 h-100 m-0 p-0 align-content-center" style={{height:"400px"}}>

            <div className="modal d-flex justify-content-center align-middle" style={{width:"1320px"}}>
                <div className="modal-dialog ">
                    <div className="modal-content ">
                        <div className="modal-header">
                            <h5 className="modal-title">订阅节目 / Subscribe Program</h5>
                        </div>
                        <div className="modal-body">
                            <p className="m-0 p-0 fs-4 fw-bold">{`您是否确认订阅《${program.title}》？`}</p>
                            <p className="lead m-0 p-0" style={{fontSize:"15px"}}>{`Do you confirm your subscription to《${program.title}》`}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={()=>subscribe()}>订阅 / Subscribe Now</button>
                            <button type="button" className="btn btn-light" onClick={()=>backTo()}>返回 / Back To</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}