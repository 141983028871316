import React from 'react';
import {api, studioAPI} from "../../api/base";
import {withRouter} from "../CommonComponents/WithRouter";
import {Second} from "./Login";
import type {User} from "../../Auth/Route";
import Helmet from "react-helmet";
import {website_name} from "../CommonComponents/WebsiteTitle";

class RegisterPageCore extends React.Component{

    constructor(props){
        super(props);
        this.state={
            nickName:null,
            countryCode:"+86",
            password:null,
            mobilePhone:null,
            account:null,
            code:null,
            countryList:null,

            fromPath:this.props.router.location===undefined ? "/" :this.props.router.location.state.pathname,
            fromState:this.props.router.location===undefined ? {} :this.props.router.location.state,
            pageToken:this.props.router.pageToken,
            isSent:false
        }
    }
    componentDidMount(): void {
        this.getSupportCountry();
    }

    getSupportCountry(){
        let a = new studioAPI();
        a.getSupportCountry(
            {"page_token":this.state.pageToken},
            (content)=>{
                        this.setState({
                            countryList:content
                        })
            },()=>{});
    }
    register(){

        if ( IsWeakPasswords(this.state.password)===true ){
            window.alert("对不起，密码必须包含字母、数字,长度为8～20位\nNote:Sorry,the password must contain letters and numbers, with a length of 8 to 20 characters");
            return
        }

        if (IsAccount(this.state.account) === false){
            window.alert("对不起,账号必须是数字或者大小字母,长度必须为5～20位\nNote:Sorry, the account number must be numbers or letters, and the length must be 5 to 20 characters");
            return
        }
        if (IsNickName(this.state.nickName) === false){
            window.alert("对不起,昵称不支持特殊字符\nNote:Sorry,nickname does not support special characters");
            return
        }

        if (this.state.account === this.state.password) {
            window.alert("对不起,账号和密码不能一致!");
            return
        }

        let auth = this.props.router.auth;
        api.Register(
            {
                nick_name:this.state.nickName,
                password:this.state.password,
                /*country_code: this.state.countryCode,
                mobile_phone:this.state.mobilePhone,
                code:this.state.code,*/
                account:this.state.account,
                page_token:this.state.pageToken.to()
            },
            (content)=>{

                let newUser : User = {
                    user_name:content.user_name,
                    user_id:content.user_id,
                    user_background:content.user_background,
                    user_icon:content.user_icon,
                    user_experience:content.user_experience,
                    user_level:content.user_level,
                    user_language:content.user_language,
                    user_poster:content.user_poster,
                    access_token:content.access_token,
                };

                auth.signUp(
                    newUser,
                    ()=>{
                        let path = null;
                        if (this.state.fromPath === undefined || this.state.fromPath === null ||
                            this.state.fromPath === "/register"
                        ){
                            path = "/";
                        }else{
                            path = this.state.fromPath;
                        }
                        this.props.router.navigate(path,{replace:true,state:this.state.fromState})
                    }
                )
            },
            (msg)=>{
                window.alert(msg["error_message"])
            }
        )
    }
    returnBack(){

        let path = this.state.fromPath === undefined ? "/" : this.state.fromPath;
        let state = this.state.fromState === undefined ? {} : this.state.fromState;
        this.props.router.navigate(path,{replace: true,state:state});
    }
    sendCode(){

        if (this.state.mobilePhone === null || this.state.mobilePhone === undefined || this.state.mobilePhone.length <=0){
            window.alert("请输入手机号码!");
            return
        }

        if (this.state.isSent === false){
            let a = new studioAPI();
            a.smsVerification(
                {
                    "mobile_phone":this.state.mobilePhone,
                    "country_code":this.state.countryCode,
                    "page_token":this.state.pageToken.to(),
                },
                (content)=>{
                    //this.setTimer();
                    this.setState({
                        isSent:true,
                    })
                }
            )
        }
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {



        return (
            <div >
                <Helmet>
                    <title>Register New Account  - {website_name}</title>
                </Helmet>
            <div className="r_wrap">
                <div className="inside">
                    <div className="left">
                        <div className="img text-center justify-content-center" />
                    </div>
                    <div className="right">
                        <div className="decoration">Create Account</div>
                        <ul>
                            <li className="mb-3">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="Nick_Name"
                                           placeholder="Please Input The Nick Name" onChange={(e)=>{this.setState({nickName:e.target.value})}}/>
                                        <label htmlFor="Nick_Name">暱稱 / Nick Name</label>
                                </div>
                            </li>
                            <li className="mb-3">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="Account"
                                           placeholder="请输入登录账号名(注意:账号只允许数字和字母) / Please enter the login account name (note: the account only allows numbers and letters)t" onChange={(e)=>{this.setState({account:e.target.value})}}/>
                                    <label htmlFor="Account">登陸賬號 / Account</label>
                                </div>
                            </li>
                            <li className="mb-3">
                                <div className="form-floating">
                                <input type="password" className="form-control" id="password"
                                       placeholder="请输入密码 / please enter password" onChange={(e)=>{this.setState({password:e.target.value})}}/>
                                    <label htmlFor="password">登陸密碼 / Password</label>
                                </div>
                            </li>
                            <li className="mb-3">
                                <button className="btn btn-danger " style={{width:"100%",height:"50px",fontSize:"17px"}}
                                    onClick={()=>this.register()}
                                >立即註冊 / Register Now</button>
                            </li>
                            <li className="mb-3">
                                <button className="btn btn-outline-secondary " style={{width:"100%",height:"50px",fontSize:"17px"}}
                                        onClick={()=>this.returnBack()}
                                >返回 / Back To</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

/*
*  <li className="mb-3">
                                <div className="form-floating">
                                    <select className="form-select" id="selectCountry" onChange={(e)=>{this.setState({countryCode:e.target.value})}}>
                                        <option selected>Open this select menu</option>
                                        {this.state.countryList===null || undefined ? <option /> : this.state.countryList.map(i=>(
                                            <option value={i.code}>{i.name}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="selectCountry">What region does your mobile number belong to</label>
                                </div>
                            </li>
                            <li className="mb-3">
                                <div className="input-group">
                                    <span className="input-group-text">{this.state.countryCode}</span>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="mobile_phone"
                                               placeholder="Mobile Phone" onChange={(e)=>{this.setState({mobilePhone:e.target.value})}}/>
                                            <label htmlFor="mobile_phone" >Mobile Phone</label>
                                    </div>
                                </div>
                            </li>
                            <SMSVerificationComponent callback_onChange={(val)=>{this.setState({code:val})}}
                                                      callback_sendCode={()=>{this.sendCode()}}
                                                      callback_canSend={()=>{this.setState({isSent:false})}}
                            />*/

export const RegisterPage = withRouter(RegisterPageCore);


function SMSVerificationComponent({callback_onChange,callback_sendCode,callback_canSend}) {

    let [sent,setSent] = React.useState(false);
    let [number,setNumber] = React.useState(60);
    let [val,setVal] = React.useState(null);



    let d =60;



    return (
        <li className="mb-3">
            <div className="input-group">
                <div className="form-floating">
                    <input type="text" className="form-control" id="VCode"
                           placeholder="Please input the verify code"
                           value={val}
                           onChange={(e)=>{setVal(e.target.value);callback_onChange(e.target.value)}}
                    />
                    <label htmlFor="VCode">Verify Code</label>
                </div>
                <button className="btn btn-outline-danger"
                        type="button"
                        disabled={sent}
                        onClick={()=>{
                            callback_sendCode();
                            setSent(true);
                            let t = window.setInterval(
                                ()=>{
                                    if (d === 0){
                                        window.clearInterval(t);
                                        d=60;
                                        setNumber(d);
                                        setSent(false);
                                        callback_canSend();
                                    }else{
                                        setNumber(d--);
                                    }
                                },
                                Second
                            )
                        }}
                >{sent === true ? `${number} Second` : "Send Code"}</button>
            </div>
        </li>
    )
}

function CacptchaComponent({callback_onSuccess}) {

    return (
        <div ></div>
    )
}

/**
 * @return {boolean}
 */
function IsWeakPasswords(pass){
    let regex = /(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}/;
    return !regex.test(pass)
}

/**
 * @return {boolean}
 */
export function IsAccount(a) {
    let regex = /^[a-zA-Z]\w{4,19}$/;
    return regex.test(a)
}

/**
 * @return {boolean}
 */
function IsNickName(n) {
    let regex = /^[a-zA-Z0-9_\u4e00-\u9fa5\\s·]+$/;
    return regex.test(n)
}