import React from 'react';
import {api,newBiPoint} from "../../api/base";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {WaitingComponent} from "../CommonComponents/Waiting";
import {ErrorNotVideoComponent} from "../CommonComponents/Error";
import {useAuth} from "../../Auth/Route";
import {ImageComponent} from "../CommonComponents/Image";
import {episodeSort, IsHttpsURL, MakeStaticURL} from "../CommonComponents/LittleFunc";
import Helmet from "react-helmet";
import {website_name} from "../CommonComponents/WebsiteTitle";



const videoComponentNumber = 1;
const showComponentNumber = 2;
const searchComponentNumber = 3;

/**
 * @return {boolean}
 */
function IsNull(d) {
    return d===null;
}

export function ChannelPage(){


    const params = useParams();
    let [userId,setUserId] = React.useState(params.user_id);
    let auth = useAuth();
    let navigator = useNavigate();
    let [selectedId,setSelectedId] = React.useState(videoComponentNumber);
    let [user,setUser] =  React.useState(null);
    let [keyword,setKeyword] = React.useState(null);
    let [isLoggedIn,setLoggedIn] = React.useState(auth.isLoggedIn);
    //打开直播频道按钮
    let [isOpenLive,setOpenLive] = React.useState(false);
    //打开聊天室按钮
    let [isOpenChat,setOpenChat] = React.useState(false);
    //打开商品橱窗
    let [isOpenMerchandise,setOpenMerchandise] = React.useState(false);


    if (userId === undefined || userId === null || userId.length<=0){
        if (auth.isLoggedIn===true && auth.user !== null){
            setUserId(auth.user.user_id)
        }else{
            navigator("/not_found",{replace:true})
        }
    }

    React.useEffect(()=>{
        const getData=function(){
            api.GetUserProfile(userId,(content)=>{setUser(content)},(msg)=>{console.log(msg)})
        };
        getData();
    },[userId]);

    React.useEffect(
        ()=>{
            setLoggedIn(auth.isLoggedIn);
        },
        [auth.isLoggedIn]
    );


    let nav=[
        {
            id:videoComponentNumber,
            name:"视频",
            component:VideoComponent,
        },{
            id:showComponentNumber,
            name:"节目",
            component:ShowComponent
        },
    ];




    let renderContent = function () {

        switch (selectedId) {
            case videoComponentNumber:
                return <VideoComponent user_id={userId} user_data={user}/>;
            case showComponentNumber:
                return <ShowListComponent user_id={userId} user_data={user}/>;
            case searchComponentNumber:
                return <SearchComponent keyword={keyword} user_id={userId}/>;
            default:return <VideoComponent user_id={userId} user_data={user}/>;
        }
    };

    let changeSelectedId = function (id) {
        //console.log(id);
        setSelectedId(id)
    };

    if (user=== null) {
        return (
            <div >

                <HeadNavComponent />
                <div className="crwarp">
                    <WaitingComponent />
                </div>
            </div>
        )
    }

    return (
        <div>
            <Helmet>
                <title>{user.name}'s Channel - {website_name}</title>
            </Helmet>
            <HeadNavComponent/>
            <div className="crwarp">
                <ChannelHeadComponent data={user} isLoggedIn={isLoggedIn}/>
                <NavComponent nav={nav} callback_selected={(id)=>changeSelectedId(id)} callback_search={(keyword)=>{
                    setKeyword(keyword);
                    setSelectedId(searchComponentNumber);
                }}
                />
                { renderContent() }
            </div>
            <FootNavComponent />
        </div>
    )

}




class ChannelHeadComponent extends React.Component{
    fans_amount: string;

    constructor(props){
        super(props);
        this.state={
            data:props.data,
            is_follow:false,
            pageToken:"",
            fansAmount:props.data.fans_amount,
            isLoggedIn:props.isLoggedIn,
        }
    }

    componentDidMount(): void {
        if (this.state.isLoggedIn === true) {
            return api.IsFollowed(this.state.data.id,
                (response) => {
                    this.setState({
                        is_follow: response.is_followed
                    })
                }, (msg) => {
                    console.log(msg["error_message"])
                })
        }
    }
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.state.isLoggedIn !== prevState.isLoggedIn && this.state.isLoggedIn !== false){
            return api.IsFollowed(this.state.data.id,
                (response) => {
                    this.setState({
                        is_follow: response.is_followed
                    })
                }, (msg) => {
                    console.log(msg["error_message"])
                })
        }
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {

        this.setState({isLoggedIn:nextProps.isLoggedIn})
    }

    follow(e){
        e.target.disabled = true ;
        api.Follow(this.state.data.id, () => {
            let fa = this.state.fansAmount;
            if (!this.state.is_follow === false){
                fa = fa - 1 <=0 ? 0 : fa-1
            }else{
                fa = fa + 1;
            }
            this.setState({is_follow: !this.state.is_follow,fansAmount:fa})
        }, (message) => {
            window.alert(message["error_message"]);
        }, !this.state.is_follow, this.state.pageToken);

        setTimeout(()=>{
            e.target.disabled = false;
        },1000*5);
    }
    renderFollowButton(){

        if (this.state.is_follow===false){
            return (
                <button className="btn btn-danger rounded-5 text-center fw-bold" style={{height:"40px",fontSize:"15px"}}
                    onClick={(e) => this.follow(e)}>
                    关注
                </button>
            )
        }else{
            return (
                <button className="btn btn-outline-danger rounded-5 text-center fw-bold" style={{fontSize: "15px"}} onClick={(e)=>this.follow(e)}>
                    <i className="bi bi-check-circle-fill" /> 取消关注
                </button>
            )
        }
    }

    render(){

        if (this.state.data === null){return <div >{this.props.data}</div>}

        return (
            <div className="channel">
                <div className="channel-img">
                    <img src={this.state.data.background} alt={this.state.data.name} width={"1180px"} height={"330px"}/>
                    <div className="code">
                        <img src={this.state.data.icon} alt={this.state.data.name} width={"70px"} height={"70px"}/>
                    </div>
                </div>
                <div className="channel-details">
                    <div className="channel-small-img" style={{padding:"10px",width:"110px",height:"110px"}}>
                        <img src={this.state.data.icon} alt={this.state.data.name} style={{width:"90px",height:"90px"}} />
                    </div>
                    <div className="channel-name fl">{this.state.data.name}</div>
                    <div className="do-info fr" style={{height:"80px",paddingTop:"20px",paddingBottom:"20px"}}>
                        <span style={{height:"40px",lineHeight:"40px",fontSize:"15px"}} >
                            <b className="text-dark fw-bold" style={{fontSize:"17px"}}>{this.state.fansAmount}</b>{`人已关注TA`}</span>
                        {this.renderFollowButton()}
                    </div>
                </div>
            </div>
        )
    }
}

class NavComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            callback_selected:props.callback_selected,
            callback_search:props.callback_search,
            nav:props.nav,
            selected_id:1,
            keyword:null,
        }
    }
    selected(id){
        this.setState({
            selected_id:id,
        });
        this.props.callback_selected(id);
    }
    search(){
        this.setState({
            selected_id:searchComponentNumber,
        });
        this.state.callback_search(this.state.keyword)
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="channel-nav" style={{height:"70px",width:"100%"}}>
                <div className="channel-menu" style={{lineHeight:"40px",height:"40px"}}>

                    <ul className="nav nav-pills" style={{height:"40px"}}>
                        { this.state.nav.map( item =>(
                            <li className="nav-item" style={{height:"40px"}}>
                                <a onClick={()=>this.selected(item.id)} style={{height:"40px",lineHeight:"30px",fontSize:"17px"}}
                                   className={this.state.selected_id===item.id ? "nav-link active text-center bg-danger fw-bold" : "nav-link text-center text-danger fw-bold" }>{item.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="fr channel-search">

                    <div className="input-group" style={{height: "40px"}}>
                        <input type="text" className="form-control" onChange={(event)=>{this.setState({keyword:event.target.value})}} style={{border:"1px solid #ff0000"}}/>
                        <button className="btn btn-danger" type="button" onClick={()=>this.search()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                 className="bi bi-search" viewBox="0 0 16 16">
                                <path
                                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}

class VideoComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            user_id:props.user_id,
            video:null,
            InitState:true,
            page_token:this.props.page_token,
            user_data:props.user_data,
            total_page:1,
            current_page:1,
            total:0,
        };
    }
    componentDidMount(): void {
        this.getTotal();
    }
    getTotal(){
        api.GetUserVideoTotal(
            this.state.user_id,(content)=>{
                this.setState({
                    total:content.total,
                    total_page:content.page_amount,
                    current_page:1
                });
                if (content.total > 0){
                    this.getData(this.state.current_page);
                }
            },(error_message)=>{console.log(error_message)},
            this.state.page_token
        )
    }
    getData(page:number){
        api.GetUserVideo({user_id:this.state.user_id,page:page},(content)=>{

            if (content === null ){
                return this.setState({video:null,InitState:false,current_page:page})
            }else{
                let v = this.state.video === null ? [] : this.state.video ;
                v.push(...content);
                this.setState({video:v,InitState:false,current_page:page})
            }

        },(error_message)=>{console.log(error_message)},this.state.page_token);
    }
    load_more(){
        this.getData(this.state.current_page + 1)
    }
    render(){

        if (this.state.InitState===true){
            return (
            <div className="vlist2" style={{marginTop:"5px",width:"100%"}}>
                <WaitingComponent setBorder={false}/>
            </div>)
        }
        if(this.state.video === null || this.state.video === undefined){
            //console.log(this.state.video);
            return (
                <div className="vlist2" style={{marginTop:"5px",width:"100%"}}>
                    <ErrorNotVideoComponent setBorder={false} />
                </div>
            )
        }


        return (
            <div className="vlist2" style={{marginTop:"5px",width:"100%"}}>
                <div className="hd">
                    <div className="title">
                        <a><span className="text-danger fw-bold" style={{fontSize:"17px"}}>全部视频</span></a>
                    </div>
                </div>
                <ul style={{margin:"0px",padding:"0px"}}>
                    { this.state.video.map( item=>(
                        <li>
                            <Link to={`/watch/${item.id}`} onClick={()=>{newBiPoint("video","click",item.id)}}>
                                <ImageComponent src={item.thumb} topic={"video"} Gif={item.Gif} itemId={item.id} isPoster={false} style={{width:"270px",height:"180px"}}/>
                            </Link>
                            <div className="vdetails">
                                <h2 style={{margin:"0",maxHeight:"50px",minHeight:"24px",height:"auto"}}>
                                    <Link to={`/watch/${item.id}`} replace={true} onClick={()=>{newBiPoint("video","click",item.id)}}>
                                        {item.title}
                                    </Link>
                                </h2>
                                <p style={{margin:"0"}}>From <Link to={`/user/${this.state.user_data.id}`}>{this.state.user_data.name}</Link></p>
                                <p style={{margin:"0"}}>{`${item.click} Views · Published On ${item.published}`}</p>
                            </div>
                        </li>
                    ))}
                </ul>

                {this.state.total_page <= this.state.current_page ? <div /> : <div className="lmore"
                    onClick={ ()=>this.load_more() }
                >加载更多</div>}

            </div>
        )
    }
}

/*
* <div className="lmore">加载更多</div>
* */

class ShowListComponent extends React.Component{
    creator: {id:string,name:string,icon:string};
    episodes:{video:{id:string,title:string,thumb:string,published:string},sort_number:number,stage:string};

    constructor(props){
        super(props);
        this.state={
            topic:null,
            user_id:props.user_id,
            user_data:props.user_data,
            InitState:true,
            pageToken:"",
        }
    }
    componentDidMount(): void {
        this.getData();
    }

    getData(){
        api.GetUserTopic(this.state.user_id,
            (content)=>{
                this.setState({topic:content,InitState:false})
            }
            ,(repose)=>{console.log(repose.error_message)},this.state.pageToken)
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        if (this.state.InitState === true){
            return <div style={{width:"100%"}}>
                <WaitingComponent setBorder={false} />
            </div>
        }
        if(this.state.topic === null){
            return (
                <ErrorNotVideoComponent setBorder={false} />
            )
        }
        return (<div >{ this.state.topic.map(item=>(
            <ShowComponent data={item} user_data={this.state.user_data}/>
        ))}
                <div style={{height:"20px",width:"100%",float:"left"}} />
        </div>
        )
    }
}


class ShowComponent extends React.Component {

    constructor(props){
        super(props);
        this.state={
            data:props.data,
            episodes:[],
            user_data:props.user_data
        }
    }
    render() {
        if (this.state.data === null || this.state.data === undefined) {
            return (
                <div >
                    <ErrorNotVideoComponent setBorder={false} />
                </div>
            )
        }
        return (
            <div className="vlist1" style={{marginTop: "5px", marginBottom: "7.5px",width:"100%"}}>
                <div className="hd">
                    <div className="title">
                        <span>
                            <Link to={`/program/${this.state.data.id}`} style={{textDecoration:"none"}} class="text-danger fw-bold">{this.state.data.title}</Link>
                        </span>
                    </div>
                </div>
                <ChannelEpisodesComponent id={this.state.data.id} creator={this.state.user_data}/>
            </div>
        )
    }
}

function ChannelEpisodesComponent({id,creator}) {

    let [episodes,setEpisodes] = React.useState([]);
    let [initState,setInitState] = React.useState(true);

    React.useEffect(()=>{
        api.GetEpisodes({
                id: id,
                page_token: ""
            },
            (response) => {
                if (response===null){return }
                setEpisodes(episodeSort(response))
            },
            (msg) => {
            }
        );
        setInitState(false);
    },[initState === true]);

    if (episodes === null || episodes.length <=0){
        return (
            <ErrorNotVideoComponent />
        )
    }

    return (
        <ul style={{margin:"0px",padding:"0px"}}>
            {episodes.map(
                (episode)=>(
                    <li>
                        <Link to={`/watch/${episode.video.id}`} onClick={()=>{
                            newBiPoint("video","click",episode.video.id);
                        }}>
                            <ImageComponent style={{width:"212px",height:"141px"}} src={IsHttpsURL(episode.video.thumb,(url)=>MakeStaticURL(url))} isPoster={false} itemId={episode.video.id} topic={"video"} />
                        </Link>
                        <div className="vdetails">
                            <h2 style={{margin:0,height:"auto",maxHeight:"50px",lineHeight:"23px",minHeight:"25px"}}>
                                <Link to={`/watch/${episode.video.id}`} replace={true} onClick={()=>{newBiPoint("video","click",episode.video.id);}}>
                                    {episode.video.title}
                                </Link>
                            </h2>
                            <p style={{margin:0}}>From <Link to={`/user/${creator.id}`}>{creator.name}</Link></p>
                            <p style={{margin:0}}>{`${episode.video.click} Views · Published On ${episode.video.published}`}</p>
                        </div>
                    </li>
                )
            )}
        </ul>
    )
}

class SearchComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            data:null,
            keyword:props.keyword,
            user_id:props.user_id,
            total_page:0,
            total:0,
            current_page:1
        }
    }
    componentDidMount(): void {

        if (this.state.keyword === null || this.state.keyword === undefined){
            console.log(this.state.keyword);
            return
        }
        let keyword  = this.state.keyword;
        let user_id = this.state.user_id;
        let page = this.state.current_page;

        this.getData(keyword,user_id,1)
    }
    getData(keyword,user_id,page=1){
        api.Search({keyword:keyword,id:user_id,page:page},(content)=>{
            let v = this.state.data === null ? [] : this.state.data;
            v.push(...content.items);
            this.setState({
                data:v,
                total_page:content.total_page,
                current_page:content.current_page,
                total:content.total
            })
        },(error_message)=>{console.log(error_message)})
    }

    componentWillReceiveProps(nextProps, nextContext): void {
        this.setState({
            keyword:nextProps.keyword
        });
        this.getData(nextProps.keyword,this.state.user_id,1)
    }

    render(){

        if (this.state.data === null) {
            return <div>{this.state.keyword}</div>
        }
        return (
            <div className="vlist2" style={{marginTop: "5px",width:"100%"}}>
                <div className="hd">
                    <div className="title">
                        <a><span style={{fontSize:"15px",color:"#000",fontWeight:"bold"}}>关于“<b style={{color:"#ff0000",fontSize:"17px"}}>{this.state.keyword}</b>”的最新视频</span></a>
                    </div>
                </div>
                <ul style={{listStyle:"none",margin:"0px",padding:"0px"}}>
                    {this.state.data.map(item => (
                        <li>
                            <Link to={`/watch/${item.id}`} onClick={()=>{
                                newBiPoint("video","click",item.id);
                            }}>
                                <ImageComponent topic={"video"} itemId={item.id} src={item.thumb} isPoster={false} style={{width: "270px", height: "180px"}} />
                            </Link>
                            <div className="vdetails">
                                <h2 style={{margin:"0",maxHeight:"50px",minHeight:"24px",height:"auto"}}> <Link to={`/watch/${item.id}`} onClick={()=>{
                                    newBiPoint("video","click",item.id);
                                }}>{item.title}</Link></h2>
                                <p style={{margin:0}}>From <Link to={`/user/${item.creator_id}`}>{item.creator_name}</Link></p>
                                <p style={{margin:0}}>{`${item.viewer} Views · Published On ${item.creatr_time}`}</p>
                            </div>
                        </li>
                    ))}
                </ul>
                { this.state.current_page > this.state.total_page ? <div /> : <div className="lmore"

                    onClick={()=>{this.getData(this.state.keyword,this.state.user_id,this.state.current_page + 1)}}
                >加载更多</div>}
            </div>
        )
    }
}


/*
* <div className="loading-now">
                    <img src="images/loading.gif" height="20" alt={""}/>
                    <span>数据加载中，请稍后...</span>
                </div>
                <div className="lmore">加载更多</div>
*
* */