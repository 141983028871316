import React from 'react';
import {api, search_key, SearchDomain} from "../../api/base";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {pageToken, withRouter} from "../CommonComponents/WithRouter";
import {Link, useLocation, useParams} from "react-router-dom";
import {defaultThumb, PaginationComponent} from "./Studio/CommonComponents/Form";
import {WaitingComponent} from "../CommonComponents/Waiting";
import Helmet from "react-helmet";
import {instantMeiliSearch} from "@meilisearch/instant-meilisearch";
import {useAuth} from "../../Auth/Route";
import {
    InstantSearch,
    connectHits,
    connectSearchBox,
    connectPagination,
    Configure,
    SortBy
} from "react-instantsearch-dom";
import {timestampToTime} from "../CommonComponents/LittleFunc";
import {ImageComponent} from "../CommonComponents/Image";
import {website_name} from "../CommonComponents/WebsiteTitle";



const searchResult = ({hits})=>{

    return (
        <ul style={{margin:0,padding:0,display:"flow-root"}}>
            {
                hits.map(hit => (
                    <li>
                        <Link to={`/watch/${hit.id}`} className="vpic">
                            <ImageComponent src={hit.thumb}
                                            itemId={hit.id}
                                            isPoster={false}
                                            topic={"video"} style={{width: "195px", height: "130px"}}/>
                        </Link>
                        <div className="vdetails" style={{height: "auto"}}>
                            <Link to={`/watch/${hit.id}`} style={{
                                textDecoration: "none",
                                height: "auto",
                                maxHeight: "45px"
                            }}>{hit.title}</Link>
                            <p>{`${hit.viewer}次观看 · ${timestampToTime(hit.create_time)}`}</p>
                        </div>
                    </li>
                ))}
        </ul>
    )
};

const searchBox = ({currentRefinement,isSearchStalled,refine}) =>{


    //console.log(currentRefinement);
    return (
        <div className="search-index" style={{border:"none"}}>

            <div className="input-group">
                <input type="text" className="form-control"
                       value={currentRefinement}
                       placeholder="Please input the keyword" style={{border:"1px solid #ff5555"}}
                       onChange={(event)=>{refine(event.currentTarget.value)}}
                />
                <button className="btn btn-danger" type="button" style={{width:"60px"}} onClick={()=>{} }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                         className="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>
                </button>
            </div>
        </div>
    )
};

const customPagination = ({currentRefinement,nbPages,refine,createURL})=>{

    return (
        <div className="text-center w-auto align-middle" style={{height:"50px"}}>
            <PaginationComponent maxPage={nbPages} currentPage={currentRefinement} callback_switch={(page)=>{refine(page)}}/>
        </div>
    )

};

export function SearchPage() {

    let auth = useAuth();
    let location = useLocation();
    let param = useParams();
    let page_token = new pageToken(auth.pageToken);
    let searchClient = instantMeiliSearch(SearchDomain,search_key);



    let keyword  = location.state === null ? "" : location.state.keyword;
    keyword = keyword === undefined || keyword=== null ? "" : keyword;



    const SearchHits = connectHits(searchResult);
    const SearchBox = connectSearchBox(searchBox);
    const CustomPagination = connectPagination(customPagination);


    /*
    * <SortBy items={[
                        {value:"Video:create_time:asc",label:"create time asc"},
                        {value:"Video:create_time:desc",label:"create_time desc"},
                    ]} defaultRefinement={"Video:create_time:asc"}/>
    * */
    return (
        <div>
            <Helmet>
                <title>Search  - {website_name}</title>
            </Helmet>
            <HeadNavComponent />
            <div className="search-warp" style={{minHeight:"850px"}}>
                <InstantSearch searchClient={searchClient} indexName={"video"}>
                    <Configure hitsPerPage={16} />
                    <SearchBox defaultRefinement={keyword} />
                    <div className="SLeft fl">
                        <div className="SDetails">
                            <div className="Sfj d-block" >
                                <div className="Slist" style={{float:"none"}}>
                                    <SearchHits />
                                </div>
                                <CustomPagination/>
                            </div>
                        </div>
                    </div>
                </InstantSearch>
                <OtherComponent />
            </div>
            <FootNavComponent/>
        </div>
    )
}

class OtherComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            initState:true
        }
    }

    componentDidMount(): void {
        //this.setState({initState:false})
    }


    render() {

        if (this.state.initState===true){

            return (
                <div className="SRight">
                    <WaitingComponent setBorder={false}/>
                </div>
            )
        }

        return (
            <div className="SRight">
                <div className="sad">
                    <a > <img src="" alt={"ad"}/></a>
                </div>
                <div className="sad">
                    <a href="##"><img src=""/></a>
                </div>
                <CommendVideoComponent/>
            </div>
        )
    }
}

function CommendVideoComponent() {


    let [data,setData] = React.useState(null);
    let [initState,setInitState] = React.useState(true);

    let getData =()=>{
        api.GetCommendVideo("", (content)=>{
            let da=content.slice(0,4);
            setData(da);
        }, (msg)=>{console.log(msg["error_message"])})
    };

    React.useEffect(()=>{
        setInitState(false);
        getData();
    },[initState===true]);

    return (
        <div className="sVideo" style={{height:"1000px",minHeight:"550px"}}>
            <div className="stitle"><h2>为你推荐</h2></div>
            <div className="sVlist fr" >
                <ul style={{margin:"0",padding:0}}>
                    {data.map(item => (
                        <li>
                            <Link to={`/watch/${item.id}`} replace={true}>
                                <img src={item.thumb.length <= 0 ? defaultThumb : item.thumb} alt={item.title} style={{width:"240px",height:"160px"}}/>
                            </Link>
                            <div className="vdetails">
                                <Link to={`/watch/${item.id}`} replace={true}>
                                    {item.title}</Link>
                                <p>{`${item.click}次观看 · ${item.published}`}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

function DefaultSearchComponent() {

    return (
        <div className="justify-content-center d-flex align-middle" >
            <div className="d-flex align-items-center justify-content-center text-center text-danger">
                <span className="fs-4 text-primary">Please input keyword and click button to search video</span>
            </div>
        </div>
    )
}