import React from 'react';
import {api, VideoTopicToType} from "../../api/base";
import {Link} from "react-router-dom";
import {WaitingComponent} from "../CommonComponents/Waiting";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {withRouter} from "../CommonComponents/WithRouter";
import ReactHlsPlayer from "react-hls-player";
import {DefaultImageComponent, ImageComponent} from "../CommonComponents/Image";
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import {DiscussComponent} from "../CommonComponents/Discuss";
import {episodeSort, IsHttpsURL, MakeStaticURL, numberUnit2Text} from "../CommonComponents/LittleFunc";
import {ShowRatingComponent} from "../CommonComponents/Rating";
import Helmet from "react-helmet";
import {website_name} from "../CommonComponents/WebsiteTitle";
import {array} from "prop-types";




export class WatchPageCore extends React.Component{

    constructor(props) {
        super(props);
        this.state={
            video:null,
            video_id:this.props.router.params.video_id,
            openRewardBox:false,
            pageToken:this.props.router.pageToken.to(),
            playLink:null,
            container:null,
            isCollected:false,
            isLike:false,
            needPurchase:false,
            isLoggedIn:this.props.router.auth.isLoggedIn,
            like_amount:0,
        };
        //this.getVideoData()
    }
    componentDidMount(): void {
        if ( this.state.isLoggedIn === true){
                this.getVideoData();
                this.getWatchLink();
        }else{
            return this.props.router.navigate("/login",{replace:true,state:{pathname:`/watch/${this.state.video_id}`}})
        }
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {

        let video_id = nextProps.router.params.video_id;
        let pageToken = nextProps.router.pageToken.to();
        let isLoggedIn = nextProps.router.auth.isLoggedIn;
        this.setState({
            video:null,
            video_id:video_id,
            pageToken:pageToken,
            playLink:null,
            container:null,
            isCollected:false,
            isLike:false,
            openRewardBox:false,
            isLoggedIn:isLoggedIn,
            needPurchase:false
        });
        if (isLoggedIn === true){
            this.getVideoData(video_id);
            this.getWatchLink(video_id);
        }else{
            return nextProps.router.navigate("/login",{replace: true,state:{pathname:`/watch/${video_id}`}})
        }
    }
    getVideoData(video_id=this.state.video_id){
         api.GetVideoDetail({
             id:video_id,
             page_token:this.state.pageToken,
         },(content)=>{
            this.setState({video:content})
         },(msg)=>{
             console.log(msg)
         })
    }
    getWatchLink(video_id=this.state.video_id){
        api.GetPlayLink({
            video_id:video_id,
            program_id:"",
            page_token:this.state.pageToken,
        },(response)=>{

            let play_link = response[0].play_link
            let container = response[0].container
            this.setState({playLink:play_link,container:container})
        },(msg)=>{
            window.alert(msg["error_message"]);
        },(url)=>{
            let program = this.state.video.program=== null ? "" : this.state.video.program.id;
            if (program === ""){
                window.alert("该视频需要订阅才可以观看!");
                return
            }
            if (window.confirm("该视频需要订阅才可以观看,是否订阅?")){
                return this.props.router.navigate(`/subscribe_program/${program}`,{replace:true,state:{pathname:`/watch/${video_id}`}})
            }else{
                return this.prop.router.navigate("/",{replace:true})
            }
        })
    }
    Reward(){
        this.setState({openRewardBox:!this.state.openRewardBox})
    }
    collectVideo(){
        api.CollectVideo({
            id:this.state.video_id,
            page_token:this.state.pageToken
        },(response)=>{
            this.setState({
                isCollected:true
            })
        },()=>{})
    }

    render(){
        if (this.state.video == null){
            return <WaitingComponent />
        }

        return (
            <div>
                <Helmet >
                    <title>{`${this.state.video.title} - ${website_name}`}</title>
                </Helmet>
                <HeadNavComponent/>
                <PlayerComponent video={this.state.video}
                                 callBack_RewardBox={this.Reward.bind(this)}
                                 playLink={this.state.playLink}
                                 container={this.state.container}
                                 giveLike={(like_amount)=>{this.setState({like_amount:like_amount})}}
                                 {...this.props}
                />
                <OtherComponent video={this.state.video} likeAmount={this.state.like_amount}/>
                <FootNavComponent/>
                <RewardComponent isOpen={this.state.openRewardBox}
                                 targetId={this.state.video.creator.id}
                                 targetName={this.state.video.creator.name}
                                 videoId={this.state.video.id}
                                 callback_closeRewardBox={ this.Reward.bind(this) }
                                 {...this.props}
                />
            </div>

        )
    }
}
export const WatchPage =  withRouter(WatchPageCore);



class PlayerComponent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            video:props.video,
            playLink:props.playLink,
            container:props.container,
            like_amount:props.video.like,
            isLiked:false,
            isCollected:false
        };
        this.callBack_RewardBox= props.callBack_RewardBox;
        this.playerRef = React.createRef();

    }
    componentDidMount(): void {
        api.IsLiked({
            type:VideoTopicToType,
            id:this.state.video.id,
            page_token:this.state.pageToken,
        },(response)=>{
            this.props.giveLike(response.like_amount);
            this.setState({isLiked:response.is_liked,like_amount:response.like_amount})
        },(msg)=>{console.log(msg["error_message"])})
    }

    componentWillReceiveProps(nextProps) {
        this.setState({video: nextProps.video,playLink:nextProps.playLink,container:nextProps.container});
    }
    giveReward(video_id:string){
        this.callBack_RewardBox(video_id);
    }
    giveLike(){
        api.GiveLike({
            id:this.state.video.id,
            type:VideoTopicToType,
            is_like:!this.state.isLiked,
            page_token:this.state.pageToken
        },(response)=>{
            let is_liked = !this.state.isLiked;
            this.setState({isLiked:is_liked,like_amount:is_liked === true ? this.state.like_amount+1 : this.state.like_amount -1 });
            this.props.giveLike(this.state.like_amount)

        },(msg)=>{console.log(msg["error_message"])})
    }
    collectVideo(video_id:string){

        api.CollectVideo({
            video_id:video_id,
            is_give_up: this.state.isCollected,
            page_token:""
        },(response)=>{
            this.setState({isCollected:!this.state.isCollected})
        },(msg)=>{
            console.log(msg["error_message"])
        });
    }
    reportVideo(video_id:string){
        this.props.router.navigate(`/report/${video_id}`,{replace:true,state:{item_type:"video",item_title:this.state.video.title}})
    }

    renderPlayer(){
        let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
        let l = this.state.playLink ;

        if (reg.test(l) === true) {
            let ext = l.split(".").pop();
            if (ext === "m3u8") {
                return (
                    <ReactHlsPlayer playerRef={this.playerRef}  src={l}
                                    autoPlay={true}
                                    controls={"controls"}
                                    style={{width: "100%", height: "100%", objectFit: "fill"}}/>
                )
            }
        }
        return (
            <video width={"100%"} height={"100%"} crossOrigin="anonymous" controls="controls"
                   style={{objectFit: "fill"}} autoPlay={true}>
                <source src={l} type={"video/mp4"}/>
            </video>
        )


    }
    render(){
        return (
            <div >
                <div className="play-warp">
                    <div className="player">
                        {this.renderPlayer()}
                    </div>
                    <div className="mod-func">
                        <span className="fw-bold text-white" style={{fontSize:"14px"}}>{`${this.state.video.click} Views`}</span>
                        <div className="funcRight" style={{marginRight:"10px"}}>
                            <ul>
                                <li className="ds">
                                    <a onClick={()=>this.giveReward(this.state.video.id)} className="text-warning text-decoration-none" style={{fontSize:"14px"}}>
                                        <div className="d-flex justify-content-center">
                                            <i className="bi bi-coin text-center" style={{height:"auto",background:"none",margin:0}}/>
                                            <em className="fs-bold">打赏</em>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>this.giveLike(this.state.video.id)} className="text-danger text-decoration-none"  style={{fontSize:"14px"}}>
                                        <div className="d-flex justify-content-center">
                                            <i className={`${this.state.isLiked === true ? "bi-heart-fill" : "bi-heart"} bi text-center`}
                                               style={{height: "auto", background: "none", margin: 0}}/>
                                            <em className="fw-bold"
                                                style={{border: "none"}}>{this.state.like_amount}</em>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>this.collectVideo(this.state.video.id)} className={`${this.state.isCollected === true ? "text-warning-emphasis" :"text-warning"}  text-decoration-none align-middle justify-content-center`} style={{fontSize:"14px"}}>
                                        <div className="d-flex justify-content-center" >
                                        <i className="bi bi-star-fill text-center" style={{background:"none",margin:0}}/>
                                            <em className="fw-bold">{this.state.isCollected === true ? "已收藏": "收藏"}</em></div>
                                    </a>
                                </li>
                                <li>
                                    <a onClick={()=>this.reportVideo(this.state.video.id) } className="text-primary text-decoration-none " style={{fontSize:"14px"}}>
                                        <div className="d-flex justify-content-center">
                                            <i className="bi bi-exclamation-triangle-fill"
                                               style={{background: "none", margin: 0}}/>
                                            <em className="fw-bold">举报</em>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

class OtherComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            video:props.video,
            like_amount:props.likeAmount
        };

    }
    componentWillReceiveProps(nextProps) {
        this.setState({video: nextProps.video,like_amount:nextProps.likeAmount});
    }
    render(){
        return  (
            <div className="other-warp">
                <LeftComponent video={this.state.video} like_amount={this.state.like_amount}/>
                <RightComponent  video={this.state.video}/>
            </div>
        )
    }
}

class LeftComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            video:props.video,
            like_amount:props.like_amount
        };

    }
    componentWillReceiveProps(nextProps) {
        this.setState({video: nextProps.video,like_amount:nextProps.like_amount});
    }
    render(){

        return (
            <div>
                <div className="oLeft fl">
                    <ChannelComponent video={this.state.video} />
                    <ProgramComponent topic={this.state.video.program}/>
                    <AboutComponent video={this.state.video} like_amount={this.state.like_amount}/>
                    <DiscussComponent authorId={this.state.video.creator.id} itemId={this.state.video.id} topic={"video"}/>
                </div>
            </div>
        )
    }
}

class AboutComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            video:props.video,
            tags:props.video.tags === null ? [] : props.video.tags,
            like_amount:props.like_amount,
        };

    }
    componentWillReceiveProps(nextProps) {
        this.setState({video: nextProps.video,like_amount:nextProps.like_amount});
    }
    render(){
        return (
            <div className="oAbout" style={{height:"auto",minHeight:"value",margin:0}}>
                <div className="otitle">关于</div>
                <div className="atxt" >{this.state.video.description}</div>
                <div className="source" style={{height:"258px"}}>
                    <div className="sLeft">
                        <ul style={{margin:"0px",padding:"0px"}}>
                            <li>视频标题</li>
                            <li>发布时间</li>
                            <li>创作者</li>
                            <li>播放数</li>
                            <li>点赞数</li>
                            <li>标签</li>
                        </ul>
                    </div>
                    <div className="sRight" style={{height:"auto",float:"left"}}>
                        <div className="sList"><h2 style={{lineHeight:"30px"}}>{this.state.video.title}</h2></div>
                        <div className="sList">{this.state.video.published}</div>
                        <div className="sList">
                            <Link to={`/user/${this.state.video.creator.id}`} style={{color:"#1E90FF"}}>{
                            this.state.video.creator.name
                            }</Link></div>
                        <div className="sList">
                            <div className="score">
                                <span className="mark" style={{margin:0,padding:0,background:"#fff"}}>{this.state.video.click} Views</span>
                            </div>
                        </div>
                        <div className="sList">
                            <div className="score">
                                <span className="mark" style={{margin:0,padding:0,background:"#fff"}}>{this.state.like_amount} Likes</span>
                            </div>
                        </div>
                        <div className="sList">
                            <dl>
                                { this.state.tags.map(item =>(
                                    <dd style={{border:"none",fontSize:"20px",padding:0,}}><span className="badge text-bg-success text-white">{item.name}</span></dd>
                                    )
                                )}
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

class ProgramComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            topic:props.topic
        };
    }
    componentWillReceiveProps(nextProps) {
        this.setState({topic: nextProps.topic});
    }
    render(){

        if (this.state.topic==null){
            return <div />
        }

        return (
            <div className="oDetails" style={{marginBottom:"20px"}}>
                <div className="ojs">
                    <div className="otitle">节目介绍</div>
                    <div className="ocon">
                        <div className="opic">
                            <Link to={`/program/${this.state.topic.id}`} replace={true} >
                                <DefaultImageComponent src={IsHttpsURL(this.state.topic.poster,(url)=>MakeStaticURL(url))} itemId={this.state.topic.id} isPoster={true} />
                            </Link>
                        </div>
                        <div className="od">
                            <h2><Link to={`/program/${this.state.topic.id}`} replace={true} style={{textDecoration:"none"}}>{this.state.topic.title}</Link></h2>
                            <ShowRatingComponent evaluation={this.state.topic.evaluation} score={this.state.topic.score} />
                            <div className="otext" id="box">
                                <strong>简介：</strong>
                                {this.state.topic.description}
                            </div>
                        </div>
                    </div>
                </div>
                <EpisodesComponent id={this.state.topic.id} />
            </div>
        )
    }
}

class EpisodesComponent extends  React.Component{

    constructor(props){
        super(props);
        this.state = {
            id:props.id,
            data:null,
            amount:0,
            canShowAll:false,
        };
        //this.getEpisodes();
    }
    componentDidMount(): void {
        //console.log(this.state.id);
        this.getEpisodes();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({id: nextProps.id});
    }
    getEpisodes(){
        if (this.state.id === null){return }
        api.GetEpisodes({id:this.state.id},(content)=>{

            if (content === null || content === undefined){return }
            let data = episodeSort(content);
            let dataIndex = 0;
            data.forEach((it,index)=>{
                if (it.video.id === this.state.id){
                    dataIndex=index;
                }
            });
            let start = dataIndex;
            if (start <= 0){
                start = 0;
            }
            let end = start + 4;
            if (end >= data.length ){
                end = data.length
            }
            let renderData= data.slice(start,end);

            this.setState({
                data:data,
                renderData:renderData,
                amount:content.length,
                canShowAll:content.length > 4
            })
        },(msg)=>{
            console.log(msg["error_message"])
        });
    }
    showAll(){
        this.setState({
            renderData:episodeSort(this.state.data),
            canShowAll:false
        })
    }

    render(){
        if(this.state.data === null || this.state.id === null){
            return <div />
        }

        return (
            <div className="ofj" style={{paddingBottom:"10px"}}>
                <div className="otitle">分集视频</div>
                <div className="olist h-auto">
                    <ul style={{margin:0,padding:0}}>
                      {  this.state.renderData.map(item =>(<li>
                              <Link to={`/watch/${item.video.id}`} className="vpic">
                                  <ImageComponent src={`${IsHttpsURL(item.video.thumb,(url)=>MakeStaticURL(url))}`} topic={"video"} itemId={item.video.id} isPoster={false} style={{width:"100%",height:"100%"}}/>
                              </Link>
                              <div className="vdetails ">
                                  <Link to={`/watch/${item.video.id}`}>{item.video.title}</Link>
                                  <p>{`${item.video.click}次观看 · ${item.video.published}`}</p>
                              </div>
                          </li>)
                      )}
                    </ul>
                </div>
                <div className={`loadmore ${this.state.canShowAll===true ? "": "d-none"}`} onClick={()=>this.showAll()}>展开全部</div>
            </div>
        )
    }

}


class RightComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={id:props.video.id,data:null};
    }
    componentDidMount(): void {
        this.getData();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({id: nextProps.video.id});
    }
    getData(){
        if (this.state.id !=null){
            api.GetCommendVideo({
                related_id:this.state.id,
                number:5
            },(content)=>{
                this.setState({data:content})
            },function () {})
        }
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (this.state.data === null){
            return <div />
        }

        return (
            <div className="oRight">
                <div className="oVideo" style={{minHeight:`${this.state.data.length*240}px`}}>
                    <div className="otitle">相关视频</div>
                    <div className="oVlist">
                        <ul style={{margin:0,padding:0}}>
                           {
                               this.state.data.map(item => (<li>
                                   <Link to={`/watch/${item.id}`}>
                                       <ImageComponent isPoster={false} src={item.thumb} topic={"video"} itemId={item.id} />
                                   </Link>
                                   <div className="vdetails">
                                       <Link to={`/watch/${item.id}`}>{item.title}</Link>
                                       <p>{`${item.click}次观看 · ${item.published}`}</p>
                                   </div>
                                </li>))
                           }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}


class RewardComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            isOpen:this.props.isOpen,
            targetId:this.props.targetId,
            targetName:this.props.targetName,
            videoId:this.props.videoId,
            selectedAmount:10,
            pageToken:this.props.router.pageToken,
            combo:[
                {"amount":10},
                {"amount":30},
                {"amount":50},
                {"amount":70},
                {"amount":90},
                {"amount":110}
            ]
        }
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            isOpen:nextProps.isOpen,
        })
    }
    tipNow(){
        api.GiveReward(
            {
                video_id:this.state.videoId,
                user_id:this.state.targetId,
                amount:this.state.selectedAmount,
                page_token:this.state.pageToken.to(),
            },
            ()=>{
                this.close();
            },
            (msg)=>{console.log(msg["error_message"])},
            ()=>{
                window.alert("You don't have enough coins to pay");
                this.props.router.navigate("/recharge",{replace:true,state:{
                    needCurrencyAmount:this.state.selectedAmount/10
                }})
            }
        )
    }
    close(){
        this.props.callback_closeRewardBox();
    }
    render(){
        return (
            <div>
            <div className="gratuity-wrap pf" style={{
                display:this.state.isOpen === true ? "block" : "none",
                height:"auto"
            }}>
                <span className="gratuity-btn-close cp pa" title="Close "  onClick={()=>this.close()}/>
                <div className="gtitle">
                    I Tip To&nbsp;<Link to={`/user/${this.state.targetId}`}>{this.state.targetName}</Link>
                </div>
                <div className="gw" style={{height:"auto"}}>
                    <div className="gwlist">
                        <ul>
                            {this.state.combo.map(
                                item=>(
                                    <li className={this.state.selectedAmount === item.amount ? "current" : ""}>
                                        <div className={`gs gw${item.amount}`} onClick={
                                            ()=>{
                                                this.setState({
                                                    selectedAmount:item.amount
                                                })
                                            }
                                        }/>
                                        <p>{item.amount} Coins</p>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                    <div className="ds-text">
                        <span className="talk-text">{`Tip To ${this.state.targetName}`}</span>
                        <p style={{fontSize:"16px",fontWeight:"bold"}}>A total of <strong>{this.state.selectedAmount}</strong> coins will be given away, and it is expected to consume<strong> {this.state.selectedAmount/10} </strong>USD </p>
                    </div>
                    <a onClick={
                        ()=>this.tipNow()
                    } className="ds-btn">Tip Now</a>
                </div>
            </div>
            <div className="gratuity-layer pf" style={{display:this.state.isOpen=== true ? "block" : "none"}}/></div>
        )
    }
}

function ChannelComponent({video,pageToken}) {


    let creator_icon = video.creator.icon;
    let creator_name = video.creator.name;
    let creator_id   = video.creator.id;
    let brand_name = video.creator.brand_name;

    let [is_follow,setIsFollow] = React.useState(false);
    let [fansAmount,setFansAmount] = React.useState(video.creator.fans_amount===null || video.creator.fans_amount === undefined ? 0 : video.creator.fans_amount);
    let renderSubscriberAmount = ()=>{
        return (
            <div className="col-auto text-center">
                <span className="" style={{fontSize:"15px"}}>{numberUnit2Text(fansAmount)}位订阅者</span>
            </div>
        )
    };

    let renderBrandName = ()=>{
        return (
            <span className="" style={{fontSize: "15px"}}>
                <i className="bi bi-check-circle-fill text-primary"/>
                <span>{brand_name}官方频道</span>
            </span>
        )
    };
    let follow = ()=>{
        api.Follow(creator_id,(response)=>{
            setIsFollow(!is_follow);
            if (!is_follow===false){
                setFansAmount(fansAmount - 1 <=0 ? 0 :fansAmount-1)
            }else{
                setFansAmount(fansAmount + 1);
            }
        },(msg)=>{
            console.log(msg["error_message"])
        },!is_follow,pageToken)
    };



    let renderButton = ()=>{

        if (is_follow === true){
            return (
                <button className="btn btn-outline-danger rounded-5" style={{fontSize: "15px"}} onClick={()=>follow()}>
                    <i className="bi bi-check-circle-fill"/>
                    <span className="ms-1 fw-bold">取消关注</span>
                </button>
            )
        }
        return (
            <button className="btn btn-danger rounded-5" style={{fontSize:"15px"}} onClick={()=>follow()}>
                <i className="bi bi-person-fill-add" />
                <span className="ms-1 fw-bold" >关注</span>
            </button>
        )
    };

    React.useEffect(()=>{

        function init() {

            api.IsFollowed(creator_id,(response)=>{
                setIsFollow(response.is_followed);
                setFansAmount(response.fans_amount)
            },(msg)=>{
                console.log(msg["error_message"])
            })
        }
        init()
    },[]);



    return (
        <div className="w-100 bg-white mb-3 " style={{display:"flow-root",border:"1px #ededed solid"}}>
            <div className="w-100" style={{padding:"10px",display:"flow-root"}}>
                <div style={{width:"50px",height:"50px"}} className="float-start">
                    <Link to={`/user/${creator_id}`} replace={true}>
                        <img src={IsHttpsURL(creator_icon,(url)=>MakeStaticURL(url))} style={{width:"50px",height:"50px"}}  alt={creator_name} className="rounded-pill"/>
                    </Link>
                </div>
                <div className="float-start" style={{height:"50px"}}>
                    <div className="container">
                        <div className="row" style={{height:"25px",lineHeight:"25px"}}>
                            <div className="col-auto text-center">
                                <Link to={`/user/${creator_id}`} replace={true} className="text-decoration-none">
                                    <span className="fs-4 fw-bold" >{creator_name}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="row" style={{height:"20x",lineHeight:"20px"}}>
                            {brand_name.length<=0 ? renderSubscriberAmount() : renderBrandName()}
                        </div>
                    </div>
                </div>
                <div className="float-start" style={{height:"50px"}}>
                    <div className="align-middle d-flex justify-content-center h-100">
                        <div style={{height:"35px",marginTop:"7.5px"}}>
                            {renderButton()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

