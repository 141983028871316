import React from "react";


export default function NotFoundPage() {

    return (
        <div  className="align-middle align-items-center">
            <div className="fs-3">
                404:Not Found Page
            </div>
        </div>
    )
}