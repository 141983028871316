import React from "react";
import {api} from "../../api/base";
import {func} from "prop-types";
import {IsHttpsURL, MakeStaticURL} from "./LittleFunc";
import {defaultPoster} from "../Page/Studio/CommonComponents/Form";

class ratingParam{
    data:{};
    callback_afterSuccessRating:()=>{};
    callback_closeEvaluationBox:()=>{}
}

export class RatingComponent extends React.Component{

    constructor(props:ratingParam){
        super(props);
        this.state={
            data:props.data,
            rating:{},
            poster:function (){
               if (props.data === null || props.data === undefined) {
                   return defaultPoster
               }else{
                   return props.data.poster === null || props.data.poster === undefined ? defaultPoster : props.data.poster
               }
            }()
        }
    }
    evaluationItems = [
        "演员评分","导演评分","剧情评分","特效评分","音乐评分"
    ];
    submit(){
        let data = this.state.rating;
        //查阅一下有没有缺省，有就自动赋值
        this.evaluationItems.forEach((value, index)=>{
            if (data.hasOwnProperty(value)===false){
                data[value] = 0
            }
        });
        api.Rating({id: this.state.data.id, rating: data}, (response) => {
            if (typeof (this.props.callback_afterSuccessRating) !== "function") {
                return
            }
            this.props.callback_afterSuccessRating(response.score, response.evaluation);
        }, (msg) => {
            window.alert(msg["error_message"]);
            this.props.callback_closeEvaluationBox()
        });

    }
    setScore(score,title){
        let data = this.state.rating;
        data[title] = score;
        this.setState({
            rating:data,
        })
    }

    render(){
        return (
            <div>
                <div className="grade-wrap pf">
                    <span className="grade-btn-close cp pa" title="关闭"  onClick={()=>{this.props.callback_closeEvaluationBox()} }/>
                    <div className="grade-inside float-start w-100" style={{height:"240px"}}>
                        <div className="grade-img" style={{width:"160px"}}>
                            <img src={IsHttpsURL(this.state.poster,(url)=>MakeStaticURL(url))} style={{width:"140px",height:"196px"}} alt={this.state.data.title}/>
                        </div>
                        <div className="grade-star">
                            <h2 style={{height:"auto",fontSize:"17px",marginBottom:"10px"}}>{this.state.data.title}</h2>
                            <ul style={{margin:0,padding:0}}>
                                {this.evaluationItems.map( item=>(
                                    <EvaluationItemComponent title={item} setScore={this.setScore.bind(this)}/>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <a onClick={()=>this.submit()} className="grade-btn">提交</a>
                </div>
                <div className="grade-layer pf"/>
            </div>
        )
    }
}

class EvaluationItemComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            score:0,
            title:props.title,

        }
    }
    renderItems(){

        let d = [];
        let data=[1,2,3,4,5];


        data.forEach( (value, index) =>{

            d.push(
                <i className={`${value <= this.state.score ? "bi-star-fill text-warning" : "bi-star "} bi`} onClick={()=>{
                    if (this.state.score === value){
                        this.props.setScore(0,0);
                        this.setState({
                            score:0,
                        })
                    }else{
                        this.props.setScore(value,this.state.title);
                        this.setState({
                            score:value,
                        })
                    }
                }}
                />
            )
        });

        return d

    }

    render() {
        return (
            <li>
                <em style={{fontSize:"14px",lineHeight:"30px"}} className="text-center">{`${this.state.title}`}</em>
                <div className="star-point " style={{fontSize:"17px"}}>
                    <span id="hint" style={{fontSize:"17px",fontWeight:"bold"}}>{`${this.state.score}分`}</span>
                    { this.renderItems() }
                </div>
            </li>
        );
    }

}

export function ShowRatingComponent({score=0.0,evaluation=0.0}){

    let renderStar = (score)=>{

        let d = [];

        for(let i=0;i<5;i++){
            d.push(
                i+1 <= score ? <i className="bi bi-star-fill text-warning" /> : i+1 <= score + 0.5 ? <i className="bi bi-star-half text-warning"  /> :<i className="bi bi-star-fill" style={{color:"#eee"}} />
            )
        }
        return d
    };

    return (
        <div className="score">
            <span className="mark" style={{background:"none",margin:0,padding:0}}>{score.toFixed(1)}</span>
            <div className="text-warning w-auto float-start ms-2">
                {renderStar(score)}
            </div>
            <a className="ms-2 text-primary fw-bold text-decoration-none bg-white" style={{fontSize:"15px"}}>{`${evaluation}人评价`}</a>
        </div>
    )

}