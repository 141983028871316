import * as React from "react";
import {studioAPI} from "../../../../../api/base";
import {Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {DateSelectorComponent} from "../../CommonComponents/Form";
import {timestampToTime} from "../../../../CommonComponents/LittleFunc";

export class ProgramAnalysisComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            data:null,
            startTime:null,
            endTime:null,
            items:[
                {dataKey:"subscribe",name:"订阅人数",color:"#ff5555"},
                {dataKey:"view",name:"展示次数",color:"#000"},
                {dataKey:"click",name:"点击次数",color:"#0099ff"}
            ],
            id:this.props.program.id
        }
    }
    componentDidMount(): void {
        if (this.state.data === null){
            this.getData()
        }
    }



    getData(){
        let api = new studioAPI();
        api.getStatistics({
            "id":this.state.id,
            "start_time":this.state.startTime,
            "end_time":this.state.endTime,
            "item_type":"program",
        },(response)=>{

            if (response !== null){

                let m ={};
                response.forEach((it,index)=>{
                    let date = timestampToTime(it.time);
                    if (m.hasOwnProperty(date)===true){
                        let i = m[date];
                        if (i.hasOwnProperty(it.event)===true){
                            i[it.event]++
                        }else{
                            i[it.event] = 1
                        }
                    }else{
                        let i = {};
                        i[it.event] = 1;
                        m[date] = i
                    }
                });
                let data =[];
                for (let key in m){
                    data.push(
                        {date:key,...m[key]}
                    )

                }
                this.setState({
                    data:data
                })
            }

        },(msg)=>{window.alert(msg)})
    }

    render(){

        if (this.state.data===null){return <div />}

        return <div className="console_content">
            <div className="row g-3">
                <DateSelectorComponent callback_onChange={(s,e)=>{
                    this.setState({
                        startTime:s,
                        endTime:e,
                    })
                }} outline_className={"col-sm-7 d-inline-block"} />
                <div className="mb-3">
                    <button className="btn btn-primary" onClick={()=>{
                        this.getData()
                    }}>Update</button>
                </div>
            </div>
            <div style={{display:"block",width:"100%",marginTop:"10px"}}>
                <LineChart width={800} height={300} data={this.state.data}>
                    <XAxis dataKey="date"/>
                    <YAxis/>
                    <Tooltip />
                    <Legend />
                    {this.state.items.map(
                        i=>(
                            <Line type="monotone" dataKey={i.dataKey}  name={i.name} stroke={i.color===undefined ? "#333" : i.color} />
                        )
                    )}
                </LineChart>
            </div>
        </div>
    }
}