import React from "react";
import {AddMobilePhone, ChangePasswordComponent} from "../CommonComponents/Form";
import {studioAPI} from "../../../../api/base";


export function SecurityComponent({callback_changePassword}){


    let [newPassword,setPassword] = React.useState(null);
    let [verifyCode,setVerifyCode] = React.useState(null);
    let [mobilePhone,setMobilePhone] = React.useState(null);

    React.useEffect(()=>{
        function init() {
            let  a= new studioAPI();
            a.getSecurityStrategy({},(response)=>{
                setMobilePhone(response.mobile_phone)//)
            },(msg)=>{
                window.alert(msg["error_message"])
            })
        }
        init()
    },[]);

    let changePassword = ()=>{
        callback_changePassword(newPassword,verifyCode)
    };

    if (mobilePhone===null || mobilePhone === undefined || mobilePhone.length<=0){

        return (
            <div className="mt-2">
                <div className="fs-5 fw-bold mb-3">添加安全手机 / Add a secure phone</div>
                <AddMobilePhone />
            </div>
        )
    }

    return (
        <div className="mt-2" >

            <div className="fs-5 fw-bold mb-3">修改密码 / Change Password</div>
            <ChangePasswordComponent callback_onChange={(n,v)=>{
                setPassword(n);
                setVerifyCode(v);
            }}/>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={()=>changePassword()}>Change Password</button>
            </div>
        </div>
    )
}