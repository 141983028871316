import * as React from "react";
import {func, object} from "prop-types";
import {api, studioAPI} from "../../../../api/base";
import * as qiniu from "qiniu-js";
import {InputTags} from "react-bootstrap-tagsinput";

export const defaultThumb = "https://cdn02.emicdn.com/default-thumb.jpeg";
export const defaultPoster = "https://cdn02.emicdn.com/default-poster.jpeg";


export class InputFieldComponent extends React.Component{

    disable: boolean;
    label: string;
    showPrompt: boolean;
    promptText: string;



    constructor(props){
        super(props);
        this.state={
            showPrompt:props.showPrompt,
            label:props.label,
            disable:props.disable,
            promptText:props.promptText
        };
    }
    componentWillReceiveProps(nextProps, nextContext): void {

        this.setState({
            label:nextProps.label,
            showPrompt:nextProps.showPrompt,
            disable:nextProps.disable,
            promptText:nextProps.promptText
        })
    }

    onChange(event){
        if (this.props.callback_onChange === null || undefined){
            return
        }
        this.props.callback_onChange(event.target.value);
    }
    render(){
        return (
            <div className="mb-3">
                <label className="form-label">{this.state.label}</label>
                <input type="text" className="form-control"
                       onChange={(event)=>this.onChange(event)}
                       disabled={this.state.disable}
                       style={this.state.showPrompt === true ?{border:"1px solid #ff3333"}: null}
                       {...this.props}
                       onClick={(e)=>{

                                if(this.props.placeholder!== undefined && this.props.placeholder.length > 1){
                                     e.target.value = this.props.placeholder
                                }

                       }}
                />
                { this.state.showPrompt === true ?
                    <div className="form-text " style={{color:"#ff3333"}}>{this.state.promptText}</div> : <div /> }
            </div>
        )
    }
}

export class TextAreaComponent extends React.Component{

    callback_onChange:function;
    promptText:string;
    showPrompt:boolean;
    label:string;
    disable:boolean;

    constructor(props){
        super(props);
        this.state={
            label:props.label,
            showPrompt:props.showPrompt,
            promptText:props.promptText,
        }
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {

        this.setState({
            label:nextProps.label,
            showPrompt:nextProps.showPrompt,
            disable:nextProps.disable,
            promptText:nextProps.promptText
        })
    }

    render(){
        return (
            <div className="mb-3">
                <label className="form-label">{this.state.label}</label>
                <textarea className="form-control"
                          rows="5"
                          disabled={this.state.disable}
                          onChange={(event)=>{
                              this.props.callback_onChange(event.target.value)
                          }}
                          style={this.state.showPrompt === true ? {border:"1px solid #ff3333"} : null}
                          {...this.props}
                    onClick={(e)=>{

                        let p = this.props.placeholder === undefined ? null : this.props.placeholder;
                        if(p!=null){
                            e.target.value = p
                        }
                    }}
                />
                { this.state.showPrompt === true ?
                    <div className="form-text" style={{color:"#ff3333"}}>{this.state.promptText}</div> : <div /> }
            </div>
        )
    }
}

export class JNumberComponent extends React.Component{
    label: string;
    disable:boolean;

    constructor(props){
        super(props);
        this.state={
            label:props.label,
            disable:props.disable,
            isChecked:false
        }
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            label:nextProps.label,
            disable:nextProps.disable,
        })
    }

    render_input(){
        if (this.state.isChecked === true){
            return (
                <div >
                <div className={"input-group"}>
                    <span className="input-group-text" style={{marginLeft:"5px"}}>定价</span>
                    <input type="number" className="form-control text-center"
                           defaultValue={0.00}
                        onChange={(event)=>{
                            this.props.callback_onChange(true,event.target.value);
                        }}
                    />
                    <span className="input-group-text">(个)金币</span>
                </div>
                    <div className="form-text" style={{color:"#ff5555"}}>10金币等于1美元</div>
                </div>
            )
        }
        return <div/>
    }
    handlerChecked(event){
        this.props.callback_onChange(event.target.checked,0);
        this.setState({
            isChecked:event.target.checked
        });
    }
    render(){
        return (

            <div className="input-group mb-3" style={{width:"auto"}}>
                <div className="form-check form-switch " style={{background:"#fff",border:"none"}}>
                    <input className="form-check-input " type="checkbox" style={{marginRight:"5px",marginTop:"3px"}}
                        onChange={(e)=>this.handlerChecked(e)}
                    />
                    <label className={"form-check-label"} >{this.state.label}</label>
                </div>
                {this.render_input()}
            </div>

        )
    }
}

export const SelectComponent = React.forwardRef(({label,items,callback_onChange,defaultValue=null},ref)=>{

    let [selectedId,setSelectedId] = React.useState(defaultValue);
    let inputRef = React.createRef();

    items = items === undefined ? [] : items;
    items = items === null ? [] : items;


    React.useImperativeHandle(ref,()=>({
        resetData(){
            console.log(defaultValue);
            inputRef.current.value = defaultValue;
            setSelectedId(defaultValue);
        }
    }));

    return (
        <div className="mb-3">
            <label className="form-label">{label}</label>
            <select className="form-select" onChange={(e)=>{
                let val = e.target.value;
                if (val === -1 || val==="-1"){
                    setSelectedId(null);
                    callback_onChange(null);
                    return
                }
                if (val !== null && val!=="null"){
                    callback_onChange(e.target.value);
                }
                setSelectedId(e.target.value);
            }} value={selectedId} ref={inputRef} >

                <option value={-1} >请选择选项</option>
                {items.map( item=>(
                    <option value={item.id} selected={selectedId === item.id}>{item.name}</option>
                ))}
            </select>
        </div>
    )
});

export function UploadPosterComponent({label,isImportant=false,callback_afterUploadSuccess=(filename:string)=>{},maxAmount=6}) {


    let [imgs,setImgs] = React.useState([]);
    let [uploadedFile,setUploadedFile] = React.useState([]);

    let selectFile = (files)=>{
        let d = [...imgs];
        for(let i=0;i<files.length;i++){
            d.push(files[i]);
        }

        setImgs(d);
    };

    function UploadItemComponent({file,filename,pageToken,callback_afterSuccess=(filename:string)=>{},needUpload=true}) {

        let [percent,setPercent] = React.useState(0);
        let [showPercent,setShowPercent] = React.useState(false);
        let [error,setError] = React.useState(null);
        let [isSuccess,setSuccess] = React.useState(false);
        let [localImg,setLocalImg] = React.useState(null);




        let subscription = null;

        React.useEffect(()=>{
            function init() {

                let img = new FileReader();
                img.readAsDataURL(file);
                img.onload = (e) => {
                    setLocalImg(e.target.result);
                    if (needUpload === true) {
                        autoUpload(filename, file)
                    } else {
                        setSuccess(true);
                    }
                };
            }
            init()
        },[]);

        let uploadFileToQiniu = (file,key,uploadToken)=>{

            const ob = {
                next:(res)=>{
                    setPercent(res.total.percent);
                    setShowPercent(true);
                    setSuccess(false);
                },
                error(err){
                    if (err.code === 579){
                        return
                    }
                    return setError(err.message);
                    //window.alert(err.message);
                },
                complete(res){
                    setSuccess(true);
                    setShowPercent(false);
                    setPercent(0);
                    callback_afterSuccess(key);
                }
            };


            let q = qiniu.upload(file,key,uploadToken,null,null);
            subscription = q.subscribe(ob);
            setPercent(0);
            setShowPercent(false)

        };

        let autoUpload = (filename:string,file)=>{

            setShowPercent(true);
            let ext = filename.split(".").pop();
            let a = new studioAPI();
            a.getUploadToken({
                file_name:filename,
                file_size:file.size,
                ext:ext,
                file_type: file.type,
                page_token:pageToken,
            },(response)=>{

                let filename = response.image_filename;
                let token = response.token;
                uploadFileToQiniu(file,filename,token)
            },(msg)=>{
                console.log(msg["error_message"]);
                setError("上传失败，出现网络错误!")
            },true)
        };

        return (
            <div className="col-2 ps-0 mb-3" style={{height:"120px",lineHeight:"120px"}}>
                <div className="w-100 h-100">
                    <img src={localImg} className="w-100 h-100 img-thumbnail object-fit-fill" alt={filename} style={{borderColor:isSuccess===true ? "#00FF00" : ""}}/>
                    <div className={`position-relative w-100 h-100 bg-dark bg-opacity-75 ${showPercent === false ? "hide" : ""}`} style={{top:"-120px"}}>
                        <div className="progress position-relative" style={{top:"45%"}}>
                            <div className="progress-bar" style={{width:`${percent.toFixed(1)}px`}} />
                        </div>
                    </div>
                    <div className={`position-relative w-100 h-100 bg-danger bg-opacity-75 ${error === null ? "hide" : ""}`} style={{top:"-120px"}}>
                        <div className="w-100 h-auto text-center text-white overflow-hidden text-break position-relative fw-bold" style={{top:"40%",fontSize:"14px"}}>
                            <span className="d-block h-auto " style={{lineHeight:"15px"}}>{error}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div >
            <label className="form-label">{label}<span className={`text-danger ${isImportant===true ? "" : "hide"}`}>*</span><span style={{fontSize:"12px"}}>{`(最多能上传${maxAmount}个图片)`}</span></label>
            <div className="container">

                <div className="row">

                    {imgs.map(it=>(
                        <UploadItemComponent  file={it} filename={it.name} pageToken={""}
                                              callback_afterSuccess={(filename)=>{
                                                  let d = [...uploadedFile];
                                                  d.push(it.name);
                                                  setUploadedFile(d);
                                                  callback_afterUploadSuccess(filename);
                                              }}
                                              needUpload={uploadedFile.indexOf(it.name) <= -1}

                        />

                    ))}
                    {imgs.length >= maxAmount ? "" :
                    <div className="col-2 ps-0" style={{height:"120px",lineHeight:"120px"}}>
                        <div className="w-100 h-100 align-middle text-center border-1 "
                             style={{background: "#eeeeee"}}>
                            <i className="bi bi-plus-lg fs-1" />
                        </div>
                        <input type="file" className="opacity-0 position-relative w-100 h-100"
                               style={{zIndex:3,top:"-120px",left:"0",cursor:"pointer"}} onChange={(e)=>{
                                   selectFile(e.target.files)
                        }}/>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}









export function AddTagsComponent({defaultValue,callback_addTag,callback_removeTag}){


    defaultValue = defaultValue === undefined || defaultValue === null? [] : defaultValue;
    let d = [];
    if (defaultValue.length >= 1){
        if (typeof(defaultValue[0])==="object"){
            for(let i=0;i<defaultValue.length;i++){
                d.push(defaultValue[i].name)
            }
        }else{
            d = defaultValue
        }
    }
    let [tags,setTags]= React.useState(d);
    let [inputContent,setInputContent] = React.useState(null);
    let add = callback_addTag === undefined ? ()=>{} : callback_addTag;
    let removeTag = callback_removeTag === undefined ? ()=>{} :callback_removeTag;
        return (
            <div className="mb-3">
                <label className="form-label">Add Tags</label>
                <div style={{height:"auto",lineHeight:"25px"}}>
                    {tags.map(item=>(
                        <span className="badge rounded-pill text-bg-primary"
                              style={{fontSize:"18px",marginRight:"2px",cursor:"pointer"}}
                              onDoubleClick={()=>{
                                  let t = [];
                                  tags.forEach(it=>{
                                      if(it !== item){
                                          t.push(it)
                                      }
                                  });
                                  setTags(t);
                                  removeTag(item);
                              }}
                        >{item}</span>
                    ))}
                </div>
                <div className="input-group" style={{marginTop:"8px"}}>
                    <input type="text" className="form-control" value={inputContent} onChange={(e)=>{
                        setInputContent(e.target.value);
                    }}/>
                    <button className="btn btn-outline-secondary" type="button"
                                onClick={()=>{
                                    let t = [...tags,inputContent];
                                    setTags(t);
                                    add(inputContent);
                                    setInputContent("");
                                }}
                    >Add Tag</button>
                </div>
                <span className={"form-text"} style={{color:"#ff5555"}}>Double click the label to delete it</span>
            </div>
        )

}

export const DateSelectorComponent = React.forwardRef(({outline_className,
                                                           callback_onChange,
                                                           callback_onError=()=>{}},ref)=>{

    let [startTime,setStartTime] = React.useState(null);
    let [endTime,setEndTime] = React.useState(null);
    let startTimeRef= React.createRef();
    let endTimeRef=React.createRef();

    let sendData = ()=>{
        callback_onChange(startTime,endTime)
    };
    let dateSplit=(s)=>{

        if (typeof(s) !== "string"){return }
        let d = s.split("-");
        let year = d[0];
        let month = d[1];
        let day = d[2];
        return new Date(year,month,day)
    };
    React.useEffect(
        ()=>{
            sendData();
            let s = dateSplit(startTime);
            let e = dateSplit(endTime);
            if (e <= s){return callback_onError("结束时间小于开始时间!")}
        }
        ,[endTime,startTime]);

    React.useImperativeHandle(ref,()=>({

        resetData(){
            startTimeRef.current.valueAsDate = null;
            endTimeRef.current.valueAsDate = null;
        }
    }));


    return (
        <div className={outline_className===undefined ? "col-sm-7 d-inline-block" : outline_className}>
            <label className="form-label">时间区间 / Date</label>
            <div className="d-block">
                <div className="col-sm-5 d-inline-block">
                    <input type="date" className="form-control" onChange={(e)=>{
                        setStartTime(e.target.value);
                    }} ref={startTimeRef}/>
                </div>
                <div className="col-sm-1 d-inline-block text-center">
                    <span>To</span>
                </div>
                <div className="col-sm-5 d-inline-block">
                    <input type="date" className="form-control" onChange={(e)=>{
                        setEndTime(e.target.value);
                    }} ref={endTimeRef}/>
                </div>
            </div>
        </div>
    )
});

export function PaginationComponent({currentPage,maxPage,callback_switch}) {

    let [currentNumber,setCurrentNumber] = React.useState(currentPage);


    React.useEffect(()=>{
        setCurrentNumber(currentPage)
    },[currentPage]);

    let renderItems=()=>{

        let startNumber = currentNumber < 3 ? 1 : currentNumber-2;
        let time = 5;
        if (maxPage > 5){
            //set last page
            if (startNumber + 5 >= maxPage && currentNumber > 3){
                startNumber = maxPage-4;
            }
        }else if(maxPage < 5){
            time = maxPage;
            startNumber = 1;
        }

        //console.log(`${currentNumber}-${startNumber}-${time}-${maxPage}`);
        let it =[];
        for(let i=0;i<time;i++){
            it.push(
                <li className={currentNumber===startNumber ? "page-item active" :"page-item" }  id={startNumber} onClick={(e)=>{
                    let li = parseInt(e.currentTarget.id);
                    setCurrentNumber(li);
                    callback_switch(li);
                }}>
                    <a className="page-link">{startNumber}</a>
                </li>
            );
            startNumber=startNumber + 1
        }
        return it
    };

    return(
        <div>
            <ul className="pagination justify-content-center">
                <li className={currentNumber<=1 ? "page-item disabled" : "page-item"} onClick={()=>{
                    if (currentNumber <=1){return }
                    let n = currentNumber -1;
                    callback_switch(n);
                    setCurrentNumber(n);
                }}>
                    <a className="page-link">
                        <span>&laquo;</span>
                    </a>
                </li>
                {renderItems()}
                <li className={currentNumber === maxPage ? "page-item disabled" : "page-item"} onClick={()=>{
                    if (currentNumber === maxPage){return }
                    let n = currentNumber +1;
                    callback_switch(n);
                    setCurrentNumber(n);
                }}>
                    <a className="page-link">
                        <span>&raquo;</span>
                    </a>
                </li>
            </ul>
        </div>
    )
}

export function ChangePasswordComponent({mobile_phone,pageToken="",callback_onChange}) {


    let [sent,setSent] = React.useState(false);
    let [endingNumber,setEndingNumber] = React.useState("");

    let [vcode,setVCode] = React.useState(null);
    let [np,setNP] = React.useState(null);

    let sendCode = ()=>{
        if (mobile_phone.length<=0){
            window.alert("请输入手机号码!");
            return
        }

        let a = new studioAPI();
        a.smsVerification(
            {"mobile_phone":mobile_phone,page_token:pageToken},
            (response)=>{
                setEndingNumber(response.end_number);
                setSent(true);
            }
        )
    };

    React.useEffect(()=>{
        if(np!==null && vcode !==null && sent === true){
            callback_onChange(np,vcode)
        }
    },[vcode,np]);

    return (
        <div >
            <div className="mb-3">
                <label className="form-label">New password</label>
                <input type="password" className="form-control" placeholder={"Please input the new password"}
                    onChange={(e)=>{setNP(e.target.value)}}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">SMS verification</label>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder={"Please input the verify code on sms"}
                        onChange={(e)=>{setVCode(e.target.value)}}
                    />
                    <button className="btn btn-primary" onClick={()=>sendCode()}>Send Code</button>
                </div>

                {sent === true ?
                    <div className="form-text">`The verification code has been sent to the phone with the ending number
                        ${endingNumber}`</div> : <div/>
                }
            </div>
        </div>
    )
}

export function AddTagsComponent02({callback_addTag,callback_removeTag,defaultValue = []}) {

   let [tags,setTags] = React.useState(defaultValue);

   let onOpera = (newTags)=>{
       let arr1 = tags;
       let arr2 = newTags;

       if (arr2.length < arr1.length){
            let removeTag = arr1.filter(item => !new Set(arr2).has(item));
            if (removeTag.length >= 1){
                removeTag.map(item=>{
                    callback_removeTag(item)
                });
            }
       }else{
           let addTag = arr2.filter(item => !new Set(arr1).has(item));
           if (addTag.length >= 1){
                addTag.map(item=>{
                    callback_addTag(item)
                })
           }
       }
       setTags(newTags)
   };
    return (
        <div className="mb-3">
            <div className='input-group'>
                <InputTags values={tags} onTags={(value) =>{onOpera(value.values)}} placeholder={"please input the tag and click enter key"}/>
                <button
                    className='btn btn-outline-secondary'
                    type='button'
                    data-testid='button-clearAll'
                    disabled={tags.length<=0}
                    onClick={() => {
                        callback_removeTag(tags);
                        setTags([])
                    }}
                >
                    Delete all
                </button>
            </div>
        </div>
    )
}


export function AddMobilePhone({pageToken=""}) {


    let [mobilePhone,setMobilePhone] = React.useState(null);
    let [captcha,setCaptcha] = React.useState(null);
    let [endingNumber,setEndingNumber] = React.useState(null);
    let [sent,setSent] = React.useState(false);
    let [country,setCountry] = React.useState(null);
    let [countryList,setCountryList] = React.useState([]);


    let sendCode = ()=>{
        if (mobilePhone.length<=0){
            window.alert("请输入手机号码!");
            return
        }
        if (country === null){
            window.alert("请选择手机所在地区!");
            return;
        }

        let a = new studioAPI();
        a.smsVerification(
            {
                mobile_phone: mobilePhone,
                country_code: country,
                page_token: pageToken
            },
            (response) => {
                setEndingNumber(response.ending_number);
                setSent(true);
            },
            (msg) => {
                window.alert(msg["error_message"]);
            }
        )
    };
    let AddSecurePhone = ()=>{
        let a = new studioAPI();
        a.addSecurePhone({
            mobile_phone:mobilePhone,
            code:captcha,
            country_code:country,
            page_token:pageToken
        },(response)=>{
            window.alert("添加成功!")
        },()=>{});
    };

    React.useEffect(()=>{
        function init() {
            let a = new studioAPI();
            a.getSupportCountry({page_token:pageToken},(response)=>{
                let data=[];
                let d = response===null ? [] : response;
                d.forEach((it,index)=>{
                    data.push(
                        {name:it.name,id:it.code}
                    )
                });
                setCountryList(data)},(msg)=>{console.log(msg["error_message"])})
        }
        init()
    },[]);

    return (
        <div >
            <div className="mb-3">

                <SelectComponent items={countryList} label={"手机号码所在地区 / Mobile Phone Location"} callback_onChange={(e)=>{setCountry(e)}} />
            </div>
            <div className="mb-3">
                <label className="form-label">Mobile Phone</label>
                <input type="text" className="form-control" placeholder={"Please input the mobile phone"}
                       onChange={(e)=>{setMobilePhone(e.target.value)}}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">SMS verification</label>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder={"Please input the verify code on sms"}
                           onChange={(e)=>{setCaptcha(e.target.value)}}
                    />
                    <button className="btn btn-primary" onClick={()=>sendCode()} disabled={sent}>Send Code</button>
                </div>
                {sent === true ?
                    <div className="form-text">`The verification code has been sent to the phone with the ending number
                        ${endingNumber}`</div> : <div/>
                }
            </div>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={()=>AddSecurePhone()}>添加安全手机</button>
            </div>
        </div>
    )
}