import * as React from "react";
import {DateSelectorComponent, PaginationComponent, SelectComponent} from "../CommonComponents/Form";
import {studioAPI} from "../../../../api/base";
import {withRouter} from "../../../CommonComponents/WithRouter";
import {WaitingComponent} from "../../../CommonComponents/Waiting";


class AdListComponent extends React.Component{
    

    constructor(props){
        super(props);
        this.state={
            data:null,
            param:null,
            page:1,
            pageAmount:1,
            pageToken:this.props.router.pageToken.to(),
            total:0,
            initState:true,
        }
    }
    componentDidMount(): void {
        if(this.state.data===null){
            this.getTotal();
        }
    }
    getTotal(param = this.state.param){
        let a = new studioAPI();
        a.getAdvertisingListTotal(
            {...param,"page_token":this.state.pageToken},
            (response)=>{
                let pageAmount = response.page_amount;
                let current_page = 1;
                let total = response.total === undefined || response.total === null ? 0 : response.total;
                if (total > 0){
                    this.getData(1,this.state.param);
                }
                this.setState({
                    pageAmount:pageAmount,
                    page:current_page,
                    total:total,
                    data:null,
                    initState:false,
                });
                return null
            },
            (msg)=>{
                console.log(msg["error_message"]);
                this.setState({
                    pageAmount:0,
                    page:1,
                    total:0,
                    data:null,
                    initState:false,
                })
            })
    }
    getData(page,param){
        let a = new studioAPI();
        a.getAdvertisingList(
            {...param,"page":page},
            (response)=>{this.setState({data:response===null?[]:response,initState:false})},
            (msg)=>{console.log(msg["error_message"])}
        )
    }

    create(){
        let form = this.props.router.location.pathname;
        this.props.router.navigate("/studio/advertising/create",{replace:true,state:{form:form}});
    }
    deleteItem(id){

        let a = new studioAPI();
        a.deleteAds(
            {"id":id,"page_token":this.state.pageToken},
            (response)=>{
                let total =  this.state.total;
                let newData = [];
                if (total-1 <=0){
                    total = 0
                }else{
                    total = total-1 ;
                    if (this.state.data != null){
                        this.state.data.forEach((it,index)=>{
                            if (it.id !== id){
                                newData.push(it)
                            }
                        })
                    }
                    //if the data is < 0,
                    if (newData.length <= 0){
                        if (this.state.page > 1){
                            this.getData(this.state.page - 1,this.state.param)
                        }
                    }
                }
                this.setState({
                    total:total,
                    data:newData,
                })
            },
            (msg)=>{console.log(msg["error_message"])}
        )
    }

    render(){
        if (this.state.initState === true){
            return (
                <div className="studioWrap">
                    <div className="console">
                        <WaitingComponent setBorder={false}/>
                    </div>
                </div>
            )
        }

        return (<div className="studioWrap">
            <div className="console">
                <div className="console_inside">
                    <div >
                        <div className="col-10 d-inline-block">
                            <h4>Advertising List</h4>
                        </div>
                        <div className="col-2 d-inline-block justify-content-center text-center " >
                            <button className="btn btn-outline-danger" style={{lineHeight:"25px"}} onClick={()=>this.create()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                          d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"/>
                                </svg>
                                &nbsp;Create Ad</button>
                        </div>
                    </div>
                    <QueryParameterComponent callback_search={(param)=>{
                        this.setState({param:param});
                        this.getTotal(param)
                    }}/>
                    <TableComponent data={this.state.data} callback_edit={(id)=>{
                        this.props.router.navigate(`/studio/advertising/${id}`,{replace:true})
                    }} callback_delete={(id)=>this.deleteItem(id)}/>
                    <PaginationComponent callback_switch={(page)=>{
                        this.setState({page:page})
                    }} currentPage={this.state.page} maxPage={this.state.pageAmount}/>
                </div>
            </div>
        </div> )
    }
}

export const AdListPage = withRouter(AdListComponent);

function QueryParameterComponent({callback_search,pageToken}) {


    let [startTime,setStartTime] = React.useState();
    let [endTime,setEndTime] = React.useState();
    let [advertisingPosition,setAdvertisingPosition] = React.useState();
    let [adPositionList,setAdPositionList] = React.useState();
    let initState = true;

    let reset = ()=> {
        setStartTime(0);
        setEndTime(0);
        setAdvertisingPosition(null);
    };
    React.useEffect(
        ()=>{
            initState = false;
            let a= new studioAPI();
            a.getAdvertisingPosition({page_token:pageToken, platform:"pc"},
                (response)=>{
                    setAdPositionList(response)
                },
                (msg)=>{
                    console.log(msg["error_message"])
            });
        },
        [initState===true]
    );


    return (
        <div className="container mb-3">
            <div className="row">
                <DateSelectorComponent callback_onChange={(s, e) => {
                    setStartTime(s);
                    setEndTime(e);
                }}
                                       outline_className={"col-6 "}
                />
                <div className="col-6">
                    <SelectComponent label={"Advertising Position"} callback_onChange={(position) => {
                        if (position === null || position === "null"){
                            setAdvertisingPosition(null)
                        }
                        setAdvertisingPosition(position)
                    }} items={adPositionList}/>
                </div>
            </div>
            <div className="row">

                <div className="col-12" style={{margin:"0"}}>
                    <button className="btn btn-primary"
                            onClick={() => {
                                callback_search({
                                    start_time: startTime,
                                    end_time: endTime,
                                    spot_id:advertisingPosition,
                                    page: 1
                                })
                            }}
                    >Search</button>
                </div>
            </div>
        </div>
    )
}

function TableComponent({data,callback_edit,callback_delete}) {

    data = data === undefined ? [] : data;
    data = data === null ? [] :data;

    let [renderData,setRenderData] = React.useState(data);


    React.useEffect(()=>{
        setRenderData(data)
    },[data]);

    return (
        <table className="table">
            <thead >
                <tr>
                    <th>广告名字</th>
                    <th>广告状态</th>
                    <th>广告类型</th>
                    <th>广告位置</th>
                    <th>操作</th>
                </tr>
            </thead>
            <tbody>
                {renderData.map(item=>(
                    <RowComponent data={item} callback_edit={()=>callback_edit(item.id)} callback_delete={()=>{callback_delete(item.id)}}/>
                ))}
            </tbody>
        </table>
    )
}


function RowComponent({data,callback_edit,callback_delete}) {


    const chooseColor = (s)=>{

        switch (s) {
            case 1:
                return "badge bg-success";
            case 0:
                return "badge bg-dark";
            case 3:
                return "badge bg-warning";
            default:
                return "badge bg-light";
        }
    };

    const renderStatus = (state,serviceState)=>{
        let stateText = "";
        let sstateText = "";

        switch (state) {
            case 1:
                stateText="正常";
                break;
            case 3:
                stateText = "受限";
                break;
            case 0:
                stateText = "已删除";
                break;
        }
        switch (serviceState) {
            case 1:
                sstateText="投放中";
                break;
            case 0:
                sstateText = "未投放";
                break;
            default:
                sstateText = "未知";
                break;
        }

       return (
        <span>
            <span className={chooseColor(state)} >{stateText}</span>
            <span className={chooseColor(serviceState)}>{sstateText}</span>
        </span>)
    };

    return(
        <tr >
            <th>{data.name}</th>
            <th>{renderStatus(data.state,data.serving_status)}</th>
            <th>{data.type.name}</th>
            <th>{data.ad_spot.name}</th>
            <th>
                <button className="btn btn-primary" onClick={()=>{callback_edit(data.id)}}>Edit</button>
                <button className="btn btn-danger m-lg-1" onClick={()=>{callback_delete(data.id)}}>Delete</button>
            </th>
        </tr>
    )
}