import React from "react";
import {CartesianGrid, LineChart, XAxis, YAxis,Line,Legend,Tooltip} from "recharts";
import {api, studioAPI} from "../../../api/base";


const meterItem=[
    {title:"",subTitle:"",id:1,amount:50}
];

export class DashBoardComponent extends React.Component{
    viewers_amount: number;
    subscriber_amount: number;
    message_amount: number;
    coins_amount: number;
    like_amount: number;


    constructor(props){
        super(props);
        this.state={
            selectId:0,
            data:null
        }
    }

    componentDidMount(): void {

        this.setState({
            data:{
                viewers_amount:100,
                subscriber_amount:100,
                message_amount:50,
                coins_amount:10,
                like_amount:10
            },
        })
    }

    render(){

        if (this.state.data=== null){
            return <div />
        }
        return (
            <div className="studioWrap">
                <div className="dashboard">
                    <span className="title">Channel Dashboard</span>
                    <span className="subTitle">Here you can see your channel overview</span>
                    <div className="meter">
                        <div className="meter_items">
                            <div className="meterContent">
                                <span className="title1"><em>{this.state.data.viewers_amount}</em> News Viewers</span>
                                <span className="title2 ">As Of Weekend</span>
                            </div>
                        </div>
                        <div className="meter_items">
                            <div className="meterContent">
                                <span className="title1"><em>{this.state.data.subscriber_amount}</em> News Subscriber</span>
                                <span className="title2">As Of Weekend</span>
                            </div>
                        </div>
                        <div className="meter_items">
                            <div className="meterContent">
                                <span className="title1"><em>{this.state.data.like_amount}</em> News Likes</span>
                                <span className="title2">As Of Weekend</span>
                            </div>
                        </div>
                        <div className="meter_items">
                            <div className="meterContent">
                                <span className="title1">Get <em>{this.state.data.coins_amount}</em> Coins</span>
                                <span className="title2">As Of Weekend</span>
                            </div>
                        </div>
                        <div className="meter_items">
                            <div className="meterContent">
                                <span className="title1"><em>9,999 Thousand</em> News Likes</span>
                                <span className="title2">As Of Weekend</span>
                            </div>
                        </div>
                        <div className="meter_items">
                            <div className="meterContent">
                                <span className="title1"><em>{this.state.data.message_amount}</em> Message</span>
                                <span className="title2">As Of Weekend</span>
                            </div>
                        </div>
                    </div>
                    <div >
                        <ChartsComponent />
                    </div>
                </div>
            </div>
        )
    }
}

class ChartsComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            selectedTime:7*24*86400,
            selectedContent:{title:"Watch Time"},
            data:null
        }
    }
    getData(){
        let a  = new studioAPI();
        a.getOverview(
            {"about":this.state.selectedContent.data_tag,"range":{"start":this.state.selectedTime,"end":0}},
            (data)=>{
                this.setState({
                    data:data
                })
            },
            null
        )
    }
    componentDidMount(): void {
        this.getData()
    }


    render(){
        return (
            <div className="charts" style={{paddingTop:"0px"}}>
                <div className="content">
                    <ChartsNavComponent callback_selected={(data)=>{
                            this.setState({
                                selectedContent:data
                            });

                    }}/>
                    <div className="chartsContent">
                        <div className="inside">
                            <div className="c">
                                <ChartsDropDownComponent callback_selected={(date)=>{
                                    this.setState({
                                        selectedTime:date
                                    });
                                } }/>
                                <div >

                                    {this.state.data === null ? <div /> :
                                    <LineChart width={800} height={300} data={this.state.data}>
                                        <XAxis dataKey="date"/>
                                        <YAxis/>
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="value"  name={this.state.selectedContent.title} stroke="#ff5555" />
                                    </LineChart>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


class ChartsNavComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            data: {
                "watchTime":{"total":100},
                "views":{"total":200},
                "subscribers":{"total":300},
                "coins":{"total":300}
            },
            selectedItems:1,
            items:[
                {"title":"Watch Time","data_tag":"watchTime","unit":"Second","id":1},
                {"title":"Views","data_tag":"views","id":2,"unit":"Time"},
                {"title":"Subscribers","data_tag":"subscribers","id":3,"unit":"People"},
                {"title":"Coins","data_tag":"coins","id":4,"unit":"Pieces"}
            ]
        }
    }
    RenderItems(data){

        if (this.state.selectedItems === data.id){
            return (
                <div className="nav_items nav_items_active" onClick={
                    ()=>{
                        this.HandlerClick(data)
                    }
                }>
                    <span className="title1">{data.title}</span>
                    <span className="title2">{this.state.data[data.data_tag].total}<em>&nbsp;{data.unit}</em></span>
                </div>
            )
        }

        return (
            <div className="nav_items" onClick={
                ()=>{
                    this.HandlerClick(data)
                }
            }>
                <span className="title3">{data.title}</span>
            </div>
        )
    }

    HandlerClick(data){
        this.props.callback_selected(data);
        this.setState({
            selectedItems:data.id
        })
    }
    render(){
        if (this.state.data === null){
            console.log(this.state.data);
            return <div />
        }
        return (
            <div className="nav" onClick={()=>{console.log("Hello")} }>
                {this.state.items.map(
                    item=>(
                        this.RenderItems(item)
                    )
                )}
            </div>
        )
    }
}


class ChartsDropDownComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            show:false,
            items:[
                {"title":"Last 7 Days","date":7*86400},
                {"title":"Last 1 Month","date":30*86400},
                {"title":"Last 3 Month","date":90*86400},
                {"title":"Last 6 Month","date":180*86400},
                {"title":"Last Year","date":365*86400}
            ],
            selectedOption:"Last 7 Days"
        }
    }
    HandlerClick(){
        this.setState({
            show:!this.state.show
        })
    }

    render(){

        return (
            <div className="dropdown"
                 style={{width:"125px",height:"50px",position: "relative",right:"-84%",bottom:"-3%"}}>
                <button className="btn btn-primary dropdown-toggle" onClick={()=>{this.HandlerClick() }}>{this.state.selectedOption}</button>
                <ul className="dropdown-menu" style={{display:this.state.show === true ? "block" : "none"}}>

                    {this.state.items.map(
                        item=>(
                            <li>
                                <a className="dropdown-item"
                                    onClick={()=>{
                                        this.props.callback_selected(item.date);
                                        this.setState({
                                            selectedOption:item.title,
                                            show:!this.state.show,
                                        })
                                    }}
                                >{item.title}</a></li>
                        )
                    )}
                </ul>
            </div>
        )
    }
}


