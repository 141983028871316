import * as React from "react";
import {defaultPoster, defaultThumb} from "../Page/Studio/CommonComponents/Form";
import {newBI} from "../../api/base";
import {IsHttpsURL, MakeStaticURL} from "./LittleFunc";


const imageURL = function (url: string, isPoster: boolean) {

    if (url.length <= 0) {
        return isPoster === true ? defaultPoster : defaultThumb
    }
    return IsHttpsURL(url, MakeStaticURL)
};

export function ImageComponent({src="",style,topic = "video",itemId= "",Gif="",isPoster = false}) {

    let [imageLink,setImageLink] = React.useState(imageURL(src,isPoster));
    let imageRef = React.useRef();
    let [gif,setGif] = React.useState(Gif);

    React.useEffect(()=>{

        if (imageRef === undefined || imageRef === null){
            return null
        }else{
            let ob = new IntersectionObserver((entry)=>{
                let vid = "";
                entry.map(it=> {
                    if (it.intersectionRatio > 0.5){
                        vid = it.target.attributes.getNamedItem("alt").value;
                        let dom = window.document.getElementById(`${"view"}-${vid}`);
                        if (dom === null){
                            window.document.body.appendChild(newBI(topic,"view",vid));
                        }
                    }
                });
                //
            },{threshold:[0.5]});
            ob.observe(imageRef.current);
        }
    });

    React.useEffect(()=>{
        setImageLink(imageURL(src,isPoster));
    },[src]);

    return (
        <img src={imageLink} style={style} alt={itemId} ref={imageRef} />
    )
}

export function DefaultImageComponent({itemId,src,isPoster,style}){

    if (src === undefined || src === null || src.length<=0){
        if (isPoster === true){
            src = defaultPoster;
        }else{
            src = defaultThumb;
        }
    }

    return (
        <img src={src} width={"100%"} height={"100%"} alt={itemId} style={style} />
    )
}