import * as React from "react";
import {withRouter} from "../../../../CommonComponents/WithRouter";
import {studioAPI} from "../../../../../api/base";
import {TabComponent, TitleComponent} from "../../CommonComponents/Tab";
import {ProgramInformationComponent} from "./Info";
import {EpisodesComponent} from "./Episodes";
import {ProgramAnalysisComponent} from "./Analysis";
import {UploadImageComponent} from "../../CommonComponents/UploadImage";
import {WaitingComponent} from "../../../../CommonComponents/Waiting";
import {ErrorNetworkComponent} from "../../../../CommonComponents/Error";



class ProgramDetailComponent extends React.Component{
    program_id: string;
    id = this.props.router.params.program_id;


    constructor(props){
        super(props);

        this.state={
            programId:this.id,
            selectedId:1,
            program:null,
            nav_item:[
                {"label":"Program Information","id":1,"index":true},
                {"label":"Episodes Management","id":2},
                {"label":"Poster Management","id":3},
                {"label":"Analytics","id":4}
            ],
            isErrorState:false,
            initState:true,
        }
    }
    getData(){
        let a = new studioAPI();
        a.getProgramDetail(
            {id:this.state.programId},
            (response)=>{
                this.setState({
                    program:response,
                    initState:false
                })
            },
            (msg)=>{
                this.setState({isErrorState:true,initState:false});
                console.log(msg["error_message"])
            }
        )
    }
    componentDidMount(): void {
        if (this.state.program === null){
            this.getData()
        }

    }
    refresh(){
        this.props.router.navigate("/studio/program",{replace:true})
    }
    renderContent(){

        switch(this.state.selectedId){
            case 1:
                return <ProgramInformationComponent program={this.state.program} {...this.props} callback_refresh={()=>this.refresh()}/>;
            case 2:
                return <EpisodesComponent program={this.state.program} {...this.props} callback_refresh={()=>this.refresh()}/>;
            case 3:
                return <UploadImageComponent itemId={this.state.program.id} itemType={"program"} pageToken={this.props.router.pageToken.to()}/>;
            case 4:
                return <ProgramAnalysisComponent program={this.state.program} {...this.props} callback_refresh={()=>this.refresh()}/>;
        }
    }
    deleteProgram(){

        let a = new studioAPI();
        a.deleteProgram({
            id:this.state.programId,
            page_token:this.state.page_token
        },(response)=>{
            this.props.router.navigate("/studio/program");

        },(message)=>{
            window.alert(message.error_message)

        })
    }



    render(){
        if (this.state.initState === true){
            return (<div className="studioWrap">
                <div className="console" style={{paddingTop:"20px"}}>
                    <WaitingComponent setBorder={false} />
                </div>
            </div>);
        }

        if (this.state.isErrorState === true){
            return (<div className="studioWrap">
                <div className="console" style={{paddingTop:"20px"}}>
                    <ErrorNetworkComponent />
                </div>
            </div>)
        }

        return (
            <div className="studioWrap">
                <div className="console" >
                    <div className="console_inside" >
                        <TitleComponent title={this.state.program.title} callback_onClick={()=>{
                            this.deleteProgram()
                        }} />
                        <TabComponent tab_item={this.state.nav_item} callback_OnSelected={(id)=>{
                            this.setState({
                                selectedId:id
                            })
                        }} />
                        {this.renderContent()}
                    </div>
                </div>
            </div>
        )
    }
}

export const ProgramDetailPage = withRouter(ProgramDetailComponent);









