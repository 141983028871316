import * as React from "react";
import {
    InputFieldComponent,
    JNumberComponent, SelectComponent,
    TextAreaComponent,
    UploadPosterComponent
} from "../CommonComponents/Form";
import { studioAPI} from "../../../../api/base";
import {withRouter} from "../../../CommonComponents/WithRouter";


class CreateProgramComponent extends React.Component{


    render(){
        return (
            <div className="studioWrap">
                <div className="console">
                    <div className="console_inside">
                        <div style={{borderBottom: "1px solid #eee"}}>
                            <h3 className={"display-3"}>Create New Program</h3>
                            <p className={"lead text-end"}>A program that will make you proud forever</p>
                        </div>
                        <ContentComponent {...this.props}/>
                    </div>
                </div>
            </div>
        )
    }
}

class ContentComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            name:null,
            description:null,
            subtitle:null,
            isPaidProgram:false,
            price:0.00,
            posterKey:null,
            category_id:null,
            displayNamePrompt:false,
            displayDescriptionPrompt:false,
            displaySubtitlePrompt:false,
            categoryItems:[],
        }
    }
    componentDidMount(): void {
        let a = new studioAPI();
        a.getCategory({},(response)=>{
            this.setState({categoryItems:response})
        },(message)=>{console.log(message.error_message)})
    }

    publish(){

        if (this.state.category_id === null || this.state.category_id === undefined || this.state.category_id.length <=0){
            window.alert("请选择节目所属分类!");
            return
        }
        if (this.state.name === null || this.state.name===undefined || this.state.name.length<=0){
            this.setState({
                displayNamePrompt:true,
            });
            return null
        }
        if (this.state.subtitle === null || this.state.subtitle===undefined || this.state.subtitle.length<=0){
            this.setState({
                displaySubtitlePrompt:true,
            });
            return null
        }
        if (this.state.description === null || this.state.description===undefined || this.state.description.length<=0){
            this.setState({
                displayDescriptionPrompt:true,
            });
            return null
        }


        let a = new studioAPI();
        a.publishProgram(
            {
                title:this.state.name,
                description:this.state.description,
                show_subtitle:this.state.subtitle,
                is_paid:this.state.isPaidProgram,
                price:parseFloat(this.state.price),
                poster_key:this.state.posterKey,
                category_id:this.state.category_id,
                page_token:this.props.router.pageToken.to()
            },
            (response)=>{
                this.props.router.navigate(`/studio/program`,{replace:true})
            },
            (msg)=>{window.alert(msg)}
        )

    }

    render(){
        return (
            <div className="console_content" style={{width:"100%",marginTop:"10px"}}>

                <InputFieldComponent
                    showPrompt={this.state.displayNamePrompt }
                    callback_onChange={(value)=>{
                        this.setState({
                            name:value,
                            displayNamePrompt:value.length <= 0
                        })
                    }}
                    label={"节目名字 / Program Name"}
                    disable={false}
                    promptText={"Please Input Program Name"}
                />
                <InputFieldComponent
                    showPrompt={this.state.displaySubtitlePrompt}
                    callback_onChange={(value)=>{
                        this.setState({
                            subtitle:value,
                            displaySubtitlePrompt:value.length <= 0
                        })
                    }}
                    label={"节目简述 / Program Subtitle"}
                    disable={false}
                    promptText={"Please Input Program Subtitle"}
                />
                <SelectComponent label={"选择节目类别 / Select program category"}
                                 items={this.state.categoryItems}
                                 callback_onChange={(category_id)=>{
                                     this.setState({category_id:category_id})
                                 }} />
                <TextAreaComponent
                    showPrompt={this.state.displayDescriptionPrompt}
                    callback_onChange={
                        (value)=>{
                            this.setState({
                                description:value,
                                displayDescriptionPrompt:value.length <= 0
                            })
                        }
                    }
                    label={"节目简介 / Program Description"}
                    disable={false}
                    promptText={"Please Input Prompt Description"}
                />
                <div >
                    <JNumberComponent label={"是否为收费节目? / Paid program or not"} callback_onChange={(is,value)=>{
                        this.setState({
                            isPaidProgram:is,
                            price:value
                        })
                    }}/>
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary"
                            onClick={()=>this.publish()}
                    >Publish</button>
                </div>
            </div>
        )
    }
}

export const CreateProgramPage  = withRouter(CreateProgramComponent);