import React from 'react';
import {BrowserRouter, useRoutes} from 'react-router-dom';
import './css/clear.css';
import './css/main.css';
import './css/index.css';
import './css/App.css';

import {HomePage} from "./component/Page/Home";
import {WatchPage} from "./component/Page/Watch";
import {ProgramPage} from "./component/Page/Program";
import {ShowListPage} from "./component/Page/ShowList";
import {SearchPage} from "./component/Page/Search";
import {LoginPage} from "./component/Page/Login";
import {RegisterPage} from "./component/Page/Register";
import NotFoundPage from "./component/Page/NotFound";
import {ChannelPage} from "./component/Page/Channel";
import IsAuthStatus from "./Auth/Status";
import {RechargePage} from "./component/Page/Recharge";
import {StudioRouter} from "./component/Page/Studio";
import {SubscribeProgramPage} from "./component/Page/SubscribeProgram";
import {ReportPage} from "./component/Page/Report";
import {PlanPage} from "./component/Page/Plan";
import {MemberPage} from "./component/Page/Member";



function AppRoutes() {

    return useRoutes(
        [
            {path:"/", element: <HomePage  />},
            {path:"/watch/:video_id",element:<IsAuthStatus><WatchPage/></IsAuthStatus>},
            {path:"/report/:item_id",element:<IsAuthStatus><ReportPage/></IsAuthStatus>},
            //{path:"/play_list/:play_list",element:<IsAuthStatus><WatchPage/></IsAuthStatus>},
            {path:"/subscribe_page/:program_id",element:<IsAuthStatus><SubscribeProgramPage /></IsAuthStatus>},
            {path:"/program/:topic_id",element:<ProgramPage />},
            {path:"/user/:user_id",element:<ChannelPage />},
            {path:"/channel",element:<IsAuthStatus><ChannelPage/></IsAuthStatus>},
            {path:"/listing",element:<ShowListPage />},
            {path:"/search",element:<SearchPage />},
            {path:"/login",element:<LoginPage />},
            {path:"/register",element:<RegisterPage />},
            {path:"*",element:<NotFoundPage />},
            {path:"/recharge",element:<RechargePage />},
            {path:"/studio/*",element:<IsAuthStatus><StudioRouter /></IsAuthStatus>},
            {path:"/plan",element:<PlanPage/>},
            {path:"/member",element:<MemberPage/>}
        ]
    )
    //  /legal/rechargeServiceAgreement
}

function App() {
  return (
      <BrowserRouter>
          <AppRoutes />
      </BrowserRouter>
  );
}


export default App;
