import {InputFieldComponent, SelectComponent, TextAreaComponent} from "../CommonComponents/Form";
import React from "react";
import {studioAPI} from "../../../../api/base";


export function CustomerInformationComponent({pageToken=""}) {


    let [nickName,setNickName] = React.useState();
    let [country,setCountry] = React.useState();
    let [language,setLang] = React.useState();
    let [description,setDescription] = React.useState();
    let [storageArea,setStorageArea] = React.useState();

    React.useEffect(()=>{

        function init() {
            let a = new studioAPI();
            a.getGeneralSetting(
                {
                    page_token:""
                },
                (response)=>{
                    setNickName(response.name);
                    setCountry(response.country);
                    setLang(response.language);
                    setDescription(response.description);
                    setStorageArea(response.storage_area);
                },(msg)=>{
                    console.log(msg["error_message"])
                }
            )
        }
        init()
    },[]);

    let changeProfile = ()=>{
        let a = new studioAPI();
        a.changeProfile(
            {
                "nick_name":nickName,
                "country":country,
                "language":language,
                "description":description,
                "storage_area":storageArea,
                page_token:pageToken
            },
            (response)=>{
                window.alert("修改成功!");
            },
            (msg)=>{window.alert(msg)},
        )
    };

    return (
        <div className="mt-2">
            <InputFieldComponent callback_onChange={(e) =>{setNickName(e)}} label={"Nick Name"} placeholder={nickName}/>
            <SelectComponent label={"Country"} items={[{id:"china",name:"China"},{id:"USA",name:"USA"}]} callback_onChange={(e) =>{setCountry(e)}} defaultValue={country}/>
            <SelectComponent label={"Language"} items={[{id:"English(US)", name: "English(US)"},{id:"English(UK)",name:"English(UK)"},{id:"ZH-CN",name:"中文(简体)"}]} callback_onChange={(e) =>{setLang(e)}} defaultValue={language}/>
            <SelectComponent label={"storage_area"} items={[{id:"china",name:"中国大陆"},{id:"singapore",name:"Singapore"}]} defaultValue={storageArea} callback_onChange={(e)=>{setStorageArea(e)}} />
            <TextAreaComponent callback_onChange={(e) => {setDescription(e)}} label={"Description"} placeholder={description}/>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={()=>changeProfile()}>Update</button>
            </div>
        </div>
    )
}