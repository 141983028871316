import React from "react";
import {Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {studioAPI} from "../../../../../api/base";
import {timestampToTime} from "../../../../CommonComponents/LittleFunc";

export function VideoAnalyticsComponent({id}) {


    let [items,setItems] = React.useState([
        {name:"view",dataKey:"view",color:"#0099ff"},
        {name:"click",dataKey:"click",color:"#99ff00"},
        ]);
    let [data,setData] = React.useState([]);
    let [startTime,setStartTime] = React.useState(null);
    let [endTime,setEndTime] = React.useState(null);

    React.useEffect(()=>{

        function init() {
            let api = new studioAPI();
            api.getStatistics({
                "id":id,
                "start_time":startTime,
                "end_time":endTime,
                "item_type":"video",
            },(response)=>{

                if (response !== null && typeof(response) === "object"){
                    let m ={};
                    response.forEach((it,index)=>{
                        let date = timestampToTime(it.time);
                        if (m.hasOwnProperty(date)===true){
                            let i = m[date];
                            if (i.hasOwnProperty(it.event)===true){
                                i[it.event]++
                            }else{
                                i[it.event] = 1
                            }
                        }else{
                            let i = {};
                            i[it.event] = 1;
                            m[date] = i
                        }
                    });
                    let data =[];
                    for (let key in m){
                        data.push(
                            {date:key,...m[key]}
                        )

                    }
                    setData(data)
                }

            },(msg)=>{window.alert(msg)})
        }
        init()
    },[]);



    return (<div className="console_content">

        <div >
            <LineChart width={800} height={300} data={data}>
                <XAxis dataKey="date"/>
                <YAxis/>
                <Tooltip />
                <Legend />
                {items.map(
                    i=>(
                        <Line type="monotone" dataKey={i.dataKey}  name={i.name} stroke={i.color===undefined ? "#333" : i.color} />
                    )
                )}
            </LineChart>
        </div>
    </div>)
}