import React from 'react';
import {api, newBiPoint} from "../../api/base";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {Link} from "react-router-dom";
import {withRouter} from "../CommonComponents/WithRouter";
import {WaitingComponent} from "../CommonComponents/Waiting";
import {SwiperSlider} from "../CommonComponents/Swiper";
import {ImageComponent} from "../CommonComponents/Image";
import {HomePageAdSpot, IsHttpsURL, MakeStaticURL, QueryAdsSpot} from "../CommonComponents/LittleFunc";
import Helmet from "react-helmet";
import {website_name, website_slogan} from "../CommonComponents/WebsiteTitle";


class HomePageCore extends React.Component{

    constructor(props){
        super(props);
        this.state={
            pageToken:this.props.router.pageToken.to(),
            contentBoxNumber:1,
            NoDataStatus:false
        };
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div>
                <Helmet >
                    <title>{website_name} - {website_slogan}</title>
                    <desc>Yimi.tv is an innovative video program sharing platform designed for creators to freely create, upload, and showcase their video content. With advanced integration technology, the platform helps creators precisely target, distribute, and promote their content, ensuring each program effectively reaches its intended audience and enhances overall impact.</desc>
                </Helmet>
                <HeadNavComponent/>
                <div className="video-warp" >
                    <SwiperSlider  ad_spot={QueryAdsSpot(HomePageAdSpot)}/>
                    <CommendTopicComponent page_token={this.state.pageToken} category={"tv"} title={"電視劇推薦 / TV Series Recommendations"} />
                    <CommendTopicComponent page_token={this.state.pageToken} category={"movie"} title={"電影推薦 / Movie Recommendations"} />
                    <CommendTopicComponent page_token={this.state.pageToken} category={"cartoon"} title={"動漫推薦 / Cartoon Recommendations"}/>
                    <CommendTopicComponent page_token={this.state.pageToken} category={"varietyshow"} title={"綜藝推薦 / Variety Show Recommendations"}/>
                    <CommendTopicComponent page_token={this.state.pageToken} category={"game"} title={"遊戲解說 / Game Commentary"} />
                </div>
                <FootNavComponent />
            </div>
        )
    }
}

export const HomePage = withRouter(HomePageCore);


class CommendVideoComponent extends React.Component {

    constructor(props){
        super(props);
        this.state={
            data:null,
            pageToken:props.pageToken,
            NoDataStatus:false,
            loadingDataStatus:true,
            page:1
        };
    }
    componentDidMount(): void {
        this.getData(1);
    }

    getData(page:number){
        api.GetCommendVideo({
            number:15,
            start:(page-1)*15,
            page_token:this.state.pageToken
        },(content)=>{
            if (content != null && content.length >= 15){
                this.setState({
                    data:content,
                    page:page,
                    loadDataStatus:false
                })
            }else{
                this.setState({
                    NoDataStatus:true,
                    loadDataStatus:false
                })
            }
        },(msg)=>{
            console.log(msg["error_message"]);
        })
    }
    handle_onChange(){
        this.getData(this.state.page + 1)
    }
    render_onMore(){

        if (this.state.NoDataStatus === true){
            return <a  className="btn btn-outline-primary text-decoration-none" disabled={"true"} >没有更多~</a>
        }
        return (
            <a className="btn btn-primary text-white " onClick={()=>{this.handle_onChange()}}>换一批
                <i className="bi bi-arrow-clockwise fw-bold ms-lg-1" /> </a>
        )
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        if (this.state.loadDataStatus === true){
            return <WaitingComponent />
        }

        if (this.state.NoDataStatus === true || this.state.data === null){
            return <div />
        }

        return (
            <div className="videolist" style={{width:"100%"}}>
                <div className="title">
                    <h2 className="fw-bold p-0">推薦視頻 / Recommended Video</h2>
                    {this.render_onMore()}
                </div>
                <div className="vlist">
                    <ul style={{margin:0,padding:0}}>
                        {this.state.data.map(item => (
                            <li>
                                <Link to={`/watch/${item.id}`}>
                                    <ImageComponent topic={"video"} src={item.thumb} Gif={item.Gif} isPoster={false} style={{borderRadius:"5px"}} itemId={item.id} />
                                </Link>
                                <div className="vdetails">
                                    <Link to={`/watch/${item.id}`} style={{height:"auto",maxHeight:"44px",minHeight:"22px",textDecoration:"none"}}>
                                        {item.title}
                                    </Link>
                                    <p style={{margin:0,padding:0}}>{`${item.click} Views · Published On ${item.published}`}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}


class CommendTopicComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            data:null,
            page:1,
            pageToken:props.pageToken,
            isNullData:false,
            number:15,
            category:props.category,
            columnTitle:props.title,
            isWaiting:true,
        }
    }
    componentDidMount(): void {
        this.getData(1,this.state.category);
    }

    getData(page:number,category:string){
        //set the waiting state
        this.setState({isWaiting:true});
        //get data
        api.GetCommendProgram({
            number:this.state.number,
            start:(page-1)*this.state.number,
            page_token:this.state.pageToken,
            category:category,
        },(content)=>{
            this.setState({
                data: content,
                page: page,
                isNullData: content === null || content.length <= 0,
                isWaiting: false,
            })
        },(msg)=>{
            console.log(msg["error_message"]);
        });
    }
    handle_onClick(){
        this.getData(this.state.page + 1,this.state.category)
    }
    render_onMore(){
        return (
            <a className="btn btn-primary text-white "
               onClick={()=>{this.handle_onClick()}}>换一批
                <i className="bi bi-arrow-clockwise fw-bold ms-lg-1" /> </a>
        )
    }
    render(){
        if (this.state.isWaiting){
            return <WaitingComponent />
        }
        if(this.state.isNullData){
            return <div />
        }
        //
        return (
            <div className="videolist" style={{width:"100%"}}>
                <div className="title">
                    <h2 className="fw-bold p-0">{this.state.columnTitle}</h2>
                    {this.render_onMore()}
                </div>
                <div className="vlist vlistjm">
                    <ul style={{margin:0,padding:0}}>
                        { this.state.data.map(item=>(
                            <li>
                                <Link to={`/program/${item.id}`} replace={true} onClick={()=>{newBiPoint("program","click",item.id)}}>
                                    <ImageComponent src={item.poster} isPoster={true} itemId={item.id} topic={"program"}/>
                                </Link>
                                <div className="vdetails">
                                    <Link to={`/program/${item.id}`}
                                          onClick={()=>{newBiPoint("program","Click",item.id)}}
                                          style={{textDecoration: "none",height:"auto",minHeight: "22px",maxHeight: "44px"}}>{item.title}</Link>
                                    <p style={{margin:0,padding:0}}>{`${item.viewer}次观看 · ${item.published}`}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}
