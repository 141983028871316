import React from 'react';
import {api, newBI, newBiPoint} from "../../api/base";
import '../../css/main.css'
import {WaitingComponent} from "../CommonComponents/Waiting";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {withRouter} from "../CommonComponents/WithRouter";
import {Link} from "react-router-dom";
import {RatingComponent, ShowRatingComponent} from "../CommonComponents/Rating";
import {defaultPoster, defaultThumb} from "./Studio/CommonComponents/Form";
import {ImageComponent} from "../CommonComponents/Image";
import {DiscussComponent} from "../CommonComponents/Discuss";
import {episodeSort, IsHttpsURL, MakeStaticURL, numberUnit2Text} from "../CommonComponents/LittleFunc";
import Helmet from "react-helmet";
import {website_name} from "../CommonComponents/WebsiteTitle";


class ProgramPageCore extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            topic: null,
            id:props.router.params.topic_id,
            openRatingComponent:false,
            isLoggedIn:props.router.auth.isLoggedIn,

        };
    }
    componentDidMount(): void {
        this.getTopicData(this.state.id);
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({isLoggedIn:nextProps.router.auth.isLoggedIn})
    }

    getTopicData(id:string){

        api.GetProgramDetail({id:id},(content)=>{
            this.setState({topic:content})
        },(msg)=>{
            console.log(msg["error_message"])
        })
    }
    render(){

        if (this.state.topic == null){
            return (
                <WaitingComponent />
            )
        }
        return (
            <div>
                <Helmet >
                    <title>{this.state.topic.title} - {website_name}</title>
                </Helmet>
                <HeadNavComponent/>
                <div className="crwarp d-block" >
                    <InformationComponent topic={this.state.topic} callback_openRatingComponent={ ()=>{
                        this.setState({
                            openRatingComponent:true,
                        })
                    } }  isLoggedIn={this.state.isLoggedIn}/>
                    <OtherComponent topic={this.state.topic}/>
                </div>
                <FootNavComponent/>
                { this.state.openRatingComponent === false ? <div /> :
                    <RatingComponent
                        callback_closeEvaluationBox = {()=>{
                            this.setState({
                                openRatingComponent:false
                            })
                        }}
                        callback_afterSuccessRating={(s,e)=>{
                            let topic = this.state.topic;
                            topic.score = s;
                            topic.evaluation = e;
                            this.setState({
                                topic:topic,
                                openRatingComponent:false
                            })
                        }}
                        data={this.state.topic}
                    />
                }
            </div>

        )
    }
}

export const ProgramPage = withRouter(ProgramPageCore);

class InformationComponent extends React.Component{


    constructor(props){
        super(props);
        this.state={
            topic:props.topic,
            isSubscribed:false,
            isCollected:false,
            isRated:false,
            isLoggedIn:props.isLoggedIn,
            needUpdateSubscribeStatus:true,
            needUpdateRatingStatus:true,
            subscriberAmount:props.topic.subscriber,
        }
    }
    componentDidMount(): void {
        if (this.state.isLoggedIn===true){
            if (this.state.needUpdateSubscribeStatus===true){
                api.IsAlreadySubscribed({
                    program_id:this.state.topic.id,
                    page_token:this.state.pageToken,
                },(response)=>{
                    this.setState({isSubscribed:response.result,needUpdateSubscribeStatus:false})
                },(msg)=>{window.alert(msg["error_message"])})
            }
            if (this.state.needUpdateRatingStatus === true){
                api.IsAlreadyRating({
                    id:this.state.topic.id,
                    page_token:this.state.pageToken,
                },(response)=>{
                    this.setState({needUpdateRatingStatus:false,isRated:response.result === undefined ? response.is_rated : response.result})
                },(msg)=>{
                    console.log(msg["error_message"])
                })
            }
            //获取是否已经收藏过
            api.IsAlreadyCollectedProgram({
                program_id:this.state.topic.id,
                page_token:this.state.page_token,
            },(response)=>{
                this.setState({
                    isCollected:response.result,
                })
            },(msg)=>{
                console.log(msg["error_message"])
            })
        }
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({
            topic:nextProps.topic,
            isLoggedIn:nextProps.isLoggedIn,
        })
    }
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (this.state.isLoggedIn !== prevState.isLoggedIn){
            if (this.state.isLoggedIn === true) {
                //获取是否已经订阅了
                api.IsAlreadySubscribed({
                    program_id: this.state.topic.id,
                    page_token: this.state.pageToken,
                }, (response) => {
                    this.setState({isSubscribed: response.result, needUpdateSubscribeStatus: false})
                }, (msg) => {
                    window.alert(msg["error_message"])
                });
                //获取是否已经点评过
                api.IsAlreadyRating({
                    id: this.state.topic.id,
                    page_token: this.state.pageToken,
                }, (response) => {
                    this.setState({
                        needUpdateRatingStatus: false,
                        isRated: response.result === undefined ? response.is_rated : response.result
                    })
                }, (msg) => {
                    console.log(msg["error_message"])
                });
                //获取是否已经收藏过
                api.IsAlreadyCollectedProgram({
                    program_id:this.state.topic.id,
                    page_token:this.state.page_token,
                },(response)=>{
                    this.setState({
                        isCollected:response.result,
                    })
                },(msg)=>{
                    console.log(msg["error_message"])
                })
            }
        }

    }

    collect(){
        api.CollectProgram({
            program_id:this.state.topic.id,
            page_token:this.state.pageToken,
            is_give_up:this.state.isCollected,
        },(response)=>{
            this.setState({
                isCollected:!this.state.isCollected,
            })
        })
    }
    giveEvaluation(){
        this.props.callback_openRatingComponent()
    }
    subscribe(){
        if (this.state.isSubscribed === false && this.state.needUpdateSubscribeStatus === false) {
            api.SubscribeProgram({
                program_id: this.state.topic.id,
                pageToken: this.state.pageToken,
            }, (response) => {
                //新增BI 检测事件
                window.document.body.appendChild(newBI("program","subscribe",this.state.topic.id));
                //新增订阅人数
                let sa = this.state.subscriberAmount;
                //改变状态
                this.setState({isSubscribed: !this.state.isSubscribed, needUpdateSubscribeStatus: false,subscriberAmount:sa+1});
            }, () => {
            })
        }
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="mdetails fl">
                <a className="mpic">
                    <img src={this.state.topic.poster.length <=0 ? defaultPoster : IsHttpsURL(this.state.topic.poster,(url)=>MakeStaticURL(url))} alt={this.state.topic.title} style={{maxHeight:"100%"}}/>
                </a>
                <div className="mind fl">
                    <h2>{this.state.topic.title}<em>{this.state.topic.show_subtitle.length > 0 ? ` / ${this.state.topic.show_subtitle}` : ""}</em></h2>
                    <ShowRatingComponent score={this.state.topic.score} evaluation={this.state.topic.evaluation} />
                    <em>{`${this.state.topic.viewer}次观看· ${this.state.subscriberAmount}人订阅它 · 于${this.state.topic.published}发布`}</em>
                    <p className="overflow-scroll" style={{height:"120px"}}>{this.state.topic.description}</p>
                    <div className="tvbtn">
                        <button className="btn btn-primary btn-lg" onClick={()=>this.subscribe()} disabled={this.state.isSubscribed}>
                            { this.state.isSubscribed === true ? "已订阅" :"立即订阅"}</button>
                        <button className="btn btn-outline-secondary btn-lg" onClick={()=>this.collect()} style={{marginLeft:"10px"}}
                            disabled={this.state.isCollected}
                        >{this.state.isCollected === true ? "已收藏" : "收藏到频道" }</button>
                    </div>
                </div>
                <div className="grade border-0">
                    <button onClick={()=>this.giveEvaluation()} className="btn btn-light fw-bold text-center" style={{fontSize:"15px"}} disabled={this.state.isRated}>{this.state.isRated === true ? "已评价" : "评分"}</button>
                </div>
            </div>
        )
    }
}

class OtherComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            topic:props.topic===null ? {} : props.topic,
        }
    }
    componentWillReceiveProps(nextProps) {

        this.setState({topic: nextProps.topic});
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <div className="mwarp d-block float-start " style={{marginBottom:"50px"}}>
                <LeftComponent topic={this.state.topic} />
                <RightComponent topic={this.state.topic} />
            </div>
        )
    }
}

class LeftComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            topic:props.topic,
            episodes:[],
        }
    }
    componentDidMount(): void {
        api.GetEpisodes({id:this.state.topic.id,page_token:this.state.pageToken},
            (response)=>{
                this.setState({episodes:response})
            },
            (msg)=>{
                console.log(msg["error_message"])
            });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({topic: nextProps.topic});
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        if (this.state.topic === null || this.state.topic === undefined){

            return (
                <div style={{width:"900px"}} className="mleft fl">
                    <WaitingComponent />
                </div>
            )
        }

        return (
            <div className="mleft fl" style={{width:"900px"}}>
                <EpisodesComponent data={this.state.episodes} creator={this.state.topic.creator}/>
                <DiscussComponent pageToken={this.state.pageToken}
                                  itemId={this.state.topic.id} authorId={this.state.topic.creator.id}
                                  topic={"program"}

                />
            </div>
        )
    }
}

function EpisodesComponent({data,creator}) {

    let e = data === null ? [] : data;
    let [episodes,setEpisodes] = React.useState(episodeSort(e));
    let [initState,setInitState] = React.useState(true);

    React.useEffect(()=>{
        setInitState(false);
        setEpisodes(e)
    },[data]);


    if (initState === true){
        return (
            <div className="dlive" style={{width:"900px",minHeight:"200px"}}>
                <div className="hd">
                    <div className="title"><a ><span>分集剧情</span></a></div>
                </div>
                <div className="container" style={{marginTop:"50px"}}>
                    <WaitingComponent />
                </div>
            </div>
        )
    }

    if (episodes === null || episodes === undefined || episodes.length <= 0){

        return (
            <div className="dlive" style={{width:"900px",minHeight:"200px"}}>
                <div className="hd">
                    <div className="title"><a ><span>分集剧情</span></a></div>
                </div>
                <div className="container" style={{marginTop:"50px"}}>

                    <div className="row">
                        <span className="fs-5 text-dark w-100 text-center fw-bold">对不起客官,暂时没有分集可看哦~</span>
                    </div>
                    <div className="row">
                        <span className="lead w-100 text-center">Sorry,there should be no episodes for this Program</span>
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div className="dlive" style={{width:"900px"}}>
            <div className="hd">
                <div className="title"><a ><span>分集剧情</span></a></div>
            </div>
            <ul style={{margin:0,padding:0}}>
                { episodes.map(
                    item=>(
                        <li>
                            <Link to={`/watch/${item.video.id}`} onClick={()=>{
                                newBiPoint("video","click",item.video.id)
                            }}>
                                <ImageComponent src={IsHttpsURL(item.video.thumb,(url)=>MakeStaticURL(url))} style={{width:"195px",height:"130px"}} isPoster={false} itemId={item.id} topic={"video"} />
                            </Link>
                            <div className="vdetails" style={{display:"normal"}}>
                                <h2 style={{height:"auto",maxHeight:"45px"}}><Link to={`/watch/${item.video.id}`} replace={true} style={{textDecoration:"none"}}  title={item.sort_number} onClick={()=>{
                                    newBiPoint("video","click",item.video.id)
                                }}>{item.video.title}</Link></h2>
                                <p style={{margin:"0px"}}>来自<Link to={`/user/${creator===null ? "" : creator.id}`}>{creator===null ? "未知" :creator.name}</Link></p>
                                <p style={{margin:"0px"}}>{`${item.video.click}次观看 · ${item.video.published}`}</p>
                            </div>
                        </li>
                    )
                )}
            </ul>
        </div>
    )
}


class RightComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            topic:props.topic,
            data:null,
            InitState:true,
        };
        this.getData()
    }
    componentWillReceiveProps(nextProps) {
        this.setState({topic: nextProps.topic});

    }
    componentDidMount(): void {
        this.getData();
    }

    getData(){
        api.GetCommendUser({user_id:this.state.topic.creator_id},
            (content)=>{
                this.setState({data:content,InitState:false})
            },
            (msg)=>{
            this.setState({data:null,InitState:false})
        })
    }

    transformUnit(n){
        return numberUnit2Text(n)
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        if (this.state.InitState === true){
            return <div className="mright fl" style={{width:"270px"}}>
                <WaitingComponent />
            </div>
        }

        if (this.state.data === null || this.state.data.length<=0){

            return (
                <div className="mright fl" style={{width:"270px"}}>
                    <div className="relate fl" style={{height:"auto",width:"260px"}}>
                        <div className="title"><a ><span>相关频道</span></a></div>
                        <div className="justify-content-center">
                            <span className="text-dark">实在找不到与你相匹配的相关频道~原谅我们吧</span>
                        </div>
                    </div>
                </div>
            )
        }
//<i className="bi bi-heart-fill text-danger m-0 p-0" style={{background:"none"}}/>{item.likes_amount}</div>
        return (
            <div className="mright fl" style={{width:"280px"}}>
                <div className="relate fl" style={{height:"auto",width:"260px"}}>
                    <div className="title"><a ><span>相关频道</span></a></div>
                    <ul style={{margin:"0",padding:"0"}}>
                        {
                            this.state.data.map(item => (<li>
                                <div className="npic fl">
                                    <Link to={`/user/${item.id}`} style={{width:"60px",height:"60px"}} className="float-start">
                                        <img src={IsHttpsURL(item.icon,(url)=>MakeStaticURL(url))} alt={item.name} style={{width:"60px",height:"60px"}}/></Link>
                                </div>
                                <div className="ndetails fl">
                                    <h3 style={{fontSize:"17px",margin:0,padding:0}}>{item.name}</h3>
                                    <div className="follow">
                                        <span style={{fontSize:"14px"}}>{`${this.transformUnit(item.fans_amount)}位订阅者`}</span>
                                    </div>
                                </div>
                            </li>))
                        }
                    </ul>
                </div>
            </div>
        )
    }
}