import {Swiper,SwiperSlide} from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y,EffectFlip,Autoplay} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import React from "react";
import {Link} from "react-router-dom";
import {api, newBI} from "../../api/base";


export const SwiperSlider = ({ad_spot="ads_home_page"})=>{


    let [data,setData] = React.useState(null);


    React.useEffect(()=>{
        getData()
    });

    const getData = ()=>{

        if (data !== null){return null}
        api.GetAds({
            "advertising_spot":ad_spot,"count":5,"page_token":""
        },(response)=>{
            let d = [];
            if (response!==null){
                if (typeof(response)==="object"){
                    response.forEach((i,key)=>{
                        let s = i.source === undefined ? [] : i.source;
                        let url = new URL(i.link);
                        let redirectURL = i.link;
                        let source = [];
                        if (url.hostname === "www.yimi.tv" || url.hostname === "ads.yimi.tv"){
                            redirectURL = url.pathname;
                        }
                        s.map(item=>(
                            source.push({
                                thumb:item.image,
                                id:item.id,
                            })
                        ));
                        d.push({
                           link:redirectURL,
                           AdsId:i.id,
                           source:source,
                           slogan:i.slogan,
                        })
                    });
                    setData(d)
                }
            }
        },()=>{})
    };
    if (data == null){return  <div />}
    let d= 0;

    return (
        <Swiper  spaceBetween={0}
                 slidesPerView={1}
                 onSlideChange={() =>{
                    d++;
                 } }
                 onSwiper={(e) => {}}
                 autoplay={{
                     delay: 5500,
                     disableOnInteraction: false,
                 }}
                 navigation
                 pagination={{ clickable: true }}
                 scrollbar={{ draggable: true }}
                 modules={[Navigation,Pagination,Scrollbar,A11y,EffectFlip,Autoplay]}
                 effect={"flip"}
        >
            {data.map(item=>(
                <SwiperSlide key={item.source[0].thumb}>
                    <SliderItem data={item} count={d} length={data.length}/>
                </SwiperSlide>
            ))}
        </Swiper>
    )
};

function SliderItem({data,count,length}) {





    let source = data.source === undefined ? [] : data.source;
    let currentImage =source[0].thumb;
    let currentSourceId = source[0].id;


    if (source.length ===1){
        currentImage = source[0].thumb
    }else{
        let len = source.length;
        let time = count / length;

        if (time < len) {
            currentImage = source[time - 1].thumb;
            currentSourceId = source[time - 1].id;
        } else {
            let s = time;
            while (true) {
                s = s - len;
                if (s <= len) {
                    break
                }
            }
            currentImage = source[s - 1].thumb;
            currentSourceId = source[s-1].id;
        }
    }

    let ele = document.getElementById(currentSourceId);
    let ob = new IntersectionObserver((entry)=>{
        let ids = [];
        entry.map(it=>(
            ids.push(it.target.id)
        ));

        ele.appendChild(newBI("ad","view",ids.join("&"),data.AdsId));

    },{threshold:[0.5]});
    if (ele !== null){
        ob.observe(ele)
    }


    return (
        <Link to={data.link}  replace={true} state={{fromAds:data.AdsId}} >
            <img src={currentImage} alt={data.id} style={{width:"100%",height:"100%"}} id={currentSourceId}/>
        </Link>
    )
}