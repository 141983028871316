import {useLocation,useNavigate,useParams} from "react-router";
import React from "react";
import {useAuth} from "../../Auth/Route";


export class pageToken{
    constructor(pageToken:string){
        this._pt = pageToken
    }

    reset(p:string) {
        this._pt = p;
    }

    to(){return this._pt}
}

export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let auth = useAuth();
        let pt = new pageToken(auth.pageToken);




        return (
            <Component
                {...props}
                router={{ location, navigate, params,pageToken:pt,auth}}
            />
        );
    }
    return ComponentWithRouterProp;
}