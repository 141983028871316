import React from 'react';
import {Link, useLocation, useNavigation} from "react-router-dom";
import AuthStatus from "../../Auth/StatusComponent";
import {Navigate} from "react-router-dom";
import {newBI} from "../../api/base";


export class HeadNavComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            fixed:document.documentElement.scrollTop > 60,
            keyword:null,
            isSearch:false
        };
        this.ref= React.createRef();
    }
    componentDidMount() {

        let ob = new IntersectionObserver((entry)=>{
            entry.map(it=> {
                if (it.intersectionRatio < 0.5){
                    if (this.state.fixed === true){return null}
                    this.setState({fixed:true})
                }else{
                    if (this.state.fixed === false){return null}
                    this.setState({fixed:false})
                }
            });
            //
        },{threshold:[0.5]});
        ob.observe(this.ref.current);
    }
    render(){
        return (

            <div ref={this.ref} style={{height:"60px"}}>
            <div className={`top ${this.state.fixed === true ? "fixed" : ""}`} style={ this.state.fixed === true ?{position:"fixed",top:"0px",left:"0p",} : {}} >
                <div className="top-wrap">
                    <div className="logo fl" style={{color:"#ff5555"}}>Yimi<em style={{color:"#333333"}}>TV</em></div>
                    <div className="menu fl">
                        <ul>
                            <li style={{textAlign:"center"}}><Link to="/" className="home">Home</Link></li>
                            <li style={{textAlign:"center"}}><Link to="/listing" className="jm" >Program</Link></li>
                            <li style={{textAlign:"center"}}><Link to="/live" className="pd" >Live</Link></li>
                            <li style={{textAlign:"center"}}><Link to="/gaming" className="ss" >Gaming</Link></li>
                        </ul>
                    </div>
                    <div className="search fl">
                        <input type="text" className="m0 " placeholder="Please enter the name of the video you want to watch"
                               onChange={(event)=>{
                                   this.setState({keyword:event.target.value})
                               }}
                        />
                        <input type="submit" className="Search-btn" value=""
                            onClick={()=>{
                                if (this.state.keyword === null || this.state.keyword.length <=0 ){
                                    return null
                                }else{
                                    this.setState({
                                        isSearch:true
                                    })
                                }
                            }}
                        />
                        { this.state.isSearch === true ?
                        <Navigate to={"/search"} replace={true} state={{
                        keyword:this.state.keyword}} />: <div />}

                    </div>
                    <AuthStatus />
                </div>
            </div></div>
        )
    }
}



