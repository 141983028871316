import {studioAPI} from "../../../../api/base";
import {withRouter} from "../../../CommonComponents/WithRouter";
import React from "react";
import {CustomerInformationComponent} from "./Info";
import {TabComponent, TitleComponent} from "../CommonComponents/Tab";
import {SecurityComponent} from "./Security";


class SettingComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            selectedId:1,
            tabItems:[{id:1,label:"Profile Setting"},{id:2,label:"Security Setting"}],
            pageToken:this.props.router.pageToken.to(),
            user:this.props.router.auth.user
        }
    }
    changePassword(n,v){
        let a = new studioAPI();
        a.changePassword(
            {password:n,code:v,country_code:"",page_token: this.state.pageToken},
            (response)=>{
                this.props.router.auth.signOut();
                this.props.router.navigate("/login",{replace:true})
            },
            (msg)=>{window.alert(msg)}
        )
    }
    changeProfile(p){

    }

    disableAccount(){
        let s = new studioAPI();
        return s.disableAccount({page_token:this.state.pageToken}, (resp)=>{},(msg)=>{window.alert(msg["error_message"])});
    }

    renderContent(){

        switch (this.state.selectedId) {
            case 1:
                return (<CustomerInformationComponent />);
            case 2:
                return (<SecurityComponent callback_changePassword={()=>this.changePassword()} />)

        }
    }

    render(){
        return (<div className="studioWrap">
            <div className="console">
                <div className="console_inside">
                    <TitleComponent callback_onClick={()=>this.disableAccount()} title={"Account Setting"} btn_title={"Disable"}/>
                    <TabComponent tab_item={this.state.tabItems} callback_OnSelected={(id)=>{this.setState({selectedId:id})}} />
                    {this.renderContent()}
                </div>
            </div>
        </div>)
    }
}

export const SettingPage= withRouter(SettingComponent);