import React from 'react';
import {Link} from "react-router-dom";
import {api} from "../../api/base";
import {IsHttpsURL, MakeStaticURL, ProgramListAdSpot, QueryAdsSpot} from "./LittleFunc";



const SliderAnimationPlayTime = 1000*60;

export class TopicListSlider extends React.Component{
    

    constructor(props){
        super(props);
        this.state={
            index:0,
            data:null,
            length:2,
        };
        this.timer = null
    }
    componentDidMount(): void {
        this.timer= this.setTimer();
        this.getAds()
    }
    getAds(){
        let p = {"advertising_spot":QueryAdsSpot(ProgramListAdSpot),"width":880,"height":350,"count":5};
        api.GetAds(p,(content)=>{
            if (content === null || typeof content !== Array){
                this.setState({data:[],length:0});
                return
            }
            this.setState({data:content,length:content.length,})
        },null)
    }

    setTimer(){
        return setInterval(this.next.bind(this),SliderAnimationPlayTime)
    }
    clearTimer(){
        window.clearInterval(this.timer)
    }
    handlerRightClicked(){
        this.clearTimer();
        this.setTimer();
        this.next()
    }
    handlerLeftClicked(){
        this.clearTimer();
        this.setTimer();
        this.prev()
    }
    next(){
        this.setState({
            index:this.state.length-1 < this.state.index+1 ? 0 : this.state.index+1
        });
    }
    prev(){
        let index = 0;
        if (this.state.index === 0){
            index = this.state.length -1
        }else{
            index = this.state.index-1 <= 0 ? 0 : this.state.index-1
        }

       this.setState({
            index:index
        })
    }
    render_list(){
        let list = [];
        if (typeof this.state.data !== Array){
            return
        }

        this.state.data.forEach(
            (item,index)=>{
                list.push(
                    <li style={{display:this.state.index === index ? "block" : "none"}} className="h-100">
                        <Link style={{backgroundImage:`url(${IsHttpsURL(item.source[0].image,(url)=>{return MakeStaticURL(url)})})`}}
                            className="fpic" to={item.link}/>
                        <div className="introduce h-100" style={{width:"300px"}}>
                            <h2 style={{margin:0,padding:0,fontSize:"17px",minHeight:"25px",maxHeight:"50px",lineHeight:"25px"}} className="fw-bold overflow-hidden">{item.name}</h2>
                            <span className="badge text-bg-warning rounded-pill text-center text-white" style={{height:"25px",lineHeight:"20px",marginBottom:"5px"}}>{item.type.name}</span>
                            <p style={{fontSize:"14px",maxHeight:"120px"}} className="text-dark overflow-hidden">{item.slogan}</p>
                            <Link className="btn btn-primary text-center" to={item.link}>立即查看</Link>
                        </div>
                    </li>
                );
            }
        );
        return list;
    }

    render(){
        if (this.state.data === null || this.state.data.length<=0){
            return <div />
        }
        return (
            <div className="focusAd">
                <a className="next" onClick={()=>this.handlerRightClicked()}/>
                <a className="prev" onClick={()=>this.handlerLeftClicked()} />
                <div className="bd w-100">
                    <ul style={{margin:0,padding:0}}>
                        {this.render_list()}
                    </ul>
                </div>
            </div>
        )
    }
}

export class HomeSlider extends React.Component{

    constructor(props){
        super(props);
        this.state={
            data:[],
            selectedIndex:0,
            pageToken:this.props.pageToken
        };
        this.timer = null;
        this.width = 1180;
        this.height = 400;

    }
    componentDidMount(): void {
        this.timer = this.setTimer();
        this.getAds();
    }
    setTimer(){
        return window.setInterval(this.next.bind(this),SliderAnimationPlayTime)
    }
    clearTimer(){
        if (this.timer === null){return }
        return window.clearInterval(this.timer)
    }
    handlerRightClicked(){
        this.clearTimer();
        this.timer= this.setTimer();
        this.next()
    }
    handlerLeftClicked(){
        this.clearTimer();
        this.timer= this.setTimer();
        this.prev()
    }
    getAds(){

        let a = new api();
        api.GetAds(
            {"advertising_spot":"ddd","width":this.width,"height":this.height,"count":5,"page_token":this.state.pageToken},
            (content)=>{
                let data = [];
                if (typeof(content) === "object"){
                    content.forEach((dat,index)=>{
                        let source = dat.source === undefined ? [] : dat.source;
                        source.map(item => (
                            data.push({
                                thumb: item.image,
                                id: item.id,
                                AdsId: content.id,
                            })
                        ))
                    })
                }
                console.log(data);

                this.setState({
                    data:data
                })
            }
        )
    }
    next(){
        //console.log(this.state.data.length - 1 < this.state.selectedIndex+1 ? 0 : this.state.selectedIndex+1)
        this.setState({selectedIndex:this.state.data.length - 1 < this.state.selectedIndex+1 ? 0 : this.state.selectedIndex+1})
    }
    prev(){

        let index = 0;
        if (this.state.selectedIndex === 0){
            index = this.state.data.length -1
        }else{
            index = this.state.selectedIndex-1 <= 0 ? 0 : this.state.selectedIndex-1
        }

        this.setState({
            selectedIndex:index
        })

    }

    renderItem(){
        let d=[];
        this.state.data.forEach( (item,index)=>{
                d.push(<li style={{backgroundImage:`url('${item.thumb}')`,backgroundPosition:"center",backgroundRepeat:"no-repeat",height:`${this.height}px`,width:`${this.width}px`,display:this.state.selectedIndex === index ? "block" :"none"}} className={"slidizle-slide"}>
                    <Link to={item.link} />
                </li>
                );
            })
        ;
        return d
    }
    renderWindow(){

        let d = [];
        this.state.data.forEach((item,index)=>{
            d.push(
                <li className="slider-navigation-item" style={{opacity:this.state.selectedIndex === index ? 1:0.5,
                    borderRadius:"2px",border:"2px solid #ffffff",
                    position:"relative",top:"-100px",left:"400px",
                    marginLeft:"20px",display:"inline-block",width:"74px",height:"34px",
                    backgroundImage:`url('${item.thumb}')`,
                    backgroundRepeat:"no-repeat",backgroundPosition:"50% 50%",backgroundSize:"cover"}}
                    onClick={ ()=>{
                        this.setState({
                            selectedIndex:index
                        })
                    } }

                />
            )
        });
        return d
    }

    render(){
        return  (
            <div className="yimitv" style={{marginBottom: "20px"}}>
                <ul style={{zIndex:"1"}}>
                    {
                        this.renderItem()
                    }
                </ul>

                <LeftButton onClick={()=>{this.handlerLeftClicked()}} />
                <RightButton onClick={()=>{this.handlerRightClicked()}}/>
                <ul className={"slidizle-navigation"} style={{height: "40px", position: "relative", top: "-60px"}}>
                    {this.renderWindow()}
                </ul>
            </div>
        )
    }
}
/*
<i className="bi bi-caret-left-fill" onClick={() => this.handlerLeftClicked()}
   style={{}}/>*/
function LeftButton({onClick=()=>{}}) {
    return (<div style={{width:"40px",height:"40px",position: "relative", top:"-55%",left:"12%",cursor: "pointer",zIndex:"5",color:"#fff"}} onClick={()=>{onClick()}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
             className="bi bi-arrow-left-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
        </svg>
    </div>)
}

function RightButton({onClick = ()=>{}}) {

    return (
        <div style={{width:"40px",height:"40px",position: "relative",top:"-65%",left:"88%",cursor: "pointer",zIndex:"5",color:"#fff"}} onClick={()=>{onClick()}} >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 className="bi bi-arrow-right-circle" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
        </div>
    )

}