import React from 'react';
import {WaitingComponent} from "../CommonComponents/Waiting";
import {api, newBiPoint} from "../../api/base";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {Link} from "react-router-dom";
import {TopicListSlider} from "../CommonComponents/Silder";
import {RatingComponent, ShowRatingComponent} from "../CommonComponents/Rating";
import {withRouter} from "../CommonComponents/WithRouter";
import {ImageComponent} from "../CommonComponents/Image";
import {IsHttpsURL, MakeStaticURL, numberUnit2Text, uniqueData} from "../CommonComponents/LittleFunc";
import Helmet from "react-helmet";
import {website_name} from "../CommonComponents/WebsiteTitle";


class ShowListPageCore extends React.Component {

    constructor(props){
        super(props);
        this.state={
            data:null,
            page:1,
            openEvaluationBox:false,
            evaluationWork:null,
            error:false,
            notData:false,
            loadingData:false,
            callback_afterRatingSuccess:()=>{},
        };
    }

    componentDidMount(): void {
        this.getData();
    }

    getData(page = 1){

        if (this.state.loadingData === true){
            return
        }else{
            this.setState({
                loadingData:true
            })
        }

        api.GetProgramList({
            page:page,
            pageToken:this.props.router.pageToken.to(),
            sort_by:4,
        },(content)=>{
            if (content === null){
                this.setState({error:true,notData:true,loadingData:false})
            }else{
                let data = this.state.data === null ?  [] : this.state.data;
                data = [...data,...content];
                this.setState({data:data,page:page,loadingData:false})
            }
        },()=>{
            this.setState({error:true,loadingData:false})
        })
    }
    giveEvaluation(show,callback_afterRatingSuccess){
        this.setState({
            openEvaluationBox:true,
            evaluationWork:show,
            callback_afterRatingSuccess:callback_afterRatingSuccess,
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if(this.state.data === null){
            return (<div >
                <HeadNavComponent />
                <div className="crwarp" style={{minHeight:"1000px"}} >
                    <WaitingComponent />
                </div>
                <FootNavComponent />

            </div>)
        }
        return (
            <div >
                <Helmet>
                    <title>Program - {website_name}</title>
                </Helmet>
                <HeadNavComponent/>
                <div className="crwarp" style={{display:"flow-root"}}>
                    <TopicListSlider />
                    <div className="mwarp float-start" style={{minHeight:"1000px"}}>
                        <ListComponent data={this.state.data} callback_giveEvaluation={this.giveEvaluation.bind(this) }  {...this.props} />
                        <CommendComponent/>
                    </div>
                    {this.state.loadingData === true ? <WaitingComponent /> : <div />}
                </div>
                <FootNavComponent callback_handleViewDisplay={()=>{
                    this.getData(this.state.page + 1)
                }}/>
                { this.state.openEvaluationBox === true ?
                    <RatingComponent
                        callback_closeEvaluationBox={()=>{this.setState({openEvaluationBox:false})}}
                        callback_afterSuccessRating={(s,e)=>{
                            this.setState({openEvaluationBox:false});
                            this.state.callback_afterRatingSuccess(s,e);
                        }}
                        data={this.state.evaluationWork}
                    /> : <div /> }
            </div>
        )
    }

}

export const ShowListPage = withRouter(ShowListPageCore);

class ListComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            data:props.data,
            pageToken:props.router.pageToken.to(),
            isLoggedIn:props.router.auth.isLoggedIn,
        }
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {

        this.setState({
            data:uniqueData(nextProps.data),
            isLoggedIn:nextProps.router.auth.isLoggedIn,
            pageToken:nextProps.router.pageToken.to(),
        })
    }

    componentDidMount(): void {
        if (this.state.isLoggedIn === true){
            return null
        }
    }



    giveEvaluation(data,callback=()=>{}){
        return this.props.callback_giveEvaluation(data,callback);
    }

    render(){

        return (
            <div className="mpleft fl">
                {
                    this.state.data.map(item => (
                        <ProgramComponent data={item}
                                          isLoggedIn={this.state.isLoggedIn}
                                          pageToken={this.state.pageToken}
                                          nav={this.props.router.navigate}
                                          callback_Rating = {(data,callback_afterSuccess=()=>{})=>{
                                              this.giveEvaluation(data,callback_afterSuccess)
                                          }}
                        />)
                    )
                }
            </div>
        )
    }
}

function ProgramComponent({data,isLoggedIn=false,pageToken,nav,callback_Rating}) {

    let [isSubscribed,setIsSubscribed] = React.useState(false);
    let [isRated,setIsRated] = React.useState(false);
    let [isCollected,setIsCollected] = React.useState(false);
    let [score,setScore] = React.useState(data.score);
    let [evaluation,setEvaluation]= React.useState(data.evaluation);


    let getSubscribeStatus = ()=>{
        api.IsAlreadySubscribed({
            program_id: data.id,
            page_token: pageToken,
        }, (response) => {
            setIsSubscribed(response.result)
        }, (msg) => {
            console.log(msg["error_message"])
        })
    };

    let getRatingStatus = ()=>{
        api.IsAlreadyRating(
            {id:data.id,page_token:pageToken},
            (response)=>{
                setIsRated(response.result)
            },(msg)=>{
                console.log(msg["error_message"])
            }
        )
    };

    let isLogged = (title,callback=()=>{})=>{
        if (isLoggedIn === false){
            let t = window.confirm(`${title}需要您先登陆,是否登陆?`);
            if (t===true){
                nav("/login",{replace:true,state:{pathname:"/listing"}});
                return null
            }
        }
        return callback()
    };
    let subscribeProgram = ()=>{
       return isLogged("订阅节目",()=>{
           api.SubscribeProgram({
               program_id: data.id,
               page_token: pageToken
           }, (response) => {
               setIsSubscribed(true);
               newBiPoint("program","subscribe",data.id);
           }, (msg) => {
               window.alert(msg["error_message"])
           })
       });
    };
    let playNow = ()=>{
        return isLogged("观看节目",()=>{
            nav(`/play_list/${data.id}`,{replace:true,state:{pathname:"/listing"}});
            return null
        });
    };
    let collect = ()=>{
        return isLogged("收藏节目",()=>{
            api.CollectProgram(
                {
                    program_id:data.id,
                    page_token:pageToken,
                },(response)=>{
                    setIsCollected(true)
                },(msg)=>{
                    window.alert(msg["error_message"])
                }
            )
        })

    };
    let giveEvaluation= (data)=>{
        return isLogged("评价节目",()=>{
            callback_Rating(data,(s,e)=>{
                setIsRated(true);
                setScore(s);
                setEvaluation(e);
            })
        });
    };

    React.useEffect(()=>{

        function initState() {
            getRatingStatus();
            getSubscribeStatus();
        }
        initState()
    },[]);

    let renderSubscribeButton = ()=>{

        if (isSubscribed === false) {
            return (
                <div className="btn btn-danger text-center fs-5 fw-bold"
                     onClick={() =>subscribeProgram(data.id)}
                >{`Subscribe Now(${data.price <= 0 || data.price === null ? "For Free" : data.price.toString() + " Coins"})`}</div>
            )

        }
        return (
            <div >
                <div className="btn btn-primary btn-lg" onClick={() => playNow(data.id)}>
                    <i className="bi bi-play " />
                    &nbsp;Play Now</div>
                <button className="btn btn-outline-warning btn-lg" onClick={() =>collect(data.id)} style={{marginLeft:"5px"}} disabled={isCollected} >
                    <i className="bi bi-collection" />
                    &nbsp;{isCollected === true ?"已收藏" :"收藏该节目"}</button>
            </div>
        )
    };

    return (
        <div className="tv-details fl">
            <Link className="tvpic" to={`/program/${data.id}`} onClick={() => {
                newBiPoint("program", "click", data.id)
            }}>
                <ImageComponent topic={"program"} isPoster={true} src={data.poster} itemId={data.id}
                                style={{width: "100%", height: "100%"}}/>
            </Link>
            <div className="tvind fl">
                <h2><Link to={`/program/${data.id}`} onClick={() => {
                    newBiPoint("program", "click", data.id)
                }} className="text-decoration-none">{data.title}</Link></h2>
                <ShowRatingComponent score={score} evaluation={evaluation}/>
                <em>{`${data.viewer}次观看· ${numberUnit2Text(data.subscriber)}人订阅它 · 于${data.published} 发布`}</em>
                <p>{data.description}</p>
                <div className="tvbtn" style={{height: "50px"}}>
                    {renderSubscribeButton(data)}
                </div>
            </div>
            <div className="grade border-0">
                <button onClick={() => giveEvaluation(data)} disabled={isRated}
                        className="btn btn-light text-center fw-bold"
                        style={{fontSize: "15px"}}>{isRated === true ? "已评价" : "评分"}</button>
            </div>
        </div>
    )

}


class CommendComponent extends React.Component{

    constructor(props){
        super(props);
        this.state={
            data:null
        }
    }
    componentDidMount(): void {
        this.getData();
    }

    getData(){
        api.GetCommendUser({page_token:""},(content)=>{
            let d=[];
            if (content.length >=6){
                d = content.slice(0,6);
            }else{
                d = content
            }
            this.setState({data:d})
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        if (this.state.data==null){
            return (
                <div className="fl newlive" style={{height:"auto",width:"280px"}}>
                    <WaitingComponent />
                </div>
            )
        }
        return (
            <div className="newlive float-start" style={{height:"auto",width:"280px"}}>
                <div className="title">
                    <a >
                        <span>推薦頻道</span>
                    </a>
                </div>
                <ul style={{margin:"0",padding:"0",display:"flow-foot"}}>
                   {this.state.data.map(item =>( <li className="float-start">
                        <div className="npic float-start" >
                            <Link to={`/user/${item.id}`} onClick={()=>{
                                newBiPoint("user","click",item.id)
                            }} style={{width:"60px",height:"60px"}} className="float-start" >
                                <ImageComponent style={{width:"60px",height:"60px"}} isPoster={false} itemId={item.id} src={IsHttpsURL(item.icon,(url)=>MakeStaticURL(url))} topic={"user"} />
                            </Link>
                        </div>
                        <div className="ndetails float-start" >
                            <h3 style={{fontSize:"14px",wordWrap:"break-word"}}>{item.name}</h3>
                            <p style={{fontSize:"12px"}}>{`已有${numberUnit2Text(item.follow_amount)}人关注TA`}</p>
                        </div>
                    </li>))}
                </ul>
            </div>
        )
    }
}

