import React from 'react';
import {Link} from "react-router-dom";



export function FootNavComponent({callback_handleViewDisplay=()=>{}}) {

    let ref = React.createRef();

    React.useEffect(()=>{
        let ob = new IntersectionObserver((entry)=>{
            entry.map(it => {
                 if (it.intersectionRatio > 0){
                     if (callback_handleViewDisplay != null && typeof(callback_handleViewDisplay) === "function"){
                         callback_handleViewDisplay()
                     }
                 }
                 //console.log(it.intersectionRatio)
            });
        });

        ob.observe(ref.current);
    });

    return (
        <div className="footer " id={"footer"}  ref={ref}>
            <div className="footer-warp" style={{height:`${45+244+45}px`}}>
                <div className="footer-nav fl" style={{height:"auto"}}>
                    <dl>
                        <dt><b>Company</b></dt>
                        <dd><Link to={"/company/about"}>About</Link></dd>
                        <dd><Link to={"/company/team"}>Team</Link></dd>
                        <dd><Link to={"/company/idea"}>Idea</Link></dd>
                        <dd><Link to={"/company/contact"}>Contact</Link></dd>
                        <dd><Link to={"/company/careers"}>Careers</Link></dd>
                    </dl>
                    <dl>
                        <dt><b>Programs</b></dt>
                        <dd><Link to={"/programs/advertise"}>Advertise</Link></dd>
                        <dd><Link to={"/programs/developers"}>Developers</Link></dd>
                        <dd><a href={"https://www.wellbuyhub.com"}>Well Buy Hub</a></dd>
                        <dd><a href={"https://www.aividhub.com"}>AIVidHub</a></dd>
                        <dd><a href={"https://www.pacontv.com"}>PaconTV</a></dd>
                    </dl>
                    <dl>
                        <dt><b>Support</b></dt>
                        <dd><a>Creator Portal</a></dd>
                        <dd><a>Help Center</a></dd>
                        <dd><a>Safety Center</a></dd>
                        <dd><a>Community</a></dd>
                    </dl>
                    <dl>
                        <dt><b>Legal</b></dt>
                        <dd><Link to={"/legal/terms_of_service"}>Terms of Service</Link></dd>
                        <dd><Link to={"/legal/privacy_policy"}>Privacy Policy</Link></dd>
                        <dd><Link to={"/legal/disclosure"}>Disclosure</Link></dd>
                    </dl>

                </div>
                <div className="code fl">
                    <img src="images/code.jpg" alt={"Yimi.TV"}/>
                    <p>Welcome To Follow Us On TikTok</p>
                </div>
                <div className="fr footer-logo">
                    <img src="http://static.yimi.tv/foot-logo.jpeg" alt={"Yimi.TV"}/>
                    <p>Copyright © 2024 Yimi.TV All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

/*

export class FootNavComponent extends React.Component {

    constructor(props){
        super(props);
        this.props = props
        this.ref = React.createRef();
    }
    componentDidMount() {


    }

    render() {
        return (

        )
    }
}*/
