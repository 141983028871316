import React from 'react';


export function WaitingComponent({setBorder = true}){



        return (<div style={{width:"100%",height:"300px",background:"#fff",border:setBorder===true ? "1px #f0f0f0 solid":"",marginBottom:"15px"}}
                     className="justify-content-center d-flex">
            <div className="d-flex align-items-center justify-content-center text-center text-danger">
                <div className="spinner-border ms-auto" role="status" aria-hidden="true" style={{width:"30px",height:"30px"}}/>
                <strong style={{fontSize:"20px",marginLeft:"10px"}} className="fs-4 text-danger">Loading...</strong>
            </div>
        </div>)

}