import {
    InputFieldComponent,
    SelectComponent,
    TextAreaComponent,
} from "../../CommonComponents/Form";
import * as React from "react";


export function AdInformationComponent({programList,callback_submit,data}) {

    data = data === undefined ? {} : data;
    data = data === null ? {} :data;

    let [name,setName] = React.useState(data.name);
    let [adSpot,setAdSpot] = React.useState(data.ad_spot);

    let [needUpdate,setNeedUpdate] = React.useState(false);
    let [slogans,setSlogans] = React.useState(data.slogan);
    let [adType,setAdType] = React.useState(data.type);
    let [targetProgram,setTargetProgram] = React.useState(data.target);

    let [changeText,setChangeText] = React.useState(false);

    return  (
        <div className="console_content" >
            <InputFieldComponent
                showPrompt={false}
                callback_onChange={(value)=>{
                    setName(value);
                    setNeedUpdate(true);
                }}
                label={"广告名字"}
                disable={false}
                promptText={"Please Input Advertising Name"}
                placeholder={name}
            />
            <TextAreaComponent
                showPrompt={false}
                callback_onChange={
                    (value)=>{setSlogans(value);setNeedUpdate(true);}
                }
                placeholder={slogans}
                label={"广告语"}
                disable={false}
                promptText={"Please Input Advertising slogans"}
            />
            <InputFieldComponent
                disable={true}
                value={`${adSpot.name},${adSpot.platform}`}
                label={"广告位置"}/>
            <InputFieldComponent
                disable={true}
                value={adType.name}
                label={"广告类型"}
            />
            <InputFieldComponent
                disable={true}
                value={data.target_name}
                label={"推广物品"} />
            <div className="mb-3">

                <button className="btn btn-primary"
                        disabled={needUpdate === false || changeText === true}
                        onClick={() => {
                            if (needUpdate === false) {
                                return null
                            } else {
                                setChangeText(true);
                                let p = {
                                    name: name,
                                    slogan: slogans,
                                };
                                //callback_submit(p)
                                setTimeout(() => {
                                    setChangeText(false);
                                    setNeedUpdate(false);
                                }, 1000 * 5);
                            }
                        }}
                ><span style={{display: changeText === false ? "block" : "none"}}>Modify</span>
                    <div style={{display: changeText === true ? "block" : "none"}}>
                        <span className="spinner-border spinner-border-sm"/>
                        <span > Updating...</span>
                    </div>
                </button>
            </div>
        </div>
    )
}