import * as React from "react";
import {withRouter} from "../CommonComponents/WithRouter";
import {Link} from "react-router-dom";
import {HeadNavComponent} from "../CommonComponents/HeadNav";
import {FootNavComponent} from "../CommonComponents/FootNav";
import {api} from "../../api/base";
import Helmet from "react-helmet";


function RechargeCombo() {

    return [
        {"coins":10,"currencyPrice":1,"currencyUnit":"USD"},
        {"coins":50,"currencyPrice":5,"currencyUnit":"USD"},
        {"coins":250,"currencyPrice":25,"currencyUnit":"USD"},
        {"coins":1250,"currencyPrice":125,"currencyUnit":"USD"},
        {"coins":6250,"currencyPrice":625,"currencyUnit":"USD"},
        {"coins":31250,"currencyPrice":3125,"currencyUnit":"USD"},
    ]
}

function PaymentMethod() {

    return [
        {"name":"AliPay","id":"alipay","img":"images/zfb.png",},
        {"name":"WeChat Pay","id":"wechat","img":"images/wxzf.png",},
        {"name":"credit card","id":"stripe","img":"images/wxzf.png",}
    ]
}

class RechargePageCore extends React.Component{

    constructor(props){
        super(props);
        let auth= this.props.router.auth;
        this.state={
            coinBalance:0,
            account:auth.user === null ? "" : auth.user.name,
            rechargeAmount:null,
            rechargeCoins:null,
            currencyUnit:null,
            selectPaymentMethod:null,
            combo:RechargeCombo(),
            pm:PaymentMethod(),
            agreeTerms:true,
            needCurrencyAmount:this.props.router.location.state.needCurrencyAmount,

        }
    }

    componentDidMount(): void {

        let a = this.setBestChoice();
        this.setState({
            rechargeAmount:a[0],
            rechargeCoins:a[1],
            currencyUnit:a[2]
        })
    }

    setBestChoice(){
        let c = RechargeCombo();
        for (let i =0;i<c.length;i++){
            let item = c[i];
            if (this.BestChoice(item.currencyPrice)===true){
                    return [item.currencyPrice,item.coins,item.currencyUnit]
            }
        }
        return [c[0].currencyPrice,c[0].coins,c[0].currencyUnit]
    }

    BestChoice(amount:number){
        //let c = ;
        let c = this.state.needCurrencyAmount === undefined || this.state.needCurrencyAmount === null ? 5 : this.state.needCurrencyAmount;

        if (this.state.rechargeAmount === 0 || this.state.rechargeAmount === null){

            if (c === amount){return true}
            if (c < amount){
                if (amount/c <=5){return true}
            }
            return false
        }
        return this.state.rechargeAmount === amount
    }
    payNow(){
        if (this.state.agreeTerms===false){
            window.alert("Please agree to the terms of service！");
            return null
        }
        api.Recharge({
            rechargeAmount:this.state.rechargeAmount,
            rechargeCoins:this.state.rechargeCoins,
            currencyUnit:this.state.currencyUnit,
            paymentMethod:this.state.selectPaymentMethod,
        },(response)=>{},()=>{},()=>{})
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        return (
            <div>
                <Helmet>
                    <title>Recharge / 充值金币  - Yimi.TV</title>
                </Helmet>
                <HeadNavComponent />
                <div className="crwarp">
                    <div className="recharge-warp">
                        <div className="rHead">
                            <h2>Coins Recharge</h2>
                        </div>
                        <div className="rContent">
                            <ul>
                                <li>
                                    <label>Account：</label>
                                    {this.state.account}
                                </li>
                                <li className="zffx">
                                    <label style={{width:"auto"}}>Payment Method：</label>
                                    <dl>
                                        {this.state.pm.map(
                                            items => (
                                                <dd onClick={() => {
                                                    this.setState({
                                                        selectPaymentMethod: items.id
                                                    })
                                                }}
                                                    className={this.state.selectPaymentMethod === items.id ? "checkd" : ""}
                                                >
                                                    <img src={items.img} alt={items.name}/>
                                                </dd>
                                            )
                                        )}
                                    </dl>
                                </li>
                                <li className="czje">
                                    <label style={{width:"auto"}}>Recharge Amount：</label>
                                    <dl>
                                        {this.state.combo.map(item => (
                                            <dd
                                                onClick={() => {
                                                    this.setState({
                                                        rechargeAmount: item.currencyPrice,
                                                        currencyUnit: item.currencyUnit,
                                                        rechargeCoins: item.coins
                                                    })
                                                }}
                                                className={this.BestChoice(item.currencyPrice) === true ? "checkd" : ""}
                                            >{`${item.currencyPrice} ${item.currencyUnit}`}</dd>
                                        ))}
                                    </dl>
                                </li>
                                <li>
                                    <label style={{width:"auto"}}>Amounts Payable：</label>
                                    <em>{`${this.state.rechargeAmount} ${this.state.currencyUnit}`}</em>
                                </li>
                                <li>
                                    <label>Get Coins：</label>
                                    <strong>{`${this.state.rechargeCoins}`}</strong>
                                </li>
                                <li>
                                    <label style={{width:"auto"}}>Coin Balance：</label>
                                    <em>{this.state.coinBalance}</em>
                                </li>
                            </ul>
                            <div className="rTool">
                                <input type="button"
                                       value="Pay Now"
                                       className="pay-now"
                                       onClick={() => this.payNow()}
                                />
                                <input type="checkbox" checked={
                                    this.state.agreeTerms
                                }
                                       onClick={() => {
                                           this.setState({
                                               agreeTerms: !this.state.agreeTerms
                                           })
                                       }}
                                 /> Payment Means Agreeing To The <Link to={"/legal/rechargeServiceAgreement"}>"Yimi.tv Recharge Service Agreement"</Link> By Default
                            </div>
                        </div>
                    </div>
                </div>
                <FootNavComponent />
            </div>
        )

    }

}

export const RechargePage = withRouter(RechargePageCore);