import * as React from "react";
import {useAuth} from "./Route";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {api} from "../api/base";
import "../css/login.css"


export default function AuthStatus() {

    let context = useAuth();
    let navigate = useNavigate();
    let location = useLocation();
    let [showMenu,setShowMenu] = React.useState(false);

    if(context.user === null || context.user === undefined || context.isLoggedIn === false){
        return (

            <div  className="fr">
                <a  className="btn btn-danger"   onClick={()=>{
                    navigate("/login",{
                        replace:true,
                        state:{...location.state,
                            age:location.search,
                            pathname:location.pathname},
                    })
                }}>登陸 / Sign Up</a>
                <a className="btn btn-outline-danger" style={{marginLeft:"5px"}}

                    onClick={()=>{

                        navigate("/register", {replace:true,state:{...location.state,
                                age:location.search,
                                pathname:location.pathname,
                             }})
                    }}
                >註冊帳號 / Register</a>
            </div>

        )
    }

    return (

        <div className="ymtv-user fr" >
            <div className="user-pic fl">
                <a >
                    <img src={context.user.user_icon} width="32" height="32" alt={context.user.user_name}/>
                </a>
            </div>
            <div className="user-name fl">

                <div className="dropdown">
                    <button className="btn dropdown-toggle btn-outline-danger" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false" onClick={()=>{
                                setShowMenu(!showMenu)
                    }}>
                        {context.user.user_name}
                    </button>
                    <ul className="dropdown-menu" style={{display:showMenu===true ?"block":"none"}} >
                        <li>
                            <button className="dropdown-item" type="button"
                                onClick={()=>{
                                    navigate("/studio",{replace:true})

                                }}
                            >Creator Studio</button>
                        </li>
                        <li >
                            <button className="dropdown-item" type="button"
                                onClick={()=>{
                                    navigate("/channel",{replace:true})
                                }}
                            >
                                Visit My Channel
                            </button>
                        </li>
                        <li>
                            <button className="dropdown-item" type="button"
                                onClick={()=>{
                                    api.SignOut(function () {
                                        context.signOut(function () {
                                            navigate("/",{replace: true})
                                        })
                                    },function () {
                                        context.signUp(function () {
                                            navigate("/",{replace:true})
                                        })
                                    });
                                }}
                            >Sign out</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    )

}
/*
*
*  <span className="user-more" onClick={()=>{
                    setShowMenu(!showMenu)
                }}/>
                <div className="user-center" style={
                    {display:showMenu === true ? "block" : "none"}
                }>
                    <ul>
                        <li><Link to="/studio">Creator Studio</Link></li>
                        <li><a onClick={
                            ()=>{

                            }
                        }>Sign Out</a></li>
                    </ul>
                </div>
* */
