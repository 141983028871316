import {HNComponent} from "./CommonComponents/HeadNav";
import React from "react";
import {MenuComponent} from "./CommonComponents/Menu";
import "bootstrap/dist/css/bootstrap.min.css"
import "../../../css/studio.css"
import {Outlet, Route, Routes} from "react-router";
import {DashBoardComponent} from "./DashBoard";
import {CreateProgramPage} from "./Program/CreateProgram";
import {ProgramListPage} from "./Program/ProgramList";
import {ProgramDetailPage} from "./Program/ProgramDetail"
import {VideoListPage} from "./Video/VideoList";
import {UploadVideoPage} from "./Video/UploadVideo";
import {VideoDetailPage} from "./Video/VideoDetail";
import {WalletPage} from "./Wallet";
import {AdListPage} from "./Ads/AdsList";
import {AdsDetailPage} from "./Ads/AdvertisingDetail";
import {CreateAdsPage} from "./Ads/CreateAds";
import {SettingPage} from "./Setting";
import Helmet from "react-helmet";

export class StudioComponent extends React.Component{
    render(){
        return(
            <div >
                <Helmet>
                    <title>Creator Studio - Yimi.TV</title>
                </Helmet>
                <HNComponent />
                <div className="container-fluid" style={{height: "100%",marginTop: "50px"}}>
                    <div className="row" style={{height:"100%"}}>
                        <MenuComponent />
                        <div className="col-sm-9 col-sm-offset-3 col-md-10 col-md-offset-2">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export function  StudioRouter() {

    return<Routes>
        <Route element={<StudioComponent />}>
            <Route path={"/"} element={<VideoListPage />} index={true} />
            <Route path={"/program"} element={<ProgramListPage />} />
            <Route path={"/program/:program_id"} element={<ProgramDetailPage />} />
            <Route path={"/program/create"} element={<CreateProgramPage/>} />
            <Route path={"/video"} element={<VideoListPage />}/>
            <Route path={"/video/upload"} element={<UploadVideoPage />} />
            <Route path={"/video/:video_id"} element={<VideoDetailPage />} />
            <Route path={"/wallet"} element={<WalletPage />} />
            <Route path={"/advertising"} element={<AdListPage />} />
            <Route path={"/advertising/:ad_id"} element={<AdsDetailPage />} />
            <Route path={"/advertising/create"} element={<CreateAdsPage/>} />
            <Route path={"/setting"} element={<SettingPage />} />

        </Route>
    </Routes>
}